import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router'
import { Layout, Row, Col, Typography, Divider } from 'antd';
import { TEMPLATE } from '../../../config/Config'
import { langs } from '../../../config/localization';
import Newsletter from '../../../components/newsletter/index';
import { FOOTER_SLUGS } from '../../../config/Constant'
import { getClassifiedCatLandingRoute, getBookingCatLandingRoute, getRetailCatLandingRoutes } from '../../../common/getRoutes'
import { getPageListingAction } from '../../../store/actions/index';
import { ReactComponent as BackToTop } from "../../../assets/images/icon_arrow_down.svg";
import { ReactComponent as Instagram } from '../../../assets/images/instagram.svg';
import { ReactComponent as Facebook } from '../../../assets/images/facebook.svg';
import { ReactComponent as LinkedIn } from '../../../assets/images/LinkedIn.svg';

// import './footer.less';
import './footerNew.less';
const { Footer } = Layout;
const { Title, Text } = Typography;

class AppFooter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      bookingList: [],
      retailList: [],
      classifiedList: [],
      isEmpty: false,
      redirectTo: null
    };
  }

  /**
* @method renderSubcategory
* @description render subcategory based on category type
*/
  renderSubcategory = (categoryType, key) => {
    if (categoryType && categoryType !== undefined) {
      return categoryType.map((data, i) => {
        return (
          <li key={i} onClick={() => {
            if (key === langs.modules.classified || key === langs.modules.booking || key === langs.sellerType.retail) {
              this.selectTemplateRoute(data, key)
            }
          }} style={{ cursor: 'pointer' }}>{key === langs.sellerType.retail ? data.text : data.name}
          </li>
        )
      });
    }
  }

  /**
  * @method selectTemplateRoute
  * @description decide template to navigate the logic
  */
  selectTemplateRoute = (el, key) => {

    if (key === langs.modules.classified) {
      let reqData = {
        page_id: langs.modules.classified,
        category_id: el.id,
        page: 1,
        page_size: 10
      }

      this.props.getPageListingAction(reqData, (res) => {
        if (res.success) {
          if (Array.isArray(res.data) && res.data.length) {
            let templateName = res.data[0].template_slug
            let cat_id = res.data[0]?.category?.id;
            let path = ''
            if (templateName === TEMPLATE.GENERAL) {
              path = getClassifiedCatLandingRoute(TEMPLATE.GENERAL, cat_id, el.name)
              this.props.history.push(path)
            } else if (templateName === TEMPLATE.JOB) {
              let route = getClassifiedCatLandingRoute(TEMPLATE.JOB, cat_id, el.name)
              this.props.history.push(route);
            } else if (templateName === TEMPLATE.REALESTATE) {
              path = getClassifiedCatLandingRoute(TEMPLATE.REALESTATE, cat_id, el.name)
              this.props.history.push(path)
            }
          } else {
            // toastr.warning(langs.warning, langs.messages.classified_list_not_found)
            let templateName = el.template_slug
            let cat_id = el.id;
            let path = ''
            if (templateName === TEMPLATE.GENERAL) {
              path = getClassifiedCatLandingRoute(TEMPLATE.GENERAL, cat_id, el.name)
              this.props.history.push(path)
            } else if (templateName === TEMPLATE.JOB) {
              let route = getClassifiedCatLandingRoute(TEMPLATE.JOB, cat_id, el.name)
              this.props.history.push(route);
            } else if (templateName === TEMPLATE.REALESTATE) {
              path = getClassifiedCatLandingRoute(TEMPLATE.REALESTATE, cat_id, el.name)
              this.props.history.push(path)
            }
          }
        }
      })
    } else if (key === langs.modules.booking) {
      let slug = el.slug
      if (slug === TEMPLATE.HANDYMAN) {
        let path = getBookingCatLandingRoute(TEMPLATE.HANDYMAN, el.id, el.name)
        this.props.history.push(path)
      } else if (slug === TEMPLATE.BEAUTY) {
        let path = getBookingCatLandingRoute(TEMPLATE.BEAUTY, el.id, el.name)
        this.props.history.push(path)
      } else if (slug === TEMPLATE.EVENT) {
        let path = getBookingCatLandingRoute(TEMPLATE.EVENT, el.id, el.name)
        this.props.history.push(path)
      } else if (slug === TEMPLATE.WELLBEING) {
        let path = getBookingCatLandingRoute(TEMPLATE.WELLBEING, el.id, el.name)
        this.props.history.push(path)
      } else if (slug === TEMPLATE.RESTAURANT) {
        let path = getBookingCatLandingRoute(TEMPLATE.RESTAURANT, el.id, el.name)
        this.props.history.push(path)
      } else if (slug === TEMPLATE.PSERVICES || 'Professional Services') {
        let path = getBookingCatLandingRoute(TEMPLATE.PSERVICES, el.id, el.name)
        this.props.history.push(path)
      } else if (slug === TEMPLATE.SPORTS) {
        let path = getBookingCatLandingRoute(TEMPLATE.SPORTS, el.id, el.name)
        this.props.history.push(path)
      }
    } else if (key === langs.sellerType.retail) {
      let path = getRetailCatLandingRoutes(el.id, el.slug)
      this.props.history.push(path)
    }
  }

  render() {
    return (
      <>
        <div
          className="backToTop"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          <div>
            <BackToTop />
          </div>
          Back to Top
        </div>

        <Footer className='footer'>
          <div className='container'>
            <div className='footer-wrap'>
              <Row align="middle" className='footer-logo-wrap'>
                <Col className='footer-logo' span={12}>
                  <img src={require('../../../assets/images/header-logo.svg')} alt='Formee' />
                </Col>
                <Col span={12} className="align-right pc-hide">
                  <div className='social-link'>
                    <Link target="_blank" to={{ pathname: 'https://www.instagram.com/formee.app/?hl=en' }}>
                      <Instagram className='icon' />
                    </Link>
                    <Link target="_blank" to={{ pathname: 'https://www.facebook.com/formeeapplication' }}>
                      <Facebook className='icon' />
                    </Link>
                    <Link target="_blank" to={{ pathname: 'https://www.linkedin.com/company/formee-pty-ltd' }}>
                      <LinkedIn className='icon' />
                    </Link>
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col xs={12} md={4} lg={4}>
                  <Title level={4} className='text-normal'>About</Title>
                  <ul>
                    <li><Link to={`/footer-pages/${FOOTER_SLUGS.ABOUT_US}`}>About us</Link></li>
                    <li><Link to={`/footer-pages/${FOOTER_SLUGS.CAREERS}`}>Careers</Link></li>
                    <li><Link to={`/footer-pages/${FOOTER_SLUGS.CONTACT_US}`}>Contact us</Link></li>
                  </ul>

                </Col>

                <Col xs={12} md={5} lg={4}>
                  <Title level={4} className='text-normal'>Join Us</Title>
                  <ul>
                    <li><Link to={`/footer-pages/${FOOTER_SLUGS.JOIN_FORMEE}`}>Register with Us</Link></li>
                    <li><Link to={`/footer-pages/${FOOTER_SLUGS.PARTNERSHIPS}`}>Partnerships</Link></li>
                    <li><Link to='/mobile-app/screen'>Our Mobile App</Link></li>
                  </ul>
                </Col>
                <Col xs={12} md={5} lg={4}>
                  <Title level={4} className='text-normal'>Help Center</Title>
                  <ul>
                    <li><Link to={`/footer-pages/${FOOTER_SLUGS.SERVICES}`}>Services</Link></li>
                    <li><Link to={`/footer-pages/${FOOTER_SLUGS.HOW_IT_WORKS}`}>How it works</Link></li>
                    <li><Link to='/faq'>FAQs</Link></li>
                  </ul>

                </Col>
                <Col xs={12} md={4} lg={4}>
                  <Title level={4} className='text-normal'>Legal</Title>
                  <ul>
                    <li><Link to={`/footer-pages/${FOOTER_SLUGS.TERMS_OF_USE}`}>Terms of Use</Link></li>
                    <li><Link to={`/footer-pages/${FOOTER_SLUGS.PRIVACY_POLICY}`}>Privacy Policy</Link></li>
                    <li><Link to={`/footer-pages/${FOOTER_SLUGS.POSTING_POLICY}`}>Posting Policy</Link></li>
                  </ul>

                </Col>
                <Col xs={12} md={6} lg={8} className="align-right">
                  <div className='app-link mobile-hide'>
                    <Text className='text-getapp mb-10'>Get the App</Text>
                    <Link target="_blank" to={{ pathname: 'https://apps.apple.com/pk/app/formee/id1245080932' }} >
                      <img className="mb-10" src={require('../../../assets/images/app-store-dark.png')} alt='App Store' />
                    </Link><br />
                    <Link target="_blank" to={{ pathname: 'https://play.google.com/store/apps/details?id=com.pws.formee&hl=en_US' }} >
                      <img className="mb-10" src={require('../../../assets/images/google-play-dark.png')} alt='Google Play' />
                    </Link>
                  </div>
                </Col>
                <Divider />
                <Col md={24}>
                  <Newsletter />
                </Col>
              </Row>
            </div>
          </div>
          <div className='footer-bottom-wrap'>
            <div className='container'>
              <div className='footer-bottom'>
                <ul>
                  <li>© 2022 formee express All rights reserved.</li>
                </ul>
              </div>
            </div>
          </div>
        </Footer>
      </>
    )
  }
}

const mapStateToProps = (store) => {
  const { auth, general: { categoryList } } = store;
  let classifiedList = [], bookingList = [], retailList = [], foodScanner = '';
  classifiedList = categoryList && Array.isArray(categoryList.classified) ? categoryList.classified : []
  bookingList = categoryList && Array.isArray(categoryList.booking) ? categoryList.booking : []
  retailList = categoryList && Array.isArray(categoryList.retail) ? categoryList.retail : []

  return {
    loggedInDetail: auth.loggedInUser,
    isLoggedIn: auth.isLoggedIn,
    classifiedList, bookingList, retailList, foodScanner,
  };
};
export default connect(
  mapStateToProps,
  { getPageListingAction }
)(withRouter(AppFooter));

