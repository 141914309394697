import axios from "../../../api/axios";
import { apiErrors } from "../../../config/HandleAPIErrors";
import { TOP_RATED_HOTELS_API, HOTEL_SEARCH_API, HOTEL_DESCRIPTION_API, HOTEL_AVERAGE_RATING_API, ADD_HOTEL_VIEW_API, HOTEL_MONO_SEARCH_API, HOTEL_BOOKING_API, HOTEL_BOOKING_PAYPAL_API } from "../../../api";
import { SET_HOTEL_SEARCH_DATA } from '../../constants'


/**
 * @method getTopRatedHotelsAction
 * @descriptionhandle get top rated hotels
 */
export function getTopRatedHotelsAction(data, callback) {
    const request = axios.post(`${TOP_RATED_HOTELS_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            callback(error);
        });
    }
}

/**
 * @method hotelSearchAction
 * @descriptionhandle hotel search api
 */
export function hotelSearchAction(data, callback) {
    const request = axios.post(`${HOTEL_SEARCH_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
            if (res.status === 200) {
                dispatch({
                    type: SET_HOTEL_SEARCH_DATA,
                    payload: res.data.data
                })
            } else {
                dispatch({
                    type: SET_HOTEL_SEARCH_DATA,
                    payload: ''
                })
            }
        }).catch(function (error) {
            dispatch({
                type: SET_HOTEL_SEARCH_DATA,
                payload: ''
            })
            callback(error.response);
            apiErrors(error)
        });
    }
}


/**
 * @method getHotelDescriptiveInfoAction
 * @descriptionhandle get hotel descriptive information
 */
export function getHotelDescriptiveInfoAction(data, callback) {
    const request = axios.post(`${HOTEL_DESCRIPTION_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}


/**
 * @method hotelAverageRatingAction
 * @descriptionhandle mark favorite unfavorite hotels
 */
export function hotelAverageRatingAction(data, callback) {
    const request = axios.post(`${HOTEL_AVERAGE_RATING_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {
            callback(error);
        });
    }
}

/**
 * @method addHotelViews
 * @descriptionhandle add hotel views
 */
export function addHotelViewAction(data, callback) {
    const request = axios.post(`${ADD_HOTEL_VIEW_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}


/**
 * @method hotelMonoSearchAction
 * @descriptionhandle get hotel descriptive information
 */
export function hotelMonoSearchAction(data, callback) {
    const request = axios.post(`${HOTEL_MONO_SEARCH_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
* @method hotelBookingAction
* @descriptionhandle tourism flight booking api
*/
export function hotelBookingAction(data, callback) {
    const request = axios.post(`${HOTEL_BOOKING_API}`, data)
    return (dispatch) => {
        request.then((res) => {

            callback(res);
        }).catch(function (error) {
            callback(error);
            apiErrors(error)
        });
    }
}

/**
* @method hotelBookingPaypalAction
* @descriptionhandle tourism flight booking api
*/
export function hotelBookingPaypalAction(data, callback) {
    const request = axios.post(`${HOTEL_BOOKING_PAYPAL_API}`, data)
    return (dispatch) => {
        request.then((res) => {

            callback(res);
        }).catch(function (error) {

            callback(error);
            apiErrors(error)
        });
    }
}