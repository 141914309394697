
import axios from "../../../api/axios";
import { apiErrors } from "../../../config/HandleAPIErrors";
import { GET_MOST_BOOKED_HOTELS_API, GET_MOST_BOOKED_FLIGHTS_API, GET_LOCATIONS_API, STORE_TOURISM_SEARCH_API, GET_RECOMMENDED_TOURISM_API } from "../../../api";

export * from './hotels';
export * from './flights';
/**
 * @method getMostBookedHotelsAction
 * @descriptionhandle get flight autocomplete list
 */
export function getMostBookedHotelsAction(callback) {
    const request = axios.get(`${GET_MOST_BOOKED_HOTELS_API}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {
            callback(error);
        });
    }
}

/**
* @method getRecommendedTourismAction
* @descriptionhandle get flight which are most recomended
*/
export function getRecommendedTourismAction(data, callback) {
    const request = axios.post(`${GET_RECOMMENDED_TOURISM_API}${data.module_type}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
            apiErrors(error)
        });
    }
}

/**
 * @method getMostBookedFlightsAction
 * @descriptionhandle get flight which are most booked
 */
export function getMostBookedFlightsAction(data, callback) {
    const request = axios.post(`${GET_MOST_BOOKED_FLIGHTS_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method getLocationsAction
 * @descriptionhandle get flight autocomplete list
 */
export function getLocationsAction(value, callback) {
    const request = axios.get(`${GET_LOCATIONS_API}${value}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            callback(error);
        });
    }
}

/**
* @method storeTourismSearchAction
* @descriptionhandle store search data
*/
export function storeTourismSearchAction(data) {
    const request = axios.post(`${STORE_TOURISM_SEARCH_API}`, data)
    return (dispatch) => {
        request.then((res) => {
        }).catch(function (error) {
        });
    }
}