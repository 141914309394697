import {
  GET_CATEGORY_LIST,
  GET_CATEGORY_LIST_SUCCESS,
  GET_SUB_CATEGORY_LIST,
  GET_SUB_CATEGORY_LIST_SUCCESS,
  SET_COMPARISION_PRODUCT_IDS,
  SET_FITNESS_DEFAULTS,
  SET_RESTAURANT_DEFAULTS,
} from "../constants";

const initialState = {
  categoryList: [],
  compareProductIds: [],
  fitnessDefaults: [],
  menyList: [],
  restaurantDefaults: [],
  showHideLoading: false,
  showPasswordModal: false,
  subCategoryList: [],
}

export default function generalReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORY_LIST:
      return { ...state };
    case GET_CATEGORY_LIST_SUCCESS:
      return { ...state, categoryList: action.payload }
    case GET_SUB_CATEGORY_LIST:
      return { ...state };
    case GET_SUB_CATEGORY_LIST_SUCCESS:
      return { ...state, subCategoryList: action.payload }
    case SET_RESTAURANT_DEFAULTS:
      return { ...state, restaurantDefaults: action.payload }
    case SET_FITNESS_DEFAULTS:
      return { ...state, fitnessDefaults: action.payload }
    case SET_COMPARISION_PRODUCT_IDS:
      return { ...state, compareProductIds: action.payload }
    default:
      return state;
  }
}
