import axios from "axios";
import { BOOKINGS_API, BOOK_CLASSES_API, BOOK_MEMBERSHIP_API, BOOK_SERVICES_API, BUSINESS_CLASSES_API, BUSINESS_MEMBERSHIP_API, CLASS_CHECKOUT_API, CLASS_SCHEDULE_API, MEMBERSHIP_CHECKOUT_API, SERVICE_CHECKOUT_API } from "../../../api";
import { API } from "../../../config/Config";
import { apiErrors } from "../../../config/HandleAPIErrors";

/**
 * @method getTraderMonthWellbeingBooking
 * @description get all new  time slot
 */
export function getTraderMonthWellbeingBooking(data, callback) {
  const request = axios.post(`${API.getTraderMonthWellbeingBooking}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method getTraderMonthBeautyBooking
 * @description get all new  time slot
 */
export function getTraderMonthBeautyBooking(data, callback) {
  const request = axios.post(`${API.getTraderMonthBeautyBooking}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method bookServicesAction
 * @description get all new  time slot
 */
export function bookServicesAction(data, callback) {
  const request = axios.post(`${BOOK_SERVICES_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method getBookedServiceAction
 * @description get all new  time slot
 */
export function getBookedServiceAction(booking_id, callback) {
  const request = axios.get(`${BOOK_SERVICES_API}/${booking_id}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function updateSpaDietitianSpaBooking(data, callback) {
  const request = axios.post(`${API.updateSpaDietitianSpaBooking}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function getTraderDetails(data, callback) {
  const request = axios.post(`${API.getTraderDetails}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}
export function getBookingIdByEnquiryId(enquiryId, callback) {
  const request = axios.post(`${API.getBookingIdByEnquiryId}`, enquiryId);
  return (dispatch) => {
    request
      .then((res) => {
        callback !== undefined && callback(res);
        dispatch({
          payload: res.data,
        });
      })
      .catch(function (error) {
        //
        apiErrors(error);
        callback !== undefined && callback(error);
      });
  };
}
export function serviceCheckoutAction(data, callback) {
  const request = axios.post(`${SERVICE_CHECKOUT_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function deleteBookingAction({ booking_id, ...data }, callback) {
  const request = axios.delete(`${BOOKINGS_API}/${booking_id}`, { params: data });
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function spaDeletebookingHistory(data, callback) {
  const request = axios.post(`${API.spaDeletebookingHistory}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function getSpaServiceRepay(data, callback) {
  const request = axios.post(`${API.getSpaServiceRepay}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function classCheckoutAction(data, callback) {
  const request = axios.post(`${CLASS_CHECKOUT_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function membershipCheckoutAction(data, callback) {
  const request = axios.post(`${MEMBERSHIP_CHECKOUT_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function getBusinessClassesAction(business_id, callback) {
  const request = axios.get(`${BUSINESS_CLASSES_API}/${business_id}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function getFitnessClassScheduleAction(class_id, callback) {
  const request = axios.get(`${CLASS_SCHEDULE_API}/${class_id}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function getBusinessMembershipAction(business_id, callback) {
  const request = axios.get(`${BUSINESS_MEMBERSHIP_API}/${business_id}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function bookClassAction(data, callback) {
  const request = axios.post(`${BOOK_CLASSES_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        apiErrors(error);
        callback(error);
      });
  };
}

export function bookMembershipAction(data, callback) {
  const request = axios.post(`${BOOK_MEMBERSHIP_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        apiErrors(error);
        callback(error);
      });
  };
}


export function getFitnessHistoryListing(data, callback) {
  const request = axios.get(`${API.getFitnessCustomerHistoryList}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function getFitnessDeleteBooking(data, callback) {
  const request = axios.post(`${API.getFitnessDeleteBooking}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function getBookedMembershipsAction(params, callback) {
  const request = axios.get(`${BOOK_MEMBERSHIP_API}`, { params: params });
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}
