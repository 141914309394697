import axios from 'axios';
import { FAVORITE_UNFAVORITE_HOTEL_API, GET_HOTEL_VIEWS_API, GET_HOTEL_RATING_VIEWS_API, IS_HOTEL_FAVORITE_API, POST_HOTEL_REVIEW_API } from '../../../../api';
import { API } from '../../../../config/Config';
import { apiErrors } from '../../../../config/HandleAPIErrors';
import { SET_HOTEL_SEARCH_DATA, SET_HOTEL_REQ_DATA, GET_SELETED_HOTEL } from '../../../constants'


/**
 * @method markFavUnFavHotels
 * @descriptionhandle mark favorite unfavorite hotels
 */
export function markFavUnFavHotels(data, callback) {
    const request = axios.post(`${FAVORITE_UNFAVORITE_HOTEL_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
            apiErrors(error)
        });
    }
}

/**
 * @method checkFavorite
 * @descriptionhandle check hotel is favorite or not
 */
export function checkFavorite(data, callback) {
    const request = axios.post(`${IS_HOTEL_FAVORITE_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
* @method setHotelReqData
* @description set hotel reqdata
*/
export function setHotelReqData(res) {
    return (dispatch) => {
        dispatch({
            type: SET_HOTEL_REQ_DATA,
            payload: res
        })
    }
}

/**
* @method setSelectedHotelDetails
* @description set selected hotels details
*/
export function setSelectedHotelDetails(res) {
    return (dispatch) => {
        dispatch({
            type: GET_SELETED_HOTEL,
            payload: res
        })
    }
}

/**
 * @method getHotelTotalViews
 * @descriptionhandle get hotel total views
 */
export function getHotelTotalViews(data, callback) {
    const request = axios.post(`${GET_HOTEL_VIEWS_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}
/**
 * @method getHotelRatingViews
 * @descriptionhandle get ratings and views of a list of hotels
 */
export function getHotelRatingViews(data, callback) {
    const request = axios.post(`${GET_HOTEL_RATING_VIEWS_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method addHotelreview
 * @descriptionhandle add hotel reviews
 */
export function addHotelreview(data, callback) {
    const request = axios.post(`${POST_HOTEL_REVIEW_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}
