import React, { Component } from 'react';
import { Spin } from "antd";

/* loader component  */
export class Loader extends Component {


    /**
     * @method render
     * @description render component
     */
    render() {
        const spinIcon = (
            <img
                src={require("../../assets/images/loader1.gif")}
                alt=""
                style={{ width: "64px", height: "64px" }}
            />
        );
        const style = {
            // backgroundColor: "white",
            position: "fixed",
            width: "100vw",
            zIndex: 9999,
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column"

        }
        return (
            <Spin
                tip="Loading..."
                indicator={spinIcon}
                spinning={true}
                // style={style}
            ></Spin>
        )
        // return (
        //     <div className='sf-cstm-loader d-flex align-content-center flex-direction-column'>
        //         <div className='loader-position spinner-border text-primary' role='status'>
        //             <span className='sr-only'>Loading...</span>
        //         </div>
        //     </div>
        // )
    }
}