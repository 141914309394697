import axios from "../../api/axios";
import { apiErrors } from "../../config/HandleAPIErrors";
import { BOOKING_HISTORY_API, SPORTS_BOOK_TICKET_API, SPORTS_EVENTS_SEARCH_API, SPORTS_PAYMENT_API, SPORTS_STATICS_API } from "../../api";


/**
 * @method getSportsEventListAction
 * @description get sports details
 */
export function getSportsEventListAction({ country, ...params }, callback) {
    const request = axios.get(`${SPORTS_STATICS_API}/${country}`, { params: params })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}
/**
 * @method sportsStaticAction
 * @description get sports static list
 */
export function sportsStaticAction(data, callback) {
    const request = axios.post(`${SPORTS_STATICS_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        })
            .catch(function (error) {

                apiErrors(error);
                callback(error);
            });
    };
}


/**
 * @method sportsEventSearchAction
 * @description get sports events search
 */
export function sportsEventSearchAction(data, callback) {
    const request = axios.post(`${SPORTS_EVENTS_SEARCH_API}`, data);
    return (dispatch) => {
        request
            .then((res) => {
                callback(res.data);
            })
            .catch(function (error) {

                apiErrors(error);
                callback(error);
            });
    };
}

/**
 * @method sportsTicketBookingAction
 * @description get sports city list
 */
export function sportsTicketBookingAction(data, callback) {
    const request = axios.post(`${SPORTS_BOOK_TICKET_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method sportsPaymentAction
 * @description get sports city list
 */
export function sportsPaymentAction(data, callback) {
    const request = axios.post(`${SPORTS_PAYMENT_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}


/**
 * @method getBookingHistoryAction
 * @description get booking details
 */
export function getBookingHistoryAction(params, callback) {
    const request = axios.get(`${BOOKING_HISTORY_API}`, { params: params });
    return (dispatch) => {
        request
            .then((res) => {
                callback(res.data);
            })
            .catch(function (error) {
                apiErrors(error);
                callback(error);
            });
    };
}