import axios from 'axios';
import { API } from '../../../config/Config';
import { apiErrors } from '../../../config/HandleAPIErrors';
import { GET_PLAN, SET_CLASSIFIEDS_CATEGORY_LISTING, SET_RESUME_DETAILS, PAPULAR_SEARCH, MOST_PAPULAR_RECORD } from './../../constants';
import { setPriority } from '../../../components/classified-templates/CommanMethod'
import { APPLY_JOB_API, INSPECTION_API, JOB_QUESTIONS_API, RESUME_API, SUBSCRIPTION_API } from '../../../api';


/**
 * @method bookingCategory
 * @description get all booking category
 */
export function getClassfiedCategoryListing(data, callback) {
    const request = axios.post(`${API.getClassifiedListing}`, data)
    return (dispatch) => {
        request.then((res) => {
            let filteredList = setPriority(res.data.data)
            res.data.data = filteredList
            callback(res);
            dispatch({
                type: SET_CLASSIFIEDS_CATEGORY_LISTING,
                payload: res.data
            })
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method tab List of Classified
 * @description get all booking category
 */
export function getClassfiedTabListing(mostRecentReqData, topRatedReqData, callback) {
    const mostRecentReq = axios.post(`${API.getClassifiedListing}`, mostRecentReqData)
    const topRatedReq = axios.post(`${API.getClassifiedListing}`, topRatedReqData)

    return (dispatch) => {
        axios.all([mostRecentReq, topRatedReq]).then(axios.spread((...ress) => {
            let filteredList1 = setPriority(ress[0].data.data)
            let filteredList2 = setPriority(ress[1].data.data)
            ress[0].data.data = filteredList1
            ress[1].data.data = filteredList2
            // callback(res);
            const res1 = ress[0]
            const res2 = ress[1]
            callback(res1, res2);
            // dispatch({
            //     type: SET_CLASSIFIEDS_CATEGORY_LISTING,
            //     payload: res.data
            // })
        })).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method bookingCategory
 * @description get all booking category
 */
export function getClassfiedCategoryDetail(data, callback) {
    const request = axios.post(`${API.getClassifiedDetail}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method newInClassified
 * @description get all new classified record 
 */
export function newInClassified(data, callback) {
    const request = axios.post(`${API.newInClassified}`, data)
    return (dispatch) => {
        
        request.then((res) => {
            
            let filteredList = setPriority(res.data.data)
            res.data.data = filteredList
            callback(res);
        }).catch(function (error) {
            

            apiErrors(error)
            callback(error);
        });
    }
}


/**
 * @method popularSearch
 * @description get papular search classified record 
 */
export function papularSearch(data, callback) {
    const request = axios.post(`${API.papularSearch}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
            dispatch({
                type: PAPULAR_SEARCH,
                payload: res.data
            })
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method mostPapularList
 * @description get most papular classified record 
 */
export function mostPapularList(callback) {
    const request = axios.get(`${API.mostPapular}`)
    return (dispatch) => {
        request.then((res) => {
            let filteredList = setPriority(res.data.feactured_category)
            res.data.feactured_category = filteredList
            callback(res);
            dispatch({
                type: MOST_PAPULAR_RECORD,
                payload: res.data
            })
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getSubscriptionsAction
 * @description get list of subscription plan
 */
export function getSubscriptionsAction(callback) {
    const request = axios.get(`${SUBSCRIPTION_API}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
            dispatch({
                type: GET_PLAN,
                payload: res.data
            })
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method savePlanAPI
 * @description used to save selected paln details
 */
export function savePlanDetailsAPI(data, callback) {
    const request = axios.post(`${API.savePlanDetails}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method createResumeAction
 * @description get list of subscription plan
 */
export function createResumeAction(reqData, callback) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    const request = axios.post(`${RESUME_API}`, reqData, headers)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
            // dispatch({
            //     type: GET_PLAN,
            //     payload: res.data
            // })
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getResumeAction
 * @description get list of subscription planResume
 */
export function getResumeAction(callback) {
    const request = axios.get(`${RESUME_API}`)
    return (dispatch) => {
        request.then((res) => {

            callback(res.data);
            dispatch({
                type: SET_RESUME_DETAILS,
                payload: res.data
            })
        })
    }
}


/**
 * @method applyJobAction
 * @description apply for an job 
 */
export function applyJobAction(requestData, callback) {
    // const headers = { 'Content-Type': 'multipart/form-data' }
    const request = axios.post(`${APPLY_JOB_API}`, requestData)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method deleteResumeAction
 * @description delete uloaded files
 */
export function deleteResumeAction(id, callback) {
    const request = axios.delete(`${RESUME_API}/${id}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method bookAnInspectionAction
 * @description book for an inspection
 */
export function bookAnInspectionAction(requestData, callback) {
    const request = axios.post(`${INSPECTION_API}`, requestData)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getJobQuestionsAction
 * @description get all job Questions
 */
export function getJobQuestionsAction(job_id, callback) {
    const request = axios.get(`${JOB_QUESTIONS_API}/${job_id}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {

            // apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getJobAnswersAction
 * @description get all job Questions
 */
export function getJobAnswersAction(params, callback) { 
    const request = axios.get(`${JOB_QUESTIONS_API}`, { params: params })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {

            // apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method foundAdsAPI
 * @description found ads api
 */
export function foundClassifiedUserAdsAPI(data, callback) {
    const request = axios.post(`${API.foundUserAds}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            // apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method newClassifiedList
 * @description new classified list
 */
export function newClassifiedList(data, callback) {
    const request = axios.post(`${API.newClassifiedList}`, data)
    return (dispatch) => {
        request.then((res) => {
            // let filteredList = setPriority(res.data.data)
            // res.data.data = filteredList
            callback(res);
        }).catch(function (error) {

            // apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getClassifiedLandingPageData
 * @description fetch all category api
 */
export function getClassifiedLandingPageData(data1, data2, data3, callback) {
    return (dispatch) => {
        const request1 = axios.post(`${API.newClassifiedList}`, data1)
        const request2 = axios.post(`${API.newClassifiedList}`, data2)
        const request3 = axios.post(`${API.newClassifiedList}`, data3)
        Promise.all([request1, request2, request3]).then((res) => {
            const mostRecent = res[0].data && res[0].data.data
            const top_rated = res[1].data && res[1].data.data;
            const recently_view = res[2].data && res[2].data.data;
            const masterData = {
                mostRecent, top_rated, recently_view
            };
            callback(masterData);
        }).catch((error) => {
            callback(error);
        });
    };
}


/**
 * @method foundAdsAPI
 * @description found ads api
 */
export function reportUserReviewAPI(data, callback) {
    const request = axios.post(`${API.reportUserReview}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            // apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method editClassifiedReview
 * @description edit classified review
 */
export function editClassifiedReview(data, callback) {
    const request = axios.post(`${API.updateClassifiedReview}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getJobApplicantDetails
 * @description get questions of job applicant and its submitted answer by job applicant
 * @data job_id
 */
export function getJobApplicantDetails(data, callback) {
    const request = axios.post(`${API.getJobApplicantDetails}`, data)
    return (dispatch) => {
        request.then((res) => {

            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}