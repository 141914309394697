import { all } from 'redux-saga/effects';

import {
  getCategoryListActionWatcher,
  getSubCategoryListActionWatcher,
  getRestaurantDefaultsActionWatcher,
  getFitnessDefaultsActionWatcher,
} from "./general";
import {
  getAdvertisementListActionWatcher,
  getPageListingActionWatcher,
} from "./pageData";
import {
  getItemDetailsActionWatcher,
} from "./Details";
import {
  getRetailListingActionWatcher,
} from "./Listing";


export default function* rootSaga() {
  yield all([
    getCategoryListActionWatcher(),
    getRestaurantDefaultsActionWatcher(),
    getFitnessDefaultsActionWatcher(),
    getSubCategoryListActionWatcher(),
    getAdvertisementListActionWatcher(),
    getPageListingActionWatcher(),
    getItemDetailsActionWatcher(),
    getRetailListingActionWatcher()
  ]);
}
