import axios from 'axios';
import { ADD_PASSENGER_DETAILS_API, FLIGHT_BOOKING_API, FLIGHT_BOOKING_PAYPAL_API, GET_FLIGHT_PNR_API, GET_RECOMMENDED_TOURISM_API } from '../../../../api';
import { API } from '../../../../config/Config';
import { apiErrors } from '../../../../config/HandleAPIErrors';
import { SET_ORIGIN, SET_TOKEN, SET_FLIGHT_SERACH_PARAMS, SET_MULTICITY_VALUE, SET_FLIGHT_RECORD } from '../../../constants'



/**
* @method multiCityInitialValues
* @description setMulticityInitialValues
*/
export function multiCityInitialValues(res) {
    return (dispatch) => {
        dispatch({
            type: SET_MULTICITY_VALUE,
            payload: res
        })
    }
}


/**
* @method addPassengerDetailsAction
* @descriptionhandle get flight which are most recomended
*/
export function addPassengerDetailsAction(data, callback) {

    const request = axios.post(`${ADD_PASSENGER_DETAILS_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {

            callback(error);
            apiErrors(error)
        });
    }
}

/**
* @method setTourismSearchData
* @description set tourism sercah request data
*/
export function setTourismFlightSearchData(res) {
    return (dispatch) => {
        dispatch({
            type: SET_FLIGHT_SERACH_PARAMS,
            payload: res
        })
    }
}

/**
* @method getFlightPnrNumberAction
* @descriptionhandle check flight available or not
*/
export function getFlightPnrNumberAction(data, callback) {
    const request = axios.post(`${GET_FLIGHT_PNR_API}`, data)
    return (dispatch) => {
        request.then((res) => {

            callback(res.data);
        }).catch(function (error) {

            callback(error);
            apiErrors(error)
        });
    }
}

/**
* @method setFlightToken
* @description set flight token
*/
export function setFlightToken(res) {
    return (dispatch) => {
        dispatch({
            type: SET_TOKEN,
            payload: res
        })
    }
}

/**
* @method flightBookingAction
* @descriptionhandle tourism flight booking api
*/
export function flightBookingAction(data, callback) {
    const request = axios.post(`${FLIGHT_BOOKING_API}`, data)
    return (dispatch) => {
        request.then((res) => {

            callback(res.data);
        }).catch(function (error) {

            callback(error);
            apiErrors(error)
        });
    }
}

/**
* @method paypalFlightBookingAction
* @descriptionhandle tourism flight booking api
*/
export function paypalFlightBookingAction(data, callback) {
    const request = axios.post(`${FLIGHT_BOOKING_PAYPAL_API}`, data)
    return (dispatch) => {
        request.then((res) => {

            callback(res.data);
        }).catch(function (error) {

            callback(error);
            apiErrors(error)
        });
    }
}


/**
* @method setCurrentOrigin
* @description set current origin
*/
export function setCurrentOrigin(res) {
    return (dispatch) => {
        dispatch({
            type: SET_ORIGIN,
            payload: res
        })
    }
}
