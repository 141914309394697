import axios from 'axios';
import { CLASSIFIED_ADS_API, CLASSIFIED_PAYMENT_API, CLASSIFIED_PAYMENT_DETAILS_API, INSPECTION_API, JOB_APPLICATIONS_API, SUBSCRIPTION_API } from '../../../api';
import { API } from '../../../config/Config';
import { apiErrors } from '../../../config/HandleAPIErrors';

require('dotenv').config();

/**
 * User get Ad management Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getClassifiedAdsAction(data, callback) {
    const request = axios.get(`${CLASSIFIED_ADS_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * Change Status
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function changeClassifiedStatus(data, callback) {
    const request = axios.post(`${API.changeClassifiedStatus}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * Change Status
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function changeApplicationStatus(data, callback) {
    const request = axios.post(`${JOB_APPLICATIONS_API}/${data.application_id}?_method=PUT`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * delete Classified
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function deleteClassifiedAction(data, callback) {
    const request = axios.delete(`${CLASSIFIED_ADS_API}/${data.id}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * delete Classified
 * @param getVendorClassified
 * @param callback
 * @returns {function(*)}
 */
export function getVendorClassified(data, callback) {
    const request = axios.post(`${API.getVendorClassified}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}


/**
 * delete Classified
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getCandidatesList(data, callback) {
    const request = axios.post(`${API.getJobApplications}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {

                callback(error)
                // apiErrors(error)
            });
    }
}


/**
 * @method getInspectionListAction
 * @description get business ad records
 */
export function getInspectionListAction(data, callback) {
    const request = axios.get(`${INSPECTION_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                // apiErrors(error)
            });
    }
}

/**
 * @method getUserInspectionList
 * @description get user ad records
 */
export function getUserInspectionList(data, callback) {
    const request = axios.post(`${API.userInspectionList}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                // apiErrors(error)
            });
    }
}

/**
 * @method updateUserInspectionList
 * @description update user ad records
 */
export function updateUserInspectionList(data, callback) {
    const request = axios.post(`${API.userUpdateInspection}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                // apiErrors(error)
            });
    }
}

/**
 * @method getInspectionListAPI
 * @description get inspection list data
 */
export function getInspectionListAPI(data, callback) {
    const request = axios.get(`${INSPECTION_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                // apiErrors(error)
            });
    }
}

/**
 * @method deleteInspectionAction
 * @description delete all inspection item
 */
export function deleteInspectionAction(inspection_id, callback) {
    const request = axios.delete(`${INSPECTION_API}/${inspection_id}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        }).catch(function (error) {
            callback(error)
            apiErrors(error)
        });
    }
}

/**
 * @method deleteInspectionAPI
 * @description delete inspection by id
 */
export function deleteInspectionAPI(data, callback) {
    const request = axios.post(`${API.deleteInspection}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        }).catch(function (error) {
            callback(error)
            apiErrors(error)
        });
    }
}

/**
 * Change Status
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getResumeDetail(data, callback) {
    const request = axios.get(`${JOB_APPLICATIONS_API}/${data.application_id}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * Save Payment
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function classifiedPaymentAction(data, callback) {
    const request = axios.post(`${CLASSIFIED_PAYMENT_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * Save Payment
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function createSubscriptionAction(data, callback) {
    const request = axios.post(`${SUBSCRIPTION_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * Save Payment Paypal
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function saveSubscriptionPaymentPaypal(data, callback) {
    const request = axios.post(`${API.subscriptionPaymentPaypal}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * Save Payment
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function saveBoostPayment(data, callback) {
    const request = axios.post(`${API.adBoostPayment}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * Save Payment Paypal
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function saveBoostPaymentPaypal(data, callback) {
    const request = axios.post(`${API.adBoostPaymentPaypal}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * Save Payment Paypal
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function savePaymentPaypal(data, callback) {
    const request = axios.post(`${API.paymentPaypal}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * Save Payment Paypal
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getPaymentDetailPackage(data, callback) {
    const request = axios.post(`${API.paymentDetailPackage}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * get Payment Detail Action
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getPaymentDetailAction(data, callback) {
    const request = axios.get(`${CLASSIFIED_PAYMENT_DETAILS_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}