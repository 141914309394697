import { put, takeLatest, call } from 'redux-saga/effects';
import {
    GET_ITEM_DETAILS,
} from "../constants";
import {
    ITEM_DETAIL_API,
} from "../../api";
import axios from "../../api/axios"
import {
    getItemDetailsError,
    getItemDetailsSuccess,
    handleErrorResponse,
} from '../actions';


/**
 * 
 * @param {page_id} params 
 * @returns page product and services list
 */
function getItemDetailsApi(params) {
    let url = ITEM_DETAIL_API + params.page;

    return axios.request({
        method: "get",
        url: url,
        params: params,
    });
}

function* fetchItemDetails(action) {
    let { payload, resolve, reject } = action;
    try {
        let res = yield call(getItemDetailsApi, payload);
        yield put(getItemDetailsSuccess(res.data));
        if (resolve) resolve(res.data)
    } catch (e) {
        handleErrorResponse(e)
        yield put(getItemDetailsError(e))
        if (reject) reject(e)
    }
}

export function* getItemDetailsActionWatcher() {
    yield takeLatest(GET_ITEM_DETAILS, fetchItemDetails)
}