import axios from 'axios';
import { API } from '../../../config/Config';
import {
    BUSINESS_REGISTRATION_API,
    USER_REGISTRATION_API,
    USER_PROFILE_API,
    USER_DETAILS_API,
    USER_MENU_API,
    LOGIN_API,
    SOCIAL_LOGIN_API,
    SEND_OTP_API,
    UPDATE_PASSWORD_API,
    RESET_PASSWORD_API,
    FORGOT_PASSWORD_API
} from "../../../api";
import { apiErrors } from '../../../config/HandleAPIErrors';
import { SET_LOGGED_IN_USER, LOGOUT, GET_USER_PROFILE, SET_USER_MENU_LIST } from '../../constants';
require('dotenv').config();

let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json'
    }
};

/**
 * business Registration Action action
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function businessRegistrationAction(requestData, callback = () => { }) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    return (dispatch) => {
        axios.post(BUSINESS_REGISTRATION_API, requestData, headers)
            .then((res) => {
                callback(res.data);
            })
            .catch((error) => {
                apiErrors(error);
                callback(error);
            });
    };
}


/**
 * User loginAction action
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function loginAction(data, callback = () => { }, reject) {
    const request = axios.post(LOGIN_API, data, axiosConfig)
    return (dispatch) => {
        request.then((res) => {
            dispatch({
                type: SET_LOGGED_IN_USER,
                payload: res.data
            })
            callback(res.data);
        }).catch(function (error) {
            apiErrors(error)
            reject(error?.response?.data);
        });
    }
}
/**
* @method getUserProfileAction
* @description get user profile data 
*/
export function getUserProfileAction(token) {
    let header = ''
    if (token)
        header = { headers: { "Authorization": `Bearer ${token}` } }

    const request = axios.get(`${USER_PROFILE_API}`, header)
    return (dispatch) => {
        request.then((res) => {
            dispatch({
                type: GET_USER_PROFILE,
                payload: res.data.data
            })
        })
            .catch(function (error) {
                apiErrors(error)
            });
    }
}

/**
* @method getUserDetailsAction
* @description get user profile data 
*/
export function getUserDetailsAction(user_id, callback) {
    const request = axios.get(`${USER_DETAILS_API}${user_id}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        })
            .catch(function (error) {
                callback(error);
            });
    }
}

export function verifyOtp(reqData, callback = () => { }) {
    const { mobileNo, code } = reqData
    //const request = axios.post(`https://api.ringcaptcha.com/jele5e5uhi9i5ipiryqi/code/sms?phone=91${mobileNo}&api_key=4b0e05b17a0c5425bfab4f83ad79a80060a73ec7`)
    const request = axios.post(`${API.verifyOtp}`, { phone: `${mobileNo}`, code })
    return (dispatch) => {
        return request.then((res) => {
            callback(res)
        }).catch((err) => {
            let errType = String(err.res.data.msg)
            if (errType === 'ERROR_INVALID_NUMBER') {
                err.res.data.msg = 'Your entered contact number is invalid.'
            } else if (errType === 'ERROR_WAIT_TO_RETRY') {
                err.res.data.msg = 'Please retry after some time.'
            } else if (errType === 'ERROR_MAX_ATTEMPTS_REACHED') {
                err.res.data.msg = 'You have reached to maximum attempts.'
            } else if (errType === 'ERROR_INVALID_PIN_CODE') {
                err.res.data.msg = 'Your entered otp does not matched.'
            } else if (errType === 'ERROR_TRANSACTION_LIMIT_EXCEEDED') {
                err.res.data.msg = 'You have reached to maximum attempts.'
            }
            apiErrors(err)
            callback(err)
        })
    }
}
export function sendOtpAction(reqData, callback = () => { }) {
    const { phone, countryCode } = reqData
    const request = axios.post(SEND_OTP_API, { phone: `${countryCode}${phone}` })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        }).catch((err) => {
            let errType = String(err.res.data.msg)
            if (errType === 'ERROR_INVALID_NUMBER') {
                err.res.data.msg = 'Your entered contact number is invalid.'
            } else if (errType === 'ERROR_WAIT_TO_RETRY') {
                err.res.data.msg = 'Please retry after some time.'
            } else if (errType === 'ERROR_MAX_ATTEMPTS_REACHED') {
                err.res.data.msg = 'You have reached to maximum attempts.'
            } else if (errType === 'ERROR_INVALID_PIN_CODE') {
                err.res.data.msg = 'Your entered otp does not matched.'
            } else if (errType === 'ERROR_TRANSACTION_LIMIT_EXCEEDED') {
                err.res.data.msg = 'You have reached to maximum attempts.'
            }
            apiErrors(err)
            callback(err)
        })
    }
}





/**
 * @method userRegistrationAction
 * @description Register user by email
 */
export function userRegistrationAction(requestData, callback) {
    return (dispatch) => {
        axios.post(USER_REGISTRATION_API, requestData)
            .then((res) => {
                callback(res.data);
            })
            .catch((error) => {
                apiErrors(error);
                callback(error);
            });
    };
}

/**
 * @method socialRegistrationAction
 * @description Register user by email
 */
export function socialRegistrationAction(requestData, callback) {
    return (dispatch) => {
        axios.post(SOCIAL_LOGIN_API, requestData, axiosConfig)
            .then((res) => {
                dispatch({
                    type: SET_LOGGED_IN_USER,
                    payload: res.data
                })
                callback(res.data);
            })
            .catch((error) => {
                apiErrors(error);
                callback(error);
            });
    };
}

/**
* @method changePasswordAction
* @description change user password
*/
export function changePasswordAction(requestData, callback = () => { }) {
    const request = axios.post(UPDATE_PASSWORD_API, requestData, axiosConfig)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * User forgot password action
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function forgotPasswordAction(data, callback = () => { }) {
    const request = axios.post(`${FORGOT_PASSWORD_API}`, data, axiosConfig)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            apiErrors(error)
            callback(error);
        });
    }
}


/**
 * User reset password action
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function resetPasswordAction(data, callback = () => { }) {
    const request = axios.post(`${RESET_PASSWORD_API}`, data, axiosConfig)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);

        }).catch(function (error) {
            apiErrors(error)
            callback(error);
        });
    }
}




/**
* @method logoutAction
* @description logout from
*/
export function logoutAction() {
    return (dispatch) => {
        dispatch({ type: LOGOUT })
    }
}


/**
* @method getUserMenuAction
* @description get user profile data 
*/
export function getUserMenuAction(token, callback = () => { }) {
    let header = ''
    if (token)
        header = { headers: { "Authorization": `Bearer ${token}` } }
    const request = axios.get(`${USER_MENU_API}`, header)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
            dispatch({
                type: SET_USER_MENU_LIST,
                payload: res.data
            });
        }).catch(function (error) {
            apiErrors(error)
        });
    }
}





