import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from ".";
import { getLocalStorage } from "../common/Methods";
// import cors from "cors"

// cors.apply()
const instance = axios.create({
  BASE_URL: API_URL,
  // timeout: 40000,
  // withCredentials: true,
  // credentials: 'same-origin'
});

instance.interceptors.request.use((reqConfig) => {
  let authToken = getLocalStorage().authToken;
  if (authToken) {
    reqConfig.headers.common['Authorization'] = "Bearer " + authToken;
  }
  return reqConfig;
}, (err) => {
})

instance.interceptors.response.use((response) => {
  return response
}, (err) => {
  if (err && err.response && err.response.status == 403) {
    toast.error('Unathorized access!')
  }

  return Promise.reject(err)
})

export default instance;