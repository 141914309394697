import axios from 'axios';
import { RETAIL_ADS_API } from '../../../api';
import { API } from '../../../config/Config';
import { apiErrors } from '../../../config/HandleAPIErrors';
import { SET_ADDRESS_TYPE } from '../../constants'


/**
 * @method sellerFeedbackListAPI
 * @description seller feedback list api
 */
export function sellerFeedbackListAPI(data, callback) {
    const request = axios.post(`${API.sellerFeedbackList}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method sellerFeedbackListAPI
 * @description seller feedback list api
 */
export function replyFeedbackAPI(data, callback) {
    const request = axios.post(`${API.replyFeedback}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getRetailAdListAction
 * @description get admanagement list of retail business
 */
export function getRetailAdListAction(data, callback) {
    const request = axios.get(`${RETAIL_ADS_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {


            callback(error);
        });
    }
}

/**
 * @method deleteRetailAdAction
 * @description delete retail ads api
 */
export function deleteRetailAdAction(data, callback) {
    const request = axios.delete(`${RETAIL_ADS_API}/${data.id}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method changeRetailStatusAPI
 * @description change retail ads status
 */
export function changeRetailStatusAPI(data, callback) {
    const request = axios.post(`${API.changeRetailStatus}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method deleteUploadedImagesOfPostAD
 * @description delete post ad uploaded images
 */
export function deleteUploadedImagesOfPostAD(data, callback) {
    const request = axios.post(`${API.deletePostAnAdUploadedImages}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method deleteUploadedImagesOfPostAD
 * @description delete post ad uploaded images
 */
export function deleteClassifiedOfferReceivedAPI(data, callback) {
    const request = axios.post(`${API.deleteclassifiedofferreceived}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}


