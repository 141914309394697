import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect } from "react-router";
import { langs } from "../config/localization";
import { connect } from "react-redux";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { Loader } from '../components/common/Loader';
import history from '../common/History';
import AuthMiddleware from '../AuthMiddleware';
import ScrollToTop from '../components/common/ScrollToTop';
import { getMainCategory } from "../common/Navigation";

const PageNotFound = lazy(() => import('../common/NotFoundPage'));
const UnAuthorized = lazy(() => import('../common/UnAuthorizedAcess'));
const WelComePage = lazy(() => import('../components/layout'));
const ProfileSetup = lazy(() => import('../components/profile'));
const ViewProfile = lazy(() => import('../components/profile/view'));
const SignUp = lazy(() => import('../components/auth/registration'));
const EditProfile = lazy(() => import('../components/vendor/classified/classified-vendor-profile-setup'));
// const BusinessEditProfile = lazy(() => import('../components/vendor/classified/classified-vendor-profile-setup'));
const VendorEditProfile = lazy(() => import('../components/dashboard/vendor-profiles/common-profile-setup'));
const MyProfile = lazy(() => import('../components/vendor/classified/classified-vendor-profile-setup/Myprofile'));
const Message = lazy(() => import('../components/dashboard/message/message'));
const MapView = lazy(() => import('../components/common/MapView'));
const SeeMore = lazy(() => import('../components/classified-templates/automative/SeeMore'));
const JobsSeeMore = lazy(() => import('../components/classified-templates/jobs/SeeMore'));
const CommonFilter = lazy(() => import('../components/common/Filter'));
const SimpleLandingPage = lazy(() => import('../components/classified-templates/automative'));
// const JobLandingPage = lazy(() => import('../components/classified-templates/jobs'));
const Template1DetailPage = lazy(() => import('../components/classified-templates/automative/Details'));
const JobsDetailPage = lazy(() => import('../components/classified-templates/jobs/Detail'));
const Template3DetailPage = lazy(() => import('../components/classified-templates/real-state/Details'));
const InterMediateStep = lazy(() => import('../components/auth/intermediate-step'));
const ChangePassword = lazy(() => import('../components/auth/ChangePassword'));
const Dashboard = lazy(() => import('../components/dashboard'));

const MyAds = lazy(() => import('../components/ad-management/MyAds'));
const MyAdsUser = lazy(() => import('../components/dashboard/my-profile/user-dashboard/MyAds'));
const BoostAdPayment = lazy(() => import('../components/vendor/StepSecond-Enhanced'));

const JobApplicationList = lazy(() => import('../components/vendor/classified/employer-vendor/JobApplicationList'));
const ApplicationDetails = lazy(() => import('../components/vendor/classified/employer-vendor/ApplicationDetails'));

const BookingLandingPage = lazy(() => import('../components/booking'));
const PServicesLandingPage = lazy(() => import('../components/booking/professional-services'));
const HandyManLandingPage = lazy(() => import('../components/booking/handy-man'));
const HandyManSubCategoryPage = lazy(() => import('../components/booking/handy-man/SubCatgory'));
const HandyDetailPage = lazy(() => import('../components/booking/handy-man/Details'));

const HandyManSeeMorePage = lazy(() => import('../components/booking/handy-man/SeeMore'));

const BookingSubcategorySeeMorePage = lazy(() => import('../components/booking/beauty/SeeMore'));
const BookingMapView = lazy(() => import('../components/booking/handy-man/MapView'));
const RestaurantMapView = lazy(() => import('../components/booking/restaurent/MapView'));

const BeautyLandingPage = lazy(() => import('../components/booking/beauty'));
const BeautySubCategoryPage = lazy(() => import('../components/booking/beauty/SubCategory'));
const BeautySearchPage = lazy(() => import('../components/booking/beauty/SearchView'));
const SportsSearchPage = lazy(() => import('../components/booking/sports/SearchView'));
const RestaurantSearchPage = lazy(() => import('../components/booking/restaurent/SearchView'));
const SportsLandingPage = lazy(() => import('../components/booking/sports'));

const EventsLandingPage = lazy(() => import('../components/booking/events'));
const WellBeingLandingPage = lazy(() => import('../components/booking/wellbeing'));
const RestaurentLandingPage = lazy(() => import('../components/booking/restaurent'));

const ClassifiedsLandingPage = lazy(() => import('../components/classified-templates'));
const SimpleSubCategory = lazy(() => import('../components/classified-templates/real-state/SubCategory'));
//const BookingLandingPage1 = lazy(() => import('../components/classified-templates/booking/LandingPage'

const RestaurantReviewsPage = lazy(() => import('../components/booking/restaurent/RestaurantReviews'));
const SportsDetailPage = lazy(() => import('../components/booking/sports/SportsDetailsPage'));

const PopularEventSeeMorePage = lazy(() => import('../components/booking/common/PopularSeeAll'));
const BookingMapDetailView = lazy(() => import('../components/booking/common/MapDetailView'));
const PopularSportsSeeAll = lazy(() => import('../components/booking/sports/PopularSportsSeeAll'));
const TicketListing = lazy(() => import('../components/listing-pages/TicketListing'));
const PromoSeeAll = lazy(() => import('../components/booking/common/PromoSeeAll'));

// Profile Routes Import
// const EventCatereEditProfile = lazy(() => import('../components/dashboard/vendor-profiles/common-profile-setup/index'));
// const EventCatereMyProfile = lazy(() => import('../components/dashboard/vendor-profiles/MyProfile'));
const FitnessMyProfileSetup = lazy(() => import('../components/classes-and-membership/Listing'));
const FitnessEditClass = lazy(() => import('../components/dashboard/vendor-profiles/fitness/manage-class/EditClass'));
const FitnessVenderMyBookings = lazy(() => import('../components/bookings/fitness'));
// Customer Restaurant Orders
const CustomerMyBookings = lazy(() => import('../components/bookings'));
const HandymanBookings = lazy(() => import('../components/bookings/handyman'));
const HandymanBookingCheckoutSuccess = lazy(() => import('../components/bookings/handyman/handymanBookingDetails.js'));
const handymanBookingDetails = lazy(() => import('../components/bookings/handyman/handymanBookingDetails'));
const EventBookings = lazy(() => import('../components/bookings/events'));
const MyBookings = lazy(() => import('../components/bookings/wellbeing/WellbeingBookings'));
const CustomerMyBookingsBeauty = lazy(() => import('../components/bookings/beauty/BeautyBookings'));
const MyRestaurantOrders = lazy(() => import('../components/bookings/restaurant'));
const RestaurantVendorOrderList = lazy(() => import('../components/bookings/restaurant/RestaurantVendorOrdersList'));
const OrderTracking = lazy(() => import('../components/bookings/restaurant/OrderTracking'));
const TrackUserRestaurantOrders = lazy(() => import('../components/bookings/restaurant/TrackUserRestaurantOrders'));

// const BookingListingpagePage = lazy(() => import('../components/classified-templates/booking/ListingPage'
// const BookingListingpageList = lazy(() => import('../components/classified-templates/booking/ListingPageList'
// const BookingListDetails = lazy(() => import('../components/classified-templates/booking/ListingDetailsPage'
const PopularViewSeeAll = lazy(() => import('../components/classified-templates/SeeAll'));
const MapViewDetail = lazy(() => import('../components/common/MapDetailView'));
const ResumeBuilder = lazy(() => import('../components/classified-templates/jobs/resume-builder'));

//Profile
// const BeautyServiceList = lazy(() => import('../components/dashboard/vendor-profiles/beauty/Listing'));
const UpdateBeautyService = lazy(() => import('../components/dashboard/vendor-profiles/beauty/CreateBeautyService'));
const UpdateServive = lazy(() => import('../components/dashboard/vendor-profiles/beauty/UpdateBeautyService'));
const RestaurantMenu = lazy(() => import('../components/profile/RestaurantMenu'));
// const RestaurantMenu = lazy(() => import('../components/dashboard/vendor-profiles/restaurant/RestaurantMenu'));
const UpdateRestaurantMenu = lazy(() => import('../components/dashboard/vendor-profiles/restaurant/UpdateMenu'));
const AddRestaurantMenu = lazy(() => import('../components/profile/AddRestaurantMenu'));
const AddServices = lazy(() => import('../components/dashboard/vendor-profiles/spa/AddServices'));
// Daily Deals
const CreateDailyDeals = lazy(() => import('../components/daily-deals'));
const DailyDeals = lazy(() => import('../components/daily-deals/Listing'));
// Promotions
const CreatePromotions = lazy(() => import('../components/promotions'));
const Promotions = lazy(() => import('../components/promotions/Listing'));
// Services
const Services = lazy(() => import('../components/services/ServiceList'));
const UpdateServices = lazy(() => import('../components/services/UpdateService'));
// Packages
const Packages = lazy(() => import('../components/packages/Listing'));
const CreatePackages = lazy(() => import('../components/packages'));
// Special Offers
const SpecialOffers = lazy(() => import('../components/special-offers/Listing'));
const CreateSpecialOffers = lazy(() => import('../components/special-offers'));

//m4 retail module routes ui
const VendorRetailInvoice = lazy(() => import('../components/vendor/retail/viewinvoice'));
// const UserRetailDashboard = lazy(() => import('../components/retail/user-retail/index'));
const UserRetailCartDetail = lazy(() => import('../components/retail/retail-cart/Retailcart'));

// dashboard-classified
const UserClassifiedsDashboard = lazy(() => import('../components/classified-templates/user-classified/index.js'));
// food scanner
const FoodScannerLandingPage = lazy(() => import('../components/food-scanner/index'));

const FoundUserAds = lazy(() => import('../components/classified-templates/FoundAds'));

const CustomerSpaBookingDetails = lazy(() => import('../components/dashboard/my-profile/spa/CustomerSpaBookingDetails'));
const CustomerSpaBookingHistoryDetails = lazy(() => import('../components/dashboard/my-profile/spa/CustomerSpaBookingHistoryDetails'));
// const VendorMyBookingsSpa = lazy(() => import('../components/dashboard/vendor-profiles/spa/my-bookings/spa'));
const VendorSpaBookingDetails = lazy(() => import('../components/dashboard/vendor-profiles/spa/my-bookings/spa/VendorSpaBookingDetails'));
const VendorSpaMyBookingCalender = lazy(() => import('../components/dashboard/vendor-profiles/spa/my-bookings/spa/VendorSpaMyBookingCalender'));
const VendorSpaBookingHistoryDetails = lazy(() => import('../components/dashboard/vendor-profiles/spa/my-bookings/spa/VendorSpaBookingHistoryDetails'));


//Classified Vender Routes
const GeneralVendorMyOffer = lazy(() => import('../components/vendor/classified/general/OfferList'));
const GeneralVendorMyOfferDetail = lazy(() => import('../components/vendor/classified/general/OfferDetails'));
const InspectionList = lazy(() => import('../components/vendor/classified/real-state/InspectionList'));
const InspectionDetails = lazy(() => import('../components/inspection/InspectionDetails'));
const BookingDashboard = lazy(() => import('../components/dashboard/BookingDashboard'));

//m4 retail module routes
const RetailLandingPage = lazy(() => import('../components/retail'));
const RetailCatLandingPage = lazy(() => import('../components/retail/retail-categories/LandingPage'));
const RetailSubCategoryPage = lazy(() => import('../components/retail/retail-categories/SubCatgory'));
// const RetailDetailPage = lazy(() => import('../components/retail/retail-categories/Details'));
const ProductDetailsPage = lazy(() => import('../components/detail-pages/ProductDetails'));
const BusinessDetailPage = lazy(() => import('../components/detail-pages/BusinessDetailPage'));
const RestaurantDetailPage = lazy(() => import('../components/detail-pages/RestaurantDetails'));
const ClassList = lazy(() => import('../components/detail-pages/view-classes'));
const ClassSchedule = lazy(() => import('../components/detail-pages/view-schedule'));
const FitnessMemberships = lazy(() => import('../components/detail-pages/MembershipList'));
const ClassifiedDetailsPage = lazy(() => import('../components/detail-pages/classified'));
const ClassifiedJobsDetailsPage = lazy(() => import('../components/detail-pages/classified/ClassifiedJobs'));
const ClassifiedRealEstateDetailsPage = lazy(() => import('../components/detail-pages/classified/RealEstate'));
const RetailListing = lazy(() => import('../components/listing-pages/RetailListing'));
const BookingListing = lazy(() => import('../components/listing-pages/BookingListing'));
const BusinessAdsListing = lazy(() => import('../components/listing-pages/BusinessAdsListing'));
const ClassifiedListing = lazy(() => import('../components/listing-pages/ClassifiedListing'));
const RetailSeeMore = lazy(() => import('../components/retail/retail-categories/SeeMore'));
const RetailFilterPage = lazy(() => import('../components/retail/Filter'));

//m4 retail vendor routes
// const VendorRetailEditProfile = lazy(() => import('../components/vendor/retail/vendor-profile-setup/index'));
const ReceivedOrder = lazy(() => import('../components/orders/received/Orders'));
const ReceivedOrderDetail = lazy(() => import('../components/vendor/retail/ReceivedOrderDetail'));
const VendorRetailDashboard = lazy(() => import('../components/vendor/retail/index'));
const VendorTransaction = lazy(() => import('../components/transactions'));
const RetailPaymentScreen = lazy(() => import('../components/vendor/retail/vendor-profile-setup/RetailPayment'));
const RetailDailyDeals = lazy(() => import('../components/vendor/retail/daily-deals'));
const RetailDailyDealsList = lazy(() => import('../components/vendor/retail/daily-deals/Listing'));

//Ads
const PostAd = lazy(() => import('../components/post-ad/classified'));
const RetailPostAd = lazy(() => import('../components/post-ad/retail'));
const UpdateRetailAd = lazy(() => import('../components/post-ad/retail/RetailEditPost'));
const UpdateAd = lazy(() => import('../components/post-ad/classified/update-post-ad/EditPost'));

// Retails orders
const RetailOrders = lazy(() => import('../components/orders/retail'));

// Vendor Restaurant Dashboard
const RestaurantVendorDashboard = lazy(() => import('../components/dashboard/vendor-profiles/restaurant/my-bookings/RestaurantVendorDashboard'));
// Vendor Restaurant Dashboard

const CustomerBeautyBookingDetails = lazy(() => import('../components/dashboard/my-profile/beauty/my-booking/CustomerBeautyBookingDetails'));
const CustomerBeautyBookingHistoryDetails = lazy(() => import('../components/dashboard/my-profile/beauty/my-booking/CustomerBeautyBookingHistoryDetails'));

//Vendor Beauty booking
// const VendorMyBookingsBeauty = lazy(() => import('../components/dashboard/vendor-profiles/beauty/my-bookings/beauty'));
const VendorBeautyBookingDetails = lazy(() => import('../components/dashboard/vendor-profiles/beauty/my-bookings/beauty/VendorBeautyBookingDetails'));
const VendorBeautyMyBookingCalender = lazy(() => import('../components/dashboard/vendor-profiles/beauty/my-bookings/beauty/VendorBeautyMyBookingCalender'));
const VendorBeautyBookingHistoryDetails = lazy(() => import('../components/dashboard/vendor-profiles/beauty/my-bookings/beauty/VendorBeautyBookingHistoryDetails'));

// Fitness Vendor Mange calendar
const FitnessVenderMyCalendar = lazy(() => import('../components/dashboard/vendor-profiles/fitness/manage-calendar'));

//Schedule
const MembershipList = lazy(() => import('../components/booking/wellbeing/fitness/MembershipList'));
const Schedule = lazy(() => import('../components/booking/wellbeing/fitness/view-schedule'));
const Classes = lazy(() => import('../components/booking/wellbeing/fitness/view-classes'));

const BookingCheckout = lazy(() => import('../components/checkout/BookingCheckout'));
const RestaurentCheckout = lazy(() => import('../components/booking/checkout/RestaurentCheckout'));
// handyman checkout success page
// handyman checkout success page


//handyman
const HandyManDashboard = lazy(() => import('../components/dashboard/vendor-profiles/handyman/my-bookings/dashboard'));
/**UI */

// my event bookings
//import
const MyEventBooking = lazy(() => import('../components/dashboard/my-profile/event/my-booking/'));
const MyEventBookingDetails = lazy(() => import('../components/dashboard/my-profile/event/my-booking/EventBookingDetails'));

const EventVendorCaterer = lazy(() => import('../components/dashboard/my-profile/vendor/event-vendor-caterer/'));
const ProfileVendorHandyman = lazy(() => import('../components/dashboard/my-profile/vendor/profile-vendor-handyman/'));
const ProfileVendorBeauty = lazy(() => import('../components/dashboard/my-profile/vendor/profile-vendor-beauty/'));
const ProfileVendorRestaurant = lazy(() => import('../components/dashboard/my-profile/vendor/profile-vendor-restaurant'));
const ProfileVendorFitness = lazy(() => import('../components/dashboard/my-profile/vendor/fitness-vendor-profile/'));

/**Restaurant Cart */

const RestaurantCartDetail = lazy(() => import('../components/checkout/RestaurantCartDetail'));
const FitnessVendorDashboard = lazy(() => import('../components/dashboard/vendor-profiles/fitness/dashboard/FitnessVendorDashboard'));
const CustomerFitnessBookingDetails = lazy(() => import('../components/dashboard/my-profile/fitness/my-bookings/CustomerFitnessBookingDetails'));
const CustomerFitnessBookingHistoryDetails = lazy(() => import('../components/dashboard/my-profile/fitness/my-bookings/CustomerFitnessBookingHistoryDetails'));
const OrderDetail = lazy(() => import('../components/orders/Detail'));
const RestaurantPastOrderDetail = lazy(() => import('../components/dashboard/my-profile/restaurant/my-orders/RestaurantPastOrderDetail'));
const OrderDetailsInvoice = lazy(() => import('../components/orders/Invoice'));
const EventVendorDashboard = lazy(() => import('../components/dashboard/vendor-profiles/event-caterer/dashboard/EventVendorDashboard'));
const FoodProductDetailPage = lazy(() => import('../components/food-scanner/FoodProductDetailPage'));
const AddProduct = lazy(() => import('../components/food-scanner/AddProduct'));
const SeeAllProducts = lazy(() => import('../components/food-scanner/SeAllProduct'));
const CompareFoodProduct = lazy(() => import('../components/food-scanner/ComparisionProducts'));
// const AboutUs = lazy(() => import('../components/layout/footer/StaticPages'));
const StaticPages = lazy(() => import('../components/layout/footer/StaticPages'));
const MobileAppScreen = lazy(() => import('../components/layout/footer/MobileAppStaticScreen'));
const ContactPages = lazy(() => import('../components/layout/footer/ContactStatic'));

const FAQPage = lazy(() => import('../components/layout/footer/FAQPage'));

const UserDashboard = lazy(() => import('../components/dashboard/my-profile/user-dashboard'));
const UserNotification = lazy(() => import('../components/dashboard/my-profile/user-dashboard/UserNotification'));
const UserWishlist = lazy(() => import('../components/dashboard/my-profile/user-dashboard/UserWishlist'));
const TestCard = lazy(() => import('../components/TestCard'));

const UserReview = lazy(() => import('../components/reviews/UserReview'));
const Review = lazy(() => import('../components/reviews/VendorReviews'));
// const SellerFeedback = lazy(() => import('../components/vendor/retail/vendor-reviews/SellerFeedback'));
const RetailProductReview = lazy(() => import('../components/vendor/retail/vendor-reviews/ProductReviewList'));

// Retail Checkout Page UI
// const RetailCheckout = lazy(() => import('../components/retail/retail-categories/checkout-process/RetailCheckout'));

// Retail Checkout Order Summary Page UI
const CheckoutOrderSummary = lazy(() => import('../components/retail/retail-categories/checkout-process/CheckoutOrderSummary'));

//* Retail Payment Complete Page UI */
const PaymentComplete = lazy(() => import('../components/retail/retail-categories/checkout-process/PaymentComplete'));

// const RetailCheckoutProcess = lazy(() => import('../components/retail/retail-categories/checkout-process/index'));
const RetailCheckoutProcess = lazy(() => import('../components/checkout/Retail'));
const RestaurantPaymentComplete = lazy(() => import('../components/booking/restaurent/restaurant-cart/PaymentComplete'));

//* Retail Payment Method Page */
const PaymentMethods = lazy(() => import('../components/dashboard/vendor-profiles/vendor-payment/PaymentMethods'));
const EditPaymentMethods = lazy(() => import('../components/dashboard/vendor-profiles/vendor-payment/EditPaymentMethods'));
const AddPaymentMethods = lazy(() => import('../components/dashboard/vendor-profiles/vendor-payment/AddpaymentMethod'));

///* Billing History Page */
const BillingHistory = lazy(() => import('../components/payments/billing-history/BillingHistory'));
const BillingInvoice = lazy(() => import('../components/payments/billing-history/BillingInvoice'));
const UserBillingHistory = lazy(() => import('../components/payments/billing-history/UserBillingHistory'));
const UserBillingInvoice = lazy(() => import('../components/payments/billing-history/UserBillingInvoice'));


///* Billing History Page */
const PaymentMethodsUser = lazy(() => import('../components/dashboard/my-profile/user-dashboard/user-payment/PaymentMethods'));
const EditPaymentMethodsUser = lazy(() => import('../components/dashboard/my-profile/user-dashboard/user-payment/EditPaymentMethods'));
const AddpaymentMethodsUser = lazy(() => import('../components/dashboard/my-profile/user-dashboard/user-payment/AddpaymentMethod'));
///* Subscription Page */
const Subscription = lazy(() => import('../components/dashboard/vendor-profiles/vendor-subscription/Subscription'));
const Payment = lazy(() => import('../components/dashboard/vendor-profiles/vendor-subscription/StepSecond-Enhanced'));

//* Profile Payment Method Page */
// const ProfilePaymentMethods = lazy(() => import('../components/vendor/classified/classified-vendor-profile-setup/PaymentMethods'));
const ProfileEditPaymentMethods = lazy(() => import('../components/vendor/classified/classified-vendor-profile-setup/EditPaymentMethods'));
const ProfileAddPaymentMethods = lazy(() => import('../components/vendor/classified/classified-vendor-profile-setup/AddpaymentMethod'));

//* Tourism Page */
const TourismLandingPage = lazy(() => import('../components/booking/tourism'));
const FlightLandingPage = lazy(() => import('../components/booking/tourism/flight'));
const CarLandingPage = lazy(() => import('../components/booking/tourism/car'));
const FlightList = lazy(() => import('../components/booking/tourism/flight/ListPage'));
const CarList = lazy(() => import('../components/booking/tourism/car/CarList'));
const CarMapView = lazy(() => import('../components/booking/tourism/car/MapView'));
const CarMapView2 = lazy(() => import('../components/booking/tourism/car/MapView2'));
const FlightBookings = lazy(() => import('../components/booking/tourism/flight/flight-bookings'));
const TourismSeeAll = lazy(() => import('../components/booking/tourism/SeeAll'));
// const OneWayReturn = lazy(() => import('../components/booking/tourism/flight/flight-bookings/OneWayReturn'));
// const BookingForm = lazy(() => import('../components/booking/tourism/flight/flight-bookings/BookingForm'));
// const FlightCheckout = lazy(() => import('../components/booking/tourism/flight/flight-bookings/FlightCheckout'));

const CarDetails = lazy(() => import('../components/booking/tourism/car/car-checkout'));
const CarBookingForm = lazy(() => import('../components/booking/tourism/car/car-checkout/CarBookingForm'));
const CarCheckout = lazy(() => import('../components/booking/tourism/car/car-checkout/CarCheckout'));

const HotelLandingPage = lazy(() => import('../components/booking/tourism/hotel'));
const HotelDetails = lazy(() => import('../components/booking/tourism/hotel/hotel-checkout/HotelDetails'));
//const HotelDetails = lazy(() => import('../components/booking/tourism/hotel/hotel-checkout/HotelDescription'));
const HotelBookingForm = lazy(() => import('../components/booking/tourism/hotel/hotel-checkout/HotelBookingForm'));
const HotelCheckout = lazy(() => import('../components/booking/tourism/hotel/hotel-checkout/HotelCheckout'));
const HotelSearchList = lazy(() => import('../components/booking/tourism/hotel/HotelList'));
const HotelMapView = lazy(() => import('../components/booking/tourism/hotel/MapView'));
const HotelMapView2 = lazy(() => import('../components/booking/tourism/hotel/MapView2'));

const BookingCalendarView = lazy(() => import('../components/bookings/calendar/Calendar'));
const TicketDetails = lazy(() => import('../components/booking/sports/TicketDetails'));
const TicketDetailForm = lazy(() => import('../components/booking/sports/TicketDetailForm'));
const TicketBooking = lazy(() => import('../components/booking/sports/TicketBooking'));

const RetailPaymentComplete = lazy(() => import('../components/dashboard/my-profile/PaymentComplete'));
const MostBooked = lazy(() => import('../components/booking/tourism/hotel/MostBooked'));
const Dashboards = lazy(() => import('../components/dashboards'));




class Routes extends React.Component {
  render() {
    const { isLoggedIn, isPrivateUser, menuSkiped, loggedInUser, seller_type, isFitnessBusiness } = this.props;
    let showDeals = [langs.sellerType.beauty, langs.sellerType.wellbeing, langs.sellerType.restaurant].includes(seller_type)
    let showPackages = [langs.sellerType.beauty, langs.sellerType.wellbeing, langs.sellerType.restaurant].includes(seller_type)
    return (
      <Suspense fallback={<Loader />}>
        <ScrollToTop />
        <Switch>
          <Route history={history} exact path='/signup' component={SignUp} />
          {/* {isLoggedIn && (<> */}
          <Route history={history} exact path='/profile' component={AuthMiddleware(ViewProfile)} />
          <Route history={history} exact path='/profile-setup/:cid' component={AuthMiddleware(ProfileSetup)} />
          <Route history={history} exact path='/profile-setup/:step' component={AuthMiddleware(ProfileSetup)} />
          <Route history={history} exact path='/profile-setup' component={AuthMiddleware(ProfileSetup)} />
          <Route history={history} exact path='/dashboards' component={AuthMiddleware(Dashboards)} />
          <Route history={history} exact path='/notifications' component={AuthMiddleware(UserNotification)} />
          <Route history={history} exact path='/messages' component={AuthMiddleware(Message)} />
          <Route history={history} exact path='/reviews' component={AuthMiddleware(isPrivateUser ? UserReview : Review)} />
          <Route history={history} exact path='/calendar' component={AuthMiddleware(BookingCalendarView)} />
          <Route history={history} exact path='/change-password' component={AuthMiddleware(ChangePassword)} />
          {[langs.modules.retail, 'user', langs.modules.classified].includes(getMainCategory(seller_type)) &&
            <Route history={history} exact path='/ad-management' component={AuthMiddleware(isPrivateUser ? MyAds : MyAds)} />
          }
          <Route history={history} exact path='/payment-methods' component={PaymentMethodsUser} />
          <Route history={history} exact path='/billing-history' component={isPrivateUser ? UserBillingHistory : BillingHistory} />
          <Route history={history} exact path='/subscription' component={Subscription} />
          {!isPrivateUser &&
            <Route history={history} exact path='/transaction' component={AuthMiddleware(VendorTransaction)} />
          }
          {!isLoggedIn && <Route history={history} exact path='/reset-password/:token' component={WelComePage} />}
          <Route history={history} path='/page-not-found' component={PageNotFound} />
          {seller_type === langs.sellerType.job && <Route history={history} exact path='/job-applications' component={AuthMiddleware(JobApplicationList)} />}
          {/* </>)} */}
          {(!isLoggedIn || isPrivateUser) && (
            <>
              <Route history={history} exact path='/' component={WelComePage} />
              <Route history={history} exact path='/product-details/retail/:slug' component={ProductDetailsPage} />
              <Route history={history} exact path='/business-detail/:sellerType/:slug' component={BusinessDetailPage} />
              <Route history={history} exact path='/restaurant-detail/:slug' component={RestaurantDetailPage} />
              <Route history={history} exact path='/retail-listing/:categorySlug' component={RetailListing} />
              <Route history={history} exact path='/booking-listing/:categorySlug' component={BookingListing} />
              <Route history={history} exact path='/ads-by-business/:module/:slug' component={BusinessAdsListing} />
              <Route history={history} exact path='/classified-listing/:categorySlug' component={ClassifiedListing} />
              <Route history={history} exact path='/retail/:categoryName/:categoryId' component={RetailCatLandingPage} />
              <Route history={history} exact path='/test-checkout' component={TestCard} />

              <Route history={history} exact path='/orders/detail' component={OrderDetail} />
              <Route history={history} exact path='/orders-retail' component={AuthMiddleware(RetailOrders)} />
              <Route history={history} exact path='/orders-restaurant-PastOrder-detail' component={RestaurantPastOrderDetail} />
              <Route history={history} exact path='/orders/invoice' component={OrderDetailsInvoice} />

              <Route history={history} exact path='/my-booking/event' component={MyEventBooking} />
              <Route history={history} exact path='/event-vendor-caterer/event-vendor' component={EventVendorCaterer} />
              <Route history={history} exact path='/profile-vendor-handyman/vendor-handyman' component={ProfileVendorHandyman} />
              <Route history={history} exact path='/profile-vendor-beauty/vendor-beauty' component={ProfileVendorBeauty} />
              <Route history={history} exact path='/profile-vendor-restaurant/vendor-restaurant' component={ProfileVendorRestaurant} />
              <Route history={history} exact path='/profile-vendor-fitness/fitness-vendor' component={ProfileVendorFitness} />

              <Route history={history} exact path='/classifieds' component={ClassifiedsLandingPage} />
              <Route history={history} exact path='/classifieds/:tab_type/:filter' component={ClassifiedsLandingPage} />
              <Route history={history} exact path='/bookings' component={BookingLandingPage} />
              <Route history={history} exact path='/bookings/:filter' component={BookingLandingPage} />

              <Route history={history} exact path='/editProfile' component={AuthMiddleware(EditProfile)} />

              <Route history={history} exact path='/edit-profile-payment-methods/:id' component={ProfileEditPaymentMethods} />

              <Route history={history} exact path='/add-profile-payment-methods' component={ProfileAddPaymentMethods} />


              <Route history={history} exact path='/post-ad' component={seller_type === langs.sellerType.retail ? RetailPostAd : PostAd} />
              <Route history={history} exact path='/classifieds/filter/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={CommonFilter} />
              <Route history={history} exact path='/classifieds/filter/:all/:categoryName/:categoryId' component={CommonFilter} />
              <Route history={history} exact path='/see-more/popular-view/:templateName/:categoryName/:categoryId' component={PopularViewSeeAll} />

              {/* 17/02/2021 see all changes  */}
              <Route history={history} exact path='/see-more/popular-view/:display_type/:templateName/:categoryName/:categoryId' component={PopularViewSeeAll} />

              <Route history={history} exact path='/classifieds/see-more/:filter/:classified_id' component={SeeMore} />
              <Route history={history} exact path='/classifieds/see-more/:filter' component={SeeMore} />

              {/* 17/02/2021 see all changes  */}
              <Route history={history} exact path='/classifieds/see-more/:filter/:display_type/:classified_id' component={SeeMore} />

              <Route history={history} exact path={`/classifieds/map-view/:templateName/:categoryName/:categoryId/:subCategoryName/:subCategoryId`} component={MapView} />
              <Route history={history} exact path={`/classifieds/:all/map-view/:templateName/:categoryName/:categoryId/`} component={MapView} />
              <Route history={history} exact path={`/classifieds/map-view/:templateName/:categoryName/:categoryId/:subCategoryName/:subCategoryId/:classifiedId`} component={MapViewDetail} />

              {/* Booking Module routes */}
              <Route history={history} exact path='/bookings' component={BookingLandingPage} />
              <Route history={history} exact path='/bookings/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={HandyManSubCategoryPage} />
              <Route history={history} exact path='/bookings-detail/:categoryName/:categoryId/:itemId' component={HandyDetailPage} />
              <Route history={history} exact path='/bookings-detail/:filter/:categoryName/:categoryId/:subCategoryName/:subCategoryId/:itemId' component={HandyDetailPage} />
              <Route history={history} exact path='/bookings-restaurant-detail/:categoryName/:categoryId/:itemId' component={RestaurantDetailPage} />
              <Route history={history} exact path='/bookings-restaurant-reviews/:categoryName/:categoryId/:itemId' component={RestaurantReviewsPage} />
              <Route history={history} exact path='/bookings-sports-detail/:categoryName/:categoryId/:itemId' component={SportsDetailPage} />
              <Route history={history} exact path='/bookings-detail/:categoryName/:categoryId/:subCategoryName/:subCategoryId/:itemId' component={HandyDetailPage} />
              <Route history={history} exact path='/bookings/:all/:categoryName/:categoryId' component={HandyManSubCategoryPage} />
              {/* Booking Tourism Module routs */}
              <Route history={history} exact path='/booking-tourism' component={TourismLandingPage} />
              <Route history={history} exact path='/bookings-flight-tourism/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={FlightLandingPage} />
              <Route history={history} exact path='/bookings-car-tourism/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={CarLandingPage} />
              <Route history={history} exact path='/bookings-hotel-tourism/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={HotelLandingPage} />
              <Route history={history} exact path='/booking-tourism-flight-listpage/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={FlightList} />
              <Route history={history} exact path='/booking-tourism-flight-listpage/:categoryName/:categoryId' component={FlightList} />

              <Route history={history} exact path='/booking-tourism-car-carlist/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={CarList} />
              <Route history={history} exact path='/booking-tourism-car-map-view/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={CarMapView} />
              <Route history={history} exact path='/booking-tourism-car-map-view2/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={CarMapView2} />

              <Route history={history} exact path='/booking-tourism-car-carlist/:categoryName/:categoryId' component={CarList} />
              {/* Start: Tourism-Flight-checkout-Route */}

              <Route history={history} exact path='/booking-tourism-flight/:filter/:counter' component={FlightBookings} />
              <Route history={history} exact path='/booking-tourism-see-all/:filter/:categoryId' component={TourismSeeAll} />

              <Route history={history} exact path='/booking-tourism-car-detail/:id' component={CarDetails} />
              <Route history={history} exact path='/booking-tourism-car-booking-form' component={CarBookingForm} />
              <Route history={history} exact path='/booking-tourism-car-checkout' component={CarCheckout} />
              {/* End: Tourism-Car-checkout-Route */}
              {/* Start: Tourism-hotel-checkout-Route */}
              <Route history={history} exact path='/booking-tourism-hotel' component={HotelLandingPage} />
              <Route history={history} exact path='/booking-tourism-hotel-detail/:code' component={HotelDetails} />
              <Route history={history} exact path='/booking-tourism-hotel-booking-form' component={HotelBookingForm} />
              <Route history={history} exact path='/booking-tourism-hotel-checkout' component={HotelCheckout} />
              <Route history={history} exact path='/booking-tourism-hotel-list/:categoryName/:categoryId' component={HotelSearchList} />
              <Route history={history} exact path='/booking-tourism-hotel-map-view/:categoryName/:categoryId' component={HotelMapView} />
              <Route history={history} exact path='/booking-tourism-hotel-map-view2/:categoryName/:categoryId' component={HotelMapView2} />
              {/* End: Tourism-hotel-checkout-Route */}

              {/* Nik */}

              {/* Booking Module see more routes */}

              <Route history={history} exact path='/bookings-see-more/:filter/:categoryName/:categoryId' component={HandyManSeeMorePage} />
              <Route history={history} exact path='/bookings-see-more/:filter/:categoryName/:categoryId' component={BookingSubcategorySeeMorePage} />
              <Route history={history} exact path='/bookings-see-more/:filter/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={BookingSubcategorySeeMorePage} />
              <Route history={history} exact path='/bookings-popular-see-more/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={PopularEventSeeMorePage} />
              <Route history={history} exact path='/bookings-popular-see-more/:categoryName/:filter/:categoryId' component={PopularEventSeeMorePage} />
              <Route history={history} exact path='/bookings-popular-see-more/:categoryName/:categoryId' component={PopularEventSeeMorePage} />
              <Route history={history} exact path='/bookings-popular-sports-see-more/:categoryId' component={PopularSportsSeeAll} />
              <Route history={history} exact path='/booking-ticket-list/:id' component={TicketListing} />
              <Route history={history} exact path='/ticket-booking' component={AuthMiddleware(TicketBooking)} />
              <Route history={history} exact path='/booking-ticket-detail/:tournamentId' component={TicketDetails} />
              <Route history={history} exact path='/booking-ticket-detail-form/53' component={TicketDetailForm} />
              <Route history={history} exact path='/bookings-see-all/:filter/:categoryId' component={PromoSeeAll} />

              <Route history={history} exact path={`/bookings-map-view/:categoryName/:categoryId/:subCategoryName/:subCategoryId`} component={BookingMapView} />
              <Route history={history} exact path={`/booking/map-view/:categoryName/:categoryId/:subCategoryName/:subCategoryId/:itemId`} component={BookingMapDetailView} />
              <Route history={history} exact path={`/bookings-map-view/:categoryName/:categoryId`} component={BookingMapView} />
              <Route history={history} exact path={`/bookings-map-view/:all/:categoryName/:categoryId/`} component={BookingMapView} />
              <Route history={history} exact path={`/restaurant-map-view/:categoryName/:categoryId`} component={RestaurantMapView} />

              <Route history={history} exact path='/bookings/detail/:categoryName/:categoryId' component={HandyDetailPage} />

              <Route history={history} exact path='/bookings-professional-services/:categoryId' component={PServicesLandingPage} />
              <Route history={history} exact path='/bookings-handyman/:categoryId' component={HandyManLandingPage} />
              <Route history={history} exact path='/bookings-beauty/:categoryId' component={BeautyLandingPage} />
              <Route history={history} exact path='/bookings-events/:categoryId' component={EventsLandingPage} />
              <Route history={history} exact path='/bookings-professional/:categoryId' component={HandyManLandingPage} />
              <Route history={history} exact path='/bookings-sports-tickets/:categoryId' component={SportsLandingPage} />
              <Route history={history} exact path='/bookings-wellbeing/:categoryId' component={WellBeingLandingPage} />
              <Route history={history} exact path='/bookings-restaurant/:categoryId' component={RestaurentLandingPage} />
              <Route history={history} exact path='/bookings-tourism/:categoryId' component={TourismLandingPage} />
              <Route history={history} exact path='/most-booked-hotels' component={MostBooked} />
              <Route history={history} exact path='/bookings-category/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={BeautySubCategoryPage} />
              <Route history={history} exact path='/bookings-search/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={BeautySearchPage} />
              <Route history={history} exact path='/bookings-search/:categoryName/:categoryId' component={RestaurantSearchPage} />
              <Route history={history} exact path='/bookings-sports-search/:categoryId' component={SportsSearchPage} />
              <Route history={history} exact path='/class-schedule/:classSlug' component={ClassSchedule} />
              <Route history={history} exact path='/view-classes/:classSlug' component={ClassList} />
              <Route history={history} exact path='/memberships/:classSlug' component={FitnessMemberships} />

              {/* <Route history={history} exact path='/view-schedule/:categoryName/:categoryId/:subCategoryName/:subCategoryId/:itemId/:id' component={Schedule} />
              <Route history={history} exact path='/view-classes/:categoryName/:categoryId/:subCategoryName/:subCategoryId/:itemId/:id' component={Classes} /> */}
              <Route history={history} exact path='/memberships/:categoryName/:categoryId/:subCategoryName/:subCategoryId/:itemId/:id' component={MembershipList} />
              <Route history={history} exact path='/payment-complete' component={RestaurantPaymentComplete} />

              {/* Classified Module routes */}
              {/* automative routes */}
              <Route history={history} exact path={`/classifieds-general/:categoryName/:categoryId`} component={SimpleLandingPage} />
              <Route history={history} exact path={`/classifieds-general/detail-page/:categoryId/:classified_id`} component={ClassifiedDetailsPage} />
              <Route history={history} exact path={`/classifieds-jobs/detail-page/:categoryId/:classified_id`} component={ClassifiedJobsDetailsPage} />
              <Route history={history} exact path={`/classifieds-realestate/detail-page/:categoryId/:classified_id`} component={ClassifiedRealEstateDetailsPage} />
              {/* <Route history={history} exact path={`/classifieds-general/detail-page/:categoryId/:classified_id`} component={Template1DetailPage} /> */}
              <Route history={history} exact path='/classifieds/all/general/:all-sub-categories/:categoryName/:categoryId' component={SimpleSubCategory} />
              <Route history={history} exact path='/classifieds-general/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={SimpleSubCategory} />

              {/* real state routes */}
              <Route history={history} exact path={`/classifieds-realestate/:categoryName/:categoryId`} component={SimpleLandingPage} />
              <Route history={history} exact path='/classifieds/all/realestate/:all-sub-categories/:categoryName/:categoryId' component={SimpleSubCategory} />
              <Route history={history} exact path='/classifieds-realestate/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={SimpleSubCategory} />

              {/* Job routes routes */}
              <Route history={history} exact path={`/classifieds-jobs/:categoryName/:categoryId`} component={SimpleLandingPage} />
              {/* <Route history={history} exact path={`/classifieds-jobs/detail-page/:categoryId/:classified_id`} component={Template1DetailPage} /> */}

              {/* old job listing page routes */}
              {/* <Route history={history} exact path='/classifieds-jobs/all/:all-sub-categories/:categoryName/:categoryId' component={JobSubCategoryList} />
              <Route history={history} exact path='/classifieds-jobs/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={JobSubCategoryList} /> */}

              {/* new design listing page routes */}
              <Route history={history} exact path='/classifieds-jobs/all/:all-sub-categories/:categoryName/:categoryId' component={SimpleSubCategory} />
              <Route history={history} exact path='/classifieds-jobs/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={SimpleSubCategory} />

              <Route history={history} exact path='/classified-jobs/see-more/:filter/:classified_id' component={JobsSeeMore} />
              <Route history={history} exact path='/classified-jobs/see-more/:filter' component={JobsSeeMore} />
              <Route history={history} exact path='/classifieds-jobs/resume-builder' component={ResumeBuilder} />
              {/* <Route history={history} exact path='/classified-jobs/resume' component={Resume} /> */}

              {/* M4 retail module routes */}
              <Route history={history} exact path='/retail' component={RetailLandingPage} />
              <Route history={history} exact path='/retail/:filter' component={RetailLandingPage} />
              <Route history={history} exact path='/retail/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={RetailSubCategoryPage} />
              <Route history={history} exact path='/retail/:categoryName/:categoryId/:subCategoryName/:subCategoryId/:sub_sub_CategoryName/:sub_sub_CategoryId' component={RetailSubCategoryPage} />


              <Route history={history} exact path='/retail-detail/see-more/:filter' component={RetailSeeMore} />
              <Route history={history} exact path='/retail-detail/see-more/:filter/:categoryName/:categoryId' component={RetailSeeMore} />

              {/* 17/02/2021 see all page changes */}
              <Route history={history} exact path='/retail-detail/see-more/:filter/:display_type' component={RetailSeeMore} />
              <Route history={history} exact path='/retail-detail/see-more/:filter/:display_type/:categoryName/:categoryId' component={RetailSeeMore} />

              <Route history={history} exact path='/retail/filter/:categoryName/:categoryId/:subCategoryName/:subCategoryId' component={RetailFilterPage} />
              <Route history={history} exact path='/retail-category/filter/:all/:categoryName/:categoryId' component={RetailFilterPage} />
              <Route history={history} exact path='/user-ads-retail/:filter/:categoryId/:item_id' component={FoundUserAds} />
              <Route history={history} exact path='/user-ads/general/:categoryId/:item_id' component={FoundUserAds} />
              <Route history={history} exact path='/user-ads/realstate/:categoryId/:item_id' component={FoundUserAds} />
              <Route history={history} exact path={`/retail-classifieds/map-view/:templateName/:categoryName/:categoryId/:subCategoryName/:subCategoryId`} component={MapView} />
              <Route history={history} exact path={`/retail-classifieds/:all/map-view/:templateName/:categoryName/:categoryId/`} component={MapView} />
              <Route history={history} exact path={`/retail-classifieds/map-view/:templateName/:categoryName/:categoryId/:subCategoryName/:subCategoryId/:classifiedId`} component={MapViewDetail} />
              <Route history={history} exact path={`/retail-checkout`} component={RetailCheckoutProcess} />
              <Route history={history} path='/un-authorize' component={UnAuthorized} />

              {/* Food Scanner Routes */}
              <Route history={history} exact path='/food-scanner' component={FoodScannerLandingPage} />
              <Route history={history} exact path='/food-product-detail/:id' component={FoodProductDetailPage} />
              <Route history={history} exact path='/add-product' component={AddProduct} />
              <Route history={history} exact path='/see-all-products/:listType' component={SeeAllProducts} />
              <Route history={history} exact path='/compare-product/:productIds' component={CompareFoodProduct} />

              {/*Static Pages URL*/}
              <Route history={history} exact path='/faq' component={FAQPage} />
              <Route history={history} exact path='/footer-pages/contact-us' component={ContactPages} />
              <Route history={history} exact path='/footer-pages/:slug' component={StaticPages} />
              <Route history={history} exact path='/mobile-app/screen' component={MobileAppScreen} />

              {/* Retail Checkout Page UI */}
              {/* <Route history={history} exact path='/retail-checkout' component={RetailCheckout} /> */}
              <Route history={history} exact path='/payment-complete-orders' component={RetailPaymentComplete} />

              {/* Retail Checkout Order Summary Page UI */}
              <Route history={history} exact path='/checkout-order-summary' component={CheckoutOrderSummary} />

              {/* Retail Payment Complete Page UI */}
              <Route history={history} exact path='/payment-complete' component={PaymentComplete} />

              {isLoggedIn && isPrivateUser && (
                <>
                  {/* <Route history={history} exact path='/orders-retail' component={AuthMiddleware(UserRetailDashboard)} /> */}
                  {!menuSkiped && <Route history={history} exact path='/intermediate' component={InterMediateStep} />}
                  {/* <Route history={history} exact path='/post-ad' component={seller_type === langs.sellerType.retail ? RetailPostAd:PostAd} /> */}
                  <Route history={history} exact path='/booking-checkout' component={BookingCheckout} />
                  <Route history={history} exact path='/restaurent-checkout' component={RestaurentCheckout} />
                  {/* route for handyman checkout success */}
                  <Route history={history} exact path='/job_checkout_success' component={HandymanBookingCheckoutSuccess} />
                  {/* route for handyman checkout success */}

                  <Route history={history} exact path='/booking-list' component={AuthMiddleware(CustomerMyBookings)} />
                  <Route history={history} exact path='/my-bookings/event-booking-details/:id' component={AuthMiddleware(MyEventBookingDetails)} />

                  {/* handyman Booking Route */}
                  <Route history={history} exact path='/my-bookings/handyman-booking-details/:id' component={AuthMiddleware(handymanBookingDetails)} />
                  {/* handyman Booking Route */}

                  <Route history={history} exact path='/my-booking/spa' component={AuthMiddleware(MyBookings)} />
                  <Route history={history} exact path='/spa/customer-booking-detail/:serviceBookingId' component={AuthMiddleware(CustomerSpaBookingDetails)} />
                  <Route history={history} exact path='/spa/customer-booking-history-detail/:serviceBookingId' component={AuthMiddleware(CustomerSpaBookingHistoryDetails)} />
                  <Route history={history} exact path='/orders-restaurant' component={AuthMiddleware(MyRestaurantOrders)} />
                  <Route history={history} exact path='/track-restaurant-order/:orderId' component={AuthMiddleware(TrackUserRestaurantOrders)} />
                  <Route history={history} exact path='/my-booking/beauty' component={AuthMiddleware(CustomerMyBookingsBeauty)} />

                  <Route history={history} exact path='/dashboard-classified' component={AuthMiddleware(UserClassifiedsDashboard)} />
                  <Route history={history} exact path='/cart' component={AuthMiddleware(UserRetailCartDetail)} />
                  <Route history={history} exact path='/restaurant-cart' component={AuthMiddleware(RestaurantCartDetail)} />
                  <Route history={history} exact path='/beauty/customer-booking-detail/:serviceBookingId' component={AuthMiddleware(CustomerBeautyBookingDetails)} />
                  <Route history={history} exact path='/beauty/customer-booking-history-detail/:serviceBookingId' component={AuthMiddleware(CustomerBeautyBookingHistoryDetails)} />
                  <Route history={history} exact path='/fitness-my-booking-detail/:classBookingId' component={AuthMiddleware(CustomerFitnessBookingDetails)} />
                  <Route history={history} exact path='/fitness/customer-booking-history-detail/:serviceBookingId' component={AuthMiddleware(CustomerFitnessBookingHistoryDetails)} />
                  <Route history={history} exact path='/dashboard' component={AuthMiddleware(UserDashboard)} />

                  <Route history={history} exact path='/wishlist' component={AuthMiddleware(UserWishlist)} />
                  <Route history={history} exact path='/boostAd-pay/:id/:price' component={BoostAdPayment} />
                  <Route history={history} exact path='/update-ad/:adId' component={UpdateAd} />
                  <Route history={history} exact path='/retail-review/:itemId' component={AuthMiddleware(RetailProductReview)} />
                  <Route history={history} exact path='/edit-payment-methods/:id' component={EditPaymentMethodsUser} />
                  <Route history={history} exact path='/add-payment-methods' component={AddpaymentMethodsUser} />
                  <Route history={history} exact path='/billing-invoice/:id' component={UserBillingInvoice} />
                </>
              )}
            </>
          )}


          {isLoggedIn && !isPrivateUser && (
            <>
              {loggedInUser.profile_completed == 1 ? (
                <Route history={history} exact path='/' component={() => {
                  if (seller_type === langs.sellerType.handyman || seller_type === langs.sellerType.professional) {
                    return <HandyManDashboard history={history} />;
                  } else if (seller_type === langs.sellerType.retail) {
                    return <VendorRetailDashboard history={history} />;
                  } else if (seller_type === langs.sellerType.restaurant) {
                    //Restaurant dashboard
                    return <RestaurantVendorDashboard history={history} />;
                  } else if (isFitnessBusiness) {
                    return <FitnessVendorDashboard history={history} />;
                  } else if (seller_type === langs.sellerType.events) {
                    return <EventVendorDashboard history={history} />;
                  } else if (seller_type !== langs.sellerType.retail) {
                    return <HandyManDashboard history={history} />;
                  } else {
                    return <Dashboard history={history} />;
                  }
                }}
                />
              ) : (
                <>
                  <Route history={history} exact path='/' component={ProfileSetup} />
                </>
              )}
              <Route history={history} exact path='/myProfile' component={AuthMiddleware(MyProfile)} />
              <Route history={history} exact path='/received-orders' component={AuthMiddleware(ReceivedOrder)} />
              <Route history={history} exact path='/received-orders-detail' component={AuthMiddleware(ReceivedOrderDetail)} />
              <Route history={history} exact path='/vendor-retail-invoice' component={AuthMiddleware(VendorRetailInvoice)} />
              {seller_type === langs.sellerType.restaurant && <Route history={history} exact path='/order-tracking' component={AuthMiddleware(OrderTracking)} />}
              {[langs.sellerType.handyman,
              langs.sellerType.professional,
              langs.sellerType.events,
              langs.sellerType.beauty,
              langs.sellerType.wellbeing].includes(seller_type) && <Route history={history} exact path='/business-bookings' component={AuthMiddleware(HandymanBookings)} />}
              {seller_type === langs.sellerType.restaurant && <Route history={history} exact path='/restaurant-orders' component={AuthMiddleware(RestaurantVendorOrderList)} />}

              {/* TODO:: Need to implement the route */}
              {loggedInUser && seller_type !== langs.sellerType.retail ?
                <Route history={history} exact path='/dashboard'
                  component={() => {
                    return <RestaurantVendorDashboard history={history} />
                  }}
                /> : <Route history={history} exact path='/dashboard'
                  component={() => {
                    if (seller_type === langs.sellerType.retail) {
                      return <VendorRetailDashboard history={history} />
                    } else if (seller_type !== langs.sellerType.retail) {
                      return <BookingDashboard history={history} />
                    } else {
                      return <Dashboard history={history} />
                    }
                  }}
                />
              }

              <Route history={history} exact path='/retail-review/:itemId' component={AuthMiddleware(RetailProductReview)} />
              <Route history={history} exact path='/post-ad' component={seller_type === langs.sellerType.retail ? RetailPostAd : PostAd} />
              <Route history={history} exact path='/update-ad/:adId' component={seller_type === langs.sellerType.retail ? UpdateRetailAd : UpdateAd} />
              <Route history={history} exact path='/repost-ad/:filter/:adId' component={seller_type === langs.sellerType.retail ? UpdateRetailAd : UpdateAd} />
              <Route history={history} exact path='/billing-invoice/:id' component={BillingInvoice} />
              <Route history={history} exact path='/payment/:id/:price' component={Payment} />
              <Route history={history} exact path='/edit-payment-methods/:id' component={EditPaymentMethods} />
              <Route history={history} exact path='/add-payment-methods' component={AddPaymentMethods} />
              <Route history={history} exact path='/editProfile' component={AuthMiddleware(VendorEditProfile)} />

              {seller_type === langs.sellerType.real_estate && <Route history={history} exact path='/inspection' component={AuthMiddleware(InspectionList)} />}
              {seller_type === langs.sellerType.real_estate && <Route history={history} exact path='/inspection-detail/:id' component={AuthMiddleware(InspectionDetails)} />}
              {seller_type === langs.sellerType.job && <Route history={history} exact path='/application-detail/:id' component={AuthMiddleware(ApplicationDetails)} />}
              {seller_type === langs.sellerType.car_dealer && <Route history={history} exact path='/application-detail/:id' component={AuthMiddleware(GeneralVendorMyOfferDetail)} />}
              <Route history={history} exact path='/restaurant-menu' component={AuthMiddleware(AddRestaurantMenu)} />
              <Route history={history} exact path='/edit-menu' component={AuthMiddleware(UpdateRestaurantMenu)} />
              <Route history={history} exact path='/add-menu' component={AuthMiddleware(AddRestaurantMenu)} />
              <Route history={history} exact path='/business-profile' component={AuthMiddleware(MyProfile)} />
              <Route history={history} exact path='/edit-business-Profile' component={AuthMiddleware(EditProfile)} />

              {isFitnessBusiness && <Route history={history} exact path='/fitness-my-bookings' component={AuthMiddleware(FitnessVenderMyBookings)} />}
              {isFitnessBusiness && <Route history={history} exact path='/classes-and-membership' component={AuthMiddleware(FitnessMyProfileSetup)} />}
              {isFitnessBusiness && <Route history={history} exact path='/fitness-vendor-edit-classes/:id/:classId' component={AuthMiddleware(FitnessEditClass)} />}
              {isFitnessBusiness && <Route history={history} exact path='/fitness-vendor-edit-membership/:packageId' component={AuthMiddleware(FitnessEditClass)} />}
              {isFitnessBusiness && <Route history={history} exact path='/fitness-vendor-mycalendar' component={AuthMiddleware(FitnessVenderMyCalendar)} />}

              {seller_type === langs.sellerType.restaurant && <Route history={history} exact path='/special-offers' component={AuthMiddleware(SpecialOffers)} />}
              {seller_type === langs.sellerType.restaurant && <Route history={history} exact path='/create-special-offers' component={AuthMiddleware(CreateSpecialOffers)} />}
              {showDeals && <Route history={history} exact path='/daily-deals' component={AuthMiddleware(DailyDeals)} />}
              {showDeals && <Route history={history} exact path='/create-daily-deals' component={AuthMiddleware(CreateDailyDeals)} />}
              {showDeals && <Route history={history} exact path='/promotions' component={AuthMiddleware(Promotions)} />}
              {showDeals && <Route history={history} exact path='/create-promotions' component={AuthMiddleware(CreatePromotions)} />}
              {showPackages && <Route history={history} exact path='/packages' component={AuthMiddleware(Packages)} />}
              {showPackages && <Route history={history} exact path='/create-packages' component={AuthMiddleware(CreatePackages)} />}

              {seller_type === langs.sellerType.car_dealer && <Route history={history} exact path='/my-offer' component={AuthMiddleware(GeneralVendorMyOffer)} />}
              {seller_type === langs.sellerType.car_dealer && <Route history={history} exact path='/my-offer-details/:id' component={AuthMiddleware(GeneralVendorMyOfferDetail)} />}

              {seller_type === langs.sellerType.wellbeing && <Route history={history} exact path='/vendor-spa-bookings-detail/:serviceBookingId' component={AuthMiddleware(VendorSpaBookingDetails)} />}
              {seller_type === langs.sellerType.wellbeing && <Route history={history} exact path='/spa-my-bookings-calender' component={AuthMiddleware(VendorSpaMyBookingCalender)} />}
              {seller_type === langs.sellerType.wellbeing && <Route history={history} exact path='/vendor-spa-booking-history-detail/:serviceBookingId' component={AuthMiddleware(VendorSpaBookingHistoryDetails)} />}
              {seller_type === langs.sellerType.wellbeing && <Route history={history} exact path='/add-services' component={AuthMiddleware(AddServices)} />}

              {[langs.sellerType.wellbeing, langs.sellerType.beauty].includes(seller_type) && <Route history={history} exact path='/services' component={AuthMiddleware(Services)} />}
              {[langs.sellerType.wellbeing, langs.sellerType.beauty].includes(seller_type) && <Route history={history} exact path='/edit-spa-service/:id' component={AuthMiddleware(UpdateServices)} />}

              {seller_type === langs.sellerType.beauty && <Route history={history} exact path='/update-vendor-services/:activeTab/:id' component={AuthMiddleware(UpdateServive)} />}
              {seller_type === langs.sellerType.beauty && <Route history={history} exact path='/update-vendor-services' component={AuthMiddleware(UpdateBeautyService)} />}
              {seller_type === langs.sellerType.beauty && <Route history={history} exact path='/beauty-my-bookings-calender' component={AuthMiddleware(VendorBeautyMyBookingCalender)} />}
              {seller_type === langs.sellerType.beauty && <Route history={history} exact path='/vendor-beauty-bookings-detail/:serviceBookingId' component={AuthMiddleware(VendorBeautyBookingDetails)} />}
              {seller_type === langs.sellerType.beauty && <Route history={history} exact path='/vendor-beauty-booking-history-detail/:serviceBookingId' component={AuthMiddleware(VendorBeautyBookingHistoryDetails)} />}

              {seller_type === langs.sellerType.retail && <Route history={history} merchant={true} exact path='/payment' component={AuthMiddleware(RetailPaymentScreen)} />}
              {seller_type === langs.sellerType.retail && <Route history={history} exact path='/daily-deals' component={AuthMiddleware(RetailDailyDealsList)} />}
              {seller_type === langs.sellerType.retail && <Route history={history} exact path='/create-daily-deals' component={AuthMiddleware(RetailDailyDeals)} />}


              <Route history={history} path='/un-authorize' component={UnAuthorized} />
            </>
          )}
        </Switch>
      </Suspense>
    );
  }

  //routes
}

const mapStateToProps = (store) => {
  const { auth: { isLoggedIn, loggedInUser }, profile: { businessProfile } } = store;
  let seller_type = loggedInUser?.seller_type
  let isFitnessBusiness = seller_type === langs.sellerType.wellbeing ?
    businessProfile?.business_profile?.sub_category?.name === langs.bookingSubCategory.fitness : false
  return {
    isLoggedIn, loggedInUser, isFitnessBusiness, seller_type,
    isPrivateUser: isLoggedIn ? (loggedInUser?.seller_type === langs.sellerType.private) ? true : false : false,
    menuSkiped: isLoggedIn ? loggedInUser.menu_skipped === 1 : false,
  };
};
export default connect(mapStateToProps, null)(Routes);
