import axios from 'axios';
import { API } from '../../../../config/Config';
import { apiErrors } from '../../../../config/HandleAPIErrors';
import { SET_SELECTED_CAR_DATA, SET_CAR_SEARCH_DATA, SET_CAR_REQ_DATA } from '../../../constants'
import { toastr } from 'react-redux-toastr';
import { capitalizeFirstLetter } from '../../../../components/common'
import { ADD_CARS_VIEW_API, ADD_DRIVER_DETAILS_API, CARS_RECOMMENDED_FOR_YOU_API, CARS_SEARCH_API, CAR_BOOKING_API, CAR_BOOKING_PAYPAL_API, CAR_MARK_FAV_API, CHECK_CAR_AVAILABILITY_API, CHECK_IS_CAR_FAV_API, GET_CAR_POPULAR_DEST_API, GET_CAR_REVIEWS_API, GET_CAR_VIEWS_API, POST_CAR_REVIEW_API, TOP_RENTALCAR_API } from '../../../../api';

/**
 * @method getPopularDestinations
 * @descriptionhandle get flight which are most booked
 */
export function getPopularDestinations(callback) {
    const request = axios.post(`${GET_CAR_POPULAR_DEST_API}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method carSearchAction
 * @descriptionhandle car rental search api
 */
export function carSearchAction(data, callback) {
    const request = axios.get(`${CARS_SEARCH_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            let err = res.data && res.data.err ? res.data.err : ''
            if (err && Array.isArray(err) && err.length) {
                let msg = err[0].errorWarningDescription && err[0].errorWarningDescription && err[0].errorWarningDescription.freeText ? err[0].errorWarningDescription.freeText : ""
                if (msg) {
                    toastr.warning(capitalizeFirstLetter(msg))
                }
                dispatch({
                    type: SET_CAR_SEARCH_DATA,
                    payload: ''
                })
            } else {
                dispatch({
                    type: SET_CAR_SEARCH_DATA,
                    payload: res.data
                })
            }
            callback(res);
        }).catch(function (error) {
            dispatch({
                type: SET_CAR_SEARCH_DATA,
                payload: ''
            })
            apiErrors(error)
            callback(error);
        });
    }
}

/**
* @method setCarReqData
* @description set car request data
*/
export function setCarReqData(res) {
    return (dispatch) => {
        dispatch({
            type: SET_CAR_REQ_DATA,
            payload: res
        })
    }
}

/**
 * @method getTopRentalCompany
 * @descriptionhandle get top rental company
 */
export function getTopRentalCompany(callback) {
    const request = axios.get(`${TOP_RENTALCAR_API}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method checkIsCarFavOrNot
 * @descriptionhandle get top rental company
 */
export function checkIsCarFavOrNot(data, callback) {
    const request = axios.post(`${CHECK_IS_CAR_FAV_API}`, { car_id: data.id, user_id: data.user_id })
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}


/**
 * @method markCarAsFav
 * @descriptionhandle get top rental company
 */
export function markCarAsFav(reqData, callback) {
    const request = axios.post(`${CAR_MARK_FAV_API}`, reqData)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error.res);
        });
    }
}


/**
 * @method postCarReview
 * @descriptionhandle get top rental company
 */
export function postCarReview(reqData, callback) {
    const request = axios.post(`${POST_CAR_REVIEW_API}`, reqData)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error.res);
        });
    }
}

/**
 * @method getCarViewCount
 * @descriptionhandle get top rental company
 */
export function getCarViewCount(id, callback) {
    const request = axios.post(`${GET_CAR_VIEWS_API}`, { car_id: id })
    return (dispatch) => {
        request.then((res) => {

            callback(res);
        }).catch(function (error) {

            callback(error.res);
        });
    }
}

/**      
* @method setCarReqData
* @description set car request data
*/
export function setSelectedCarData(res) {
    return (dispatch) => {
        dispatch({
            type: SET_SELECTED_CAR_DATA,
            payload: res
        })
    }
}

/**
 * @method getCarAvailabilityRates
 * @descriptionhandle get car availability rates
 */
export function getCarAvailabilityRates(data, callback) {
    const request = axios.post(`${CHECK_CAR_AVAILABILITY_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method postCarDriverDetails
 * @descriptionhandle post car driver details
 */
export function postCarDriverDetails(data, callback) {
    const request = axios.post(`${ADD_DRIVER_DETAILS_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
            apiErrors(error)
        });
    }
}

/**
 * @method carRecommendedForYou
 * @descriptionhandle cars recommended for you
 */
export function carRecommendedForYou(data, callback) {
    const request = axios.post(`${CARS_RECOMMENDED_FOR_YOU_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
            apiErrors(error)
        });
    }
}


/**
 * @method addCarViews
 * @descriptionhandle cars recommended for you
 */
export function addCarViews(reqData, callback) {
    const request = axios.post(`${ADD_CARS_VIEW_API}`, reqData)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
            apiErrors(error)
        });
    }
}



/**
 * @method getCarRating
 * @descriptionhandle cars recommended for you
 */
export function getCarRating(reqData, callback) {
    const request = axios.post(`${GET_CAR_REVIEWS_API}`, reqData)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
            apiErrors(error)
        });
    }
}

/**
 * @method carBookingAction
 * @descriptionhandle cars recommended for you
 */
export function carBookingAction(reqData, callback) {
    const request = axios.post(`${CAR_BOOKING_API}`, reqData)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
            apiErrors(error)
        });
    }
}

/**
 * @method carBookingPaypalAction
 * @descriptionhandle cars recommended for you
 */
export function carBookingPaypalAction(reqData, callback) {
    const request = axios.post(`${CAR_BOOKING_PAYPAL_API}`, reqData)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
            apiErrors(error)
        });
    }
}