import axios from "../../api/axios";
import { apiErrors } from "../../config/HandleAPIErrors";
import { JOB_APPLICATIONS_API } from "../../api";

/**
 * @method getJobApplicationsAction
 * @description get job apllication list
 */
export function getJobApplicationsAction(data, callback) {
    const request = axios.get(`${JOB_APPLICATIONS_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            apiErrors(error)
            callback(error);
        });
    }
}