import { SIDEBAR_OPEN_CLOSE } from "../constants";

const INITIAL_STATE = {
    sideMenuOpenClose: false,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIDEBAR_OPEN_CLOSE:
            return {
                ...state,
                sideMenuOpenClose: action.data
            }
        default:
            return state;
    }
}