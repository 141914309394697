import axios from 'axios';
import { AVAILABLE_SLOTS_API, BOOKINGS_API, BUSINESS_AVAILABLE_SLOTS_API, CALENDAR_API, PREVIOUS_BOOKINGS_API, PREVIOUS_QUOTES_API, QUOTE_API, REQUEST_QUOTE_API, SERVICE_IMAGES_API } from '../../../api';
import { API } from '../../../config/Config';
import { apiErrors } from '../../../config/HandleAPIErrors';

/**
 * @method getPreviousQuoteAction
 * @description get previos quotes list
 */
export function getPreviousQuoteAction(data, callback) {
    const request = axios.get(`${PREVIOUS_QUOTES_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method requestQuoteAction
 * @description create request quote
 */
export function requestQuoteAction(data, callback) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    const request = axios.post(`${REQUEST_QUOTE_API}`, data, headers)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method deleteServiceImagesAction
 * @description create request quote
 */
export function deleteServiceImagesAction(data, callback) {
    const request = axios.delete(`${SERVICE_IMAGES_API}/${data}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method uploadServiceImagesAction
 * @description create request quote
 */
export function uploadServiceImagesAction(data, callback) {
    const request = axios.post(`${SERVICE_IMAGES_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getCalendarAction
 * @description get business month booking
 */
export function getCalendarAction(data, callback) {
    const request = axios.get(`${CALENDAR_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getAvailableSlotsAction
 * @description get business booking slots
 */
export function getAvailableSlotsAction(data, callback) {
    const request = axios.get(`${AVAILABLE_SLOTS_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getBusinessAvailableSlotsAction
 * @description get business booking slots
 */
export function getBusinessAvailableSlotsAction(data, callback) {
    const request = axios.get(`${BUSINESS_AVAILABLE_SLOTS_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getPreviousBookingsAction
 * @description get business previous quote
 */
export function getPreviousBookingsAction(callback) {
    const request = axios.get(`${PREVIOUS_BOOKINGS_API}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method createBookingAction
 * @description request for booking 
 */
export function createBookingAction(data, callback) {
    const request = axios.post(`${BOOKINGS_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}


/**
 * @method getTraderjobIdByQuoteId
 * @description request for booking 
 */
export function getTraderjobIdByQuoteId(data, callback) {
    const request = axios.post(`${API.getTraderjobIdByQuoteId}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {
            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method changeQuoteStatusAction
 * @description request for Quote Status 
 */
export function changeQuoteStatusAction({ quote_id, ...data }, callback) {
    const request = axios.post(`${QUOTE_API}/${quote_id}?_method=PUT`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {
            apiErrors(error)
            callback(error);
        });
    }
}
