import React, { Component, Fragment } from "react";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import axios from "axios";
import AppHeader from "./components/layout/header";
import AppFooter from "./components/layout/footer";
import CustomeRoutes from "./router/Routes";
import { Layout, Spin } from "antd";
import { langs } from "./config/localization";
import { getLocalStorage } from "./common/Methods";
import { getMainCategory, dashboardPaths } from './common/Navigation';
import { ConsoleSqlOutlined } from "@ant-design/icons";

const spinIcon = (
  <img
    src={require("./assets/images/loader1.gif")}
    alt=""
    style={{ width: "64px", height: "64px" }}
  />
);
// const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      insideDashboard: false
    };
  }

  componentDidUpdate(nextProps) {
    let pathName = window.location.pathname.split("/");
    pathName = '/' + pathName[1]
    if (dashboardPaths().includes(pathName) && !this.state.insideDashboard) {
      // Inside dashboard
      this.setState({ insideDashboard: true })

    } else {
      if (this.state.insideDashboard && !dashboardPaths().includes(pathName)) {
        this.setState({ insideDashboard: false })
      }
      // outside dashboard
    }
  }

  render() {
    const { loading, isLoggedIn, isPrivateUser, seller_type } = this.props;
    let authToken = getLocalStorage().authToken;
    axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    let loggedInAsPrivateuser = isLoggedIn && isPrivateUser;
    //let isDashBoardSelectedStyle = this.props.dashboardRoutes && this.props.dashboardRoutes.includes(this.props.location.pathname) ? 'user-link selected' : 'user-link'
    return (
      <BrowserRouter>
        <div className={`user_type_${seller_type ? seller_type : ""}`}>
          <Spin
            tip="Loading..."
            indicator={spinIcon}
            spinning={loading}
            // style={{ alignItems: "center", justifyContent: "center", zIndex: 9999, display: "flex", flexDirection: "column" }}
          >
            <Fragment>
              <Layout id="mainBG">
                <AppHeader />
                <Layout
                  className={`${loggedInAsPrivateuser
                    ? "profile-common-main-wrap main-wrap"
                    : "main-wrap profile-purple-main-wrap profile-purple-user-accont-v2"} ${this.props.main_category}`}
                >
                  <CustomeRoutes />

                  {isLoggedIn && this.state.insideDashboard ? null : <AppFooter />}

                </Layout>
              </Layout>
            </Fragment>
          </Spin>


          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
          />
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (store) => {
  // const { auth, common } = store;
  const { auth: { isLoggedIn, loggedInUser }, common: { loading } } = store
  return {
    seller_type: isLoggedIn && loggedInUser.seller_type,
    isLoggedIn, loading,
    authToken: isLoggedIn ? loggedInUser.token : "",
    main_category: getMainCategory(isLoggedIn ? loggedInUser.seller_type : ""),
    isPrivateUser: isLoggedIn ? loggedInUser.seller_type === langs.sellerType.private : false,
  };
};
export default connect(mapStateToProps, null)(App);
