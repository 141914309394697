import { put, takeLatest, call } from 'redux-saga/effects';
import {
    GET_RETAIL_LISTING,
} from "../constants";
import {
    PAGE_LISTING_API,
} from "../../api";
import axios from "../../api/axios"
import { handleErrorResponse } from '../actions';

function getRetailListingApi(params) {
    const { page_id, ...paramsRest } = params;
    let url = PAGE_LISTING_API + page_id;
    return axios.request({ method: "get", url: url, params: paramsRest });
}

function* fetchRetailListing(action) {
    let { payload, resolve, reject } = action;
    try {
        let res = yield call(getRetailListingApi, payload);
        resolve(res.data.data)
    } catch (e) {
        handleErrorResponse(e.res)
        if (reject) reject(e)
    }
}
export function* getRetailListingActionWatcher() {
    yield takeLatest(GET_RETAIL_LISTING, fetchRetailListing)
}