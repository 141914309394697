import { ASSETS_URL, BASE_URL } from "../api";
import { DEFAULT_IMAGE_CARD } from "../config/Config";
import { langs } from "../config/localization";

/**
 * @method ProfileNavigation
 * @description get clean values
 */
export const profileNavigation = (seller_type) => {
    let stepProgress;
    switch (seller_type) {
        case langs.sellerType.private:
            stepProgress = ["Profile Information", "Payment Information"];
            break;
        case langs.sellerType.retail:
            stepProgress = ["Profile Information", "Postage Details", "Payment Information"];
            break;
        case langs.sellerType.job:
            stepProgress = ["Profile Information", "Payment Information"];
            break;
        case langs.sellerType.real_estate:
            stepProgress = ["Profile Information", "Payment Information"];
            break;
        case langs.sellerType.car_dealer:
            stepProgress = ["Profile Information", "Payment Information"];
            break;
        case langs.sellerType.events:
            stepProgress = ["Profile Information", "Business Information", "Upload Documents", "Payment Information",];
            break;
        case langs.sellerType.wellbeing:
            stepProgress = ["Profile Information", "Business Information", "Create Services", "Upload Documents", "Payment Information",];
            break;
        case langs.sellerType.beauty:
            stepProgress = ["Profile Information", "Business Information", "Create Services", "Upload Documents", "Payment Information",];
            break;
        case langs.bookingSubCategory.fitness:
            stepProgress = ["Profile Information", "Business Information", "Upload Gallery", "Create Classes", "Membership Plans", "Payment Information",];
            break;
        case langs.sellerType.restaurant:
            stepProgress = ["Profile Information", "Business Information", "Create Menu", "Payment Information",];
            break;
        default:
            stepProgress = ["Profile Information", "Business Information", "Upload Documents", "Payment Information"];
    }
    return stepProgress;
}

export const dashboardPaths = () => {
    let paths = ['/profile', '/profile-setup', '/dashboards', '/notifications', '/messages',
        '/reviews', '/calendar', '/calendar', '/wishlist',
        '/cart', '/restaurant-cart', '/payment-methods',
        '/orders-retail', '/orders-restaurant', '/restaurant-orders', '/booking-list',
        '/order-tracking','/restaurant-menu','/track-restaurant-order',
        '/billing-history', '/subscription', '/received-orders', '/transaction',
        '/booking-list', '/my-orders', '/job-applications', '/application-detail',
        '/payment', '/inspection', '/inspection-detail',
        '/update-ad', '/post-ad', '/ad-management',
        '/services', '/business-bookings', '/classes-and-membership',
        '/promotions', '/create-promotions',
        '/packages', '/create-packages',
        '/special-offers', '/create-special-offers',
        '/daily-deals', '/create-daily-deals',
    ];
    return paths;
}

export const getMainCategory = (seller_type) => {
    if (seller_type === langs.sellerType.retail) {
        return langs.modules.retail;
    } else if ([langs.sellerType.handyman, langs.sellerType.professional, langs.sellerType.wellbeing, langs.sellerType.events, langs.sellerType.restaurant, langs.sellerType.beauty].includes(seller_type)) {
        return langs.modules.booking;
    } else if ([langs.sellerType.job, langs.sellerType.car_dealer, langs.sellerType.real_estate].includes(seller_type)) {
        return langs.modules.classified;
    } else if (seller_type === langs.sellerType.private) {
        return 'user';
    } else {
        return 'user';
    }
}

export const replaceImageURL = (imageURL, width = 134, height = 134) => {
    return imageURL ? ASSETS_URL + btoa(imageURL.replace(`${BASE_URL}`, "")) + `/${width}/${height}`
        : DEFAULT_IMAGE_CARD

}