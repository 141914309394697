import axios from 'axios';
import { API } from '../../../config/Config';
import { apiErrors } from '../../../config/HandleAPIErrors';
import { SET_RETAIL_SUB_CATEGORIES, SET_GST_PERCENTAGE, UPDATE_CART_COUNT, UPDATE_WISHLIST_COUNT } from './../../constants';

import { CART_API, DASHBOARD_API, RETAIL_INVENTORY_ATTRIBUTES_API, INVENTORY_IMAGES_API, ORDER_API, RETAIL_BRANDS_API, RETAIL_ADS_API, SELLER_ORDER_API, SETTING_PARAMETER_API, WISHLIST_API, AUTOCOMPLETE_API, ADS_BY_BUSINESS_API, FEDEX_STATUS_API } from '../../../api';


/**
 * @method getAutocompleteAction
 * @description get retail category auto complete data
 */
export function getAutocompleteAction(data, callback) {
    const { module, ...restData } = data
    const request = axios.get(`${AUTOCOMPLETE_API}${module}`, { params: restData })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            callback(error);
        });
    }
}



/**
 * @method getAdsByBusinessAction
 * @description get retail  category detail
 */
export function getAdsByBusinessAction({ module, business_id, ...params }, callback) {
    const request = axios.get(`${ADS_BY_BUSINESS_API}${module}/${business_id}`, { params: params })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getRetailCategoryDetail
 * @description get retail  category detail
 */
export function getRetailCategoryDetail(data, callback) {
    const request = axios.post(`${API.getRetailDetail}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
            dispatch({
                type: SET_GST_PERCENTAGE,
                payload: res.data
            })
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getAllRetailSearchByName
 * @description get retail category item by name
 */
export function getAllRetailSearchByName(data, callback) {
    const request = axios.post(`${API.getRetailSearchByname}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            // apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method addToCartAPI
 * @description add to cart
 */
export function addToCartAPI(data, callback) {
    const request = axios.post(`${API.addToCart}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method addToCartAction
 * @description add to cart
 */
export function addToCartAction(data, callback) {
    const request = axios.post(`${CART_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            dispatch({
                type: UPDATE_CART_COUNT,
                payload: res.data.data.cart_count
            })
            dispatch({
                type: UPDATE_WISHLIST_COUNT,
                payload: res.data.data.wishlist_count
            })
            callback(res);
        }).catch(function (error) {
            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method toggleWishlistAction
 * @description toggel wishlist
 */
export function toggleWishlistAction(data, callback) {
    const request = axios.post(`${WISHLIST_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            dispatch({
                type: UPDATE_CART_COUNT,
                payload: res.data.data.cart_count
            })
            dispatch({
                type: UPDATE_WISHLIST_COUNT,
                payload: res.data.data.wishlist_count
            })
            callback(res.data);
        }).catch(function (error) {
            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method addRetailReview
 * @description add to cart
 */
export function addRetailReview(data, callback) {
    const request = axios.post(`${API.addretailReview}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getRetailAttributeAction
 * @description get all input for post an add
 */
export function getRetailAttributeAction(data, callback) {
    // let url = "https://formee.com/formee/api/showallretailattributes"
    const request = axios.get(`${RETAIL_INVENTORY_ATTRIBUTES_API}`, { params: data })
    // const request = axios.post(`${url}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method applyFilterAttributes
 * @description get all Filters
 */
export function applyRetailFilter(requestData, callback) {
    const request = axios.post(`${API.applyRetailFilter}`, requestData)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {

            // apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getCartAction
 * @descriptionupdate get retail cart api
 */
export function getCartAction(callback) {
    const request = axios.get(`${CART_API}`)
    return (dispatch) => {
        request.then((res) => {
            dispatch({
                type: UPDATE_CART_COUNT,
                payload: res.data.data.cartItems.length
            })
            // dispatch({
            //     type: UPDATE_WISHLIST_COUNT,
            //     payload: res.data.data.wishlist_count
            // })
            callback(res.data);
        }).catch(function (error) {
            callback(error);
        });
    }
}

/**
 * @method placeOrderAPI
 * @descriptionupdate place product order api
 */
export function placeOrderAPI(data, callback) {
    const request = axios.post(`${API.placeOrder}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method placeOrderAction
 * @descriptionupdate place product order api
 */
export function placeOrderAction(data, callback) {
    const request = axios.post(`${ORDER_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method getOrderListAction
 * @descriptionupdate place product order api
 */
export function getOrderListAction(data, callback) {
    const request = axios.get(`${ORDER_API}`, {
        params:
            data
    })
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method saveForLaterAPI
 * @descriptionupdate retail cart product save for later
 */
export function saveForLaterAPI(data, callback) {
    const request = axios.post(`${API.saveForLater}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method retailPostAdAction
 * @descriptionupdate retail post an ad api
 */
export function retailPostAdAction(data, callback) {
    const request = axios.post(`${RETAIL_ADS_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}


/**
 * @method getRetailAdAction
 * @descriptionupdate get gst percentage 
 */
export function getRetailAdAction(post_id, callback) {
    const request = axios.get(`${RETAIL_ADS_API}/${post_id}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            callback(error);
        });
    }
}



/**
 * @method updateRetailAdAction
 * @descriptionupdate update retail classified details
 */
export function updateRetailAdAction(data, callback) {
    const request = axios.post(`${RETAIL_ADS_API}/${data.adId}?_method=PUT`, data.formData)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {
            callback(error);
        });
    }
}

/**
 * @method getAllBrandsAction
 * @descriptionupdate get all brands api 
 */
export function getAllBrandsAction(data, callback) {
    const request = axios.get(`${RETAIL_BRANDS_API}?category_id=${data.category_id}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {
            callback(error);
        });
    }
}

/**
 * @method retailPopularItems
 * @descriptionupdate get retail popular items
 */
export function retailPopularItems(data, callback) {
    const request = axios.post(`${API.getMostPopularInRetail}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method saveSearchList
 * @descriptionupdate get save search list
 */
export function saveSearchList(callback) {
    const request = axios.get(`${API.getSaveSearchList}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method saveSearchList
 * @descriptionupdate get save search list
 */
export function deleteSearch(data, callback) {
    const request = axios.post(`${API.deleteSavedSerch}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method getDashboardAction
 * @descriptionupdate retail dashboard data
 */
export function getDashboardAction(data, callback) {
    const request = axios.get(DASHBOARD_API, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            callback(error);
        });
    }
}

/**
 * @method createRetailSearch
 * @descriptionupdate create retail search
 */
export function createRetailSearch(data, callback) {
    const request = axios.post(`${API.createRetailSearch}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method getSettingParametersAction
 * @descriptionupdate get setting parameters like gst percentage 
 */
export function getSettingParametersAction(callback) {
    const request = axios.get(`${SETTING_PARAMETER_API}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
            dispatch({
                type: SET_GST_PERCENTAGE,
                payload: res.data
            })
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method generateInvoice
 * @descriptionupdate generate invoice
 */
export function generateInvoice(callback) {
    const request = axios.get(`${API.generateInvoice}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method sendFormmeInvoice
 * @descriptionupdate send formee invoice
 */
export function sendFormmeInvoice(callback) {
    const request = axios.get(`${API.sendFormmeInvoice}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method retailPayment
 * @descriptionupdate user payment update
 */
export function retailPaymentAPI(data, callback) {
    const request = axios.post(`${API.userPaymentRetail}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method updateRetailSavedSerach
 * @descriptionupdate update retail saved search
 */
export function updateRetailSavedSerach(data, callback) {
    const request = axios.post(`${API.updateRetailSavedSearch}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method getMyRetailDeals
 * @description get a deal from Admin
 */
export function getMyRetailDeals(data, callback) {
    const request = axios.post(`${API.myRetailDeals}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
            // dispatch({
            //     type: SET_MY_DEALS,
            //     payload: res.data
            // })
        })
            .catch(function (error) {

                callback(error)
                // apiErrors(error)
            });
    }
}


/**
 * @method getRetailDealFromAdmin
 * @description get a deal from Admin
 */
export function getRetailDealFromAdmin(data, callback) {
    const request = axios.post(`${API.getRetailDealFromAdmin}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)

        })
            .catch(function (error) {
                callback(error)
                // apiErrors(error)
            });
    }
}

/**
 * @method uploadInventoryImageAction
 * @descriptionupdate upload retail product images
 */
export function uploadInventoryImageAction(data, callback) {
    const request = axios.post(`${INVENTORY_IMAGES_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}



/**
 * @method createRetailDeals
 * @description get a deal from Admin
 */
export function createRetailDeals(data, callback) {
    const request = axios.post(`${API.createRetailDeals}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method getRetailLandingPageData
 * @description fetch all category api
 */
export function getRetailLandingPageData(data1, data2, data3, callback) {
    return (dispatch) => {
        const request1 = axios.post(`${API.newRetailList}`, data1)
        const request2 = axios.post(`${API.newRetailList}`, data2)
        const request3 = axios.post(`${API.newRetailList}`, data3)
        let masterData = {
            mostRecent: [], best_sellors: [], recently_view: []
        };
        Promise.all([request1, request2, request3]).then((res) => {

            const mostRecent = res[0].data && res[0].data.data
            const best_sellors = res[1].data && res[1].data.data;
            const recently_view = res[2].data && res[2].data.data;
            masterData = {
                mostRecent, best_sellors, recently_view
            };
            callback(masterData);
        }).catch((error) => {
            callback(error);
        });
    };
}

/**
 * @method leaveFeedback
 * @description add to cart
 */
export function leaveFeedback(data, callback) {
    const request = axios.post(`${API.leaveFeedback}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method reportVendorReview
 * @description report review by business
 */
export function reportVendorReview(data, callback) {
    const request = axios.post(`${API.reportRetailVendorReview}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method replyVendorReview
 * @description reply to business review 
 */
export function replyVendorReview(data, callback) {
    const request = axios.post(`${API.reply_to_vendor_review}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method reportRetailAds
 * @description report retail ads
 */
export function reportRetailAds(data, callback) {
    const request = axios.post(`${API.reportRetailAds}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method removeFromCartAction
 * @description remove cart item item
 */
export function removeFromCartAction(data, callback) {
    const request = axios.delete(`${CART_API}/${data.item_id}`, data)
    return (dispatch) => {
        request.then((res) => {
            dispatch({
                type: UPDATE_CART_COUNT,
                payload: res.data.data.cart_count
            })
            dispatch({
                type: UPDATE_WISHLIST_COUNT,
                payload: res.data.data.wishlist_count
            })
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method reportUserFeedback 
 * @description report user feedback
 */
export function reportUserFeedback(data, callback) {
    const request = axios.post(`${API.reportFeedback}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method voteRetailFeedback
 * @description vote retail feedback
 */
export function voteRetailFeedback(data, callback) {
    const request = axios.post(`${API.voteFeedback}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method addToRetailWishList
 * @description vote retail feedback
 */
export function addToRetailWishList(data, callback) {
    const request = axios.post(`${API.adRetailToWishlist}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}


/**
 * @method voteRetailFeedback
 * @description vote retail feedback
 */
export function removeToRetailWishlist(data, callback) {
    const request = axios.post(`${API.removeRetailToWishlist}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method editRetailReview
 * @description edit retail review
 */
export function editRetailReview(data, callback) {
    const request = axios.post(`${API.updateRetailReview}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}


/**
 * @method checkFedexStatusAction
 * @description edit retail review
 */
export function checkFedexStatusAction(data, callback) {
    const request = axios.post(`${FEDEX_STATUS_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            callback(error);
        });
    }
}

/**
 * @method getSellerOrdersAction
 * @description retail business order list
 */
export function getSellerOrdersAction(data, callback) {
    const request = axios.get(`${SELLER_ORDER_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {

            // apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getOrderDetailsAction
 * @description retail order details
 */
export function getOrderDetailsAction(data, callback) {
    const request = axios.get(`${SELLER_ORDER_API}/${data.order_id}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            // apiErrors(error)
            callback(error);
        });
    }
}

/**
* @method setRetailSubCategories
* @description set retail subcategories
*/
export function setRetailSubCategories(item) {
    return (dispatch) => {
        dispatch({
            type: SET_RETAIL_SUB_CATEGORIES,
            payload: item
        })
    }
}

/**
* @method retailUserAdsAPI
* @description retail user ads
*/
export function retailUserAdsAPI(data, callback) {
    const request = axios.post(`${API.foundRetailUserAds}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
* @method getOrderDetailAction
* @description get detail of particular order
*/
export function getOrderDetailAction(order_id, callback) {
    const request = axios.get(`${ORDER_API}/${order_id}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}