import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './style/index.less';
import { store, pStore } from './store/index.js';
import { PersistGate } from 'redux-persist/lib/integration/react';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.Fragment>
    <Provider store={store} >
      <PersistGate loading={null} persistor={pStore}>
        <App />
      </PersistGate>
    </Provider>
  </React.Fragment>,
);

