import { put, takeLatest, call } from 'redux-saga/effects';
import {
  GET_CATEGORY_LIST,
  GET_FITNESS_DEFAULTS,
  GET_RESTAURANT_DEFAULTS,
  GET_SUB_CATEGORY_LIST,
} from "../constants";
import {
  CATEGORY_LIST_API,
  FITNESS_DEFAULTS_API,
  RESTAURANT_DEFAULTS_API,
  SUB_CATEGORY_LIST_API,
} from "../../api";
import axios from "../../api/axios"
import {
  getCategoryListError,
  getCategoryListSuccess,
  getSubCategoryListSuccess,
  getSubCategoryListError,
  handleErrorResponse,
  setFitnessDefaultsAction,
  setRestaurantDefaultsAction,
} from '../actions';

function getCategoryListApi(params) {
  let url = CATEGORY_LIST_API;
  if (params && params.type) { url = url + '/' + params.type; }
  return axios.request({ method: "get", url: url, });
}

function* fetchCategoryList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let res = yield call(getCategoryListApi, payload);
    resolve(res.data.data)
    if (!payload) {
      yield put(getCategoryListSuccess(res.data));
    }
  } catch (e) {
    handleErrorResponse(e.res)
    yield put(getCategoryListError(e))
    if (reject) reject()
  }
}

export function* getCategoryListActionWatcher() {
  yield takeLatest(GET_CATEGORY_LIST, fetchCategoryList)
}

function getSubCategoryListApi(params) {
  let url = SUB_CATEGORY_LIST_API;
  if (params && params.type) { url = url + '/' + params.type; }
  if (params && params.category_id) { url = url + '/' + params.category_id; }
  return axios.request({
    method: "get",
    url: url,
  });
}

function* fetchSubCategoryList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let res = yield call(getSubCategoryListApi, payload);
    yield put(getSubCategoryListSuccess(res.data));
    if (resolve) resolve(res.data)
  } catch (e) {
    yield put(getSubCategoryListError(e))
    if (reject) reject()
  }
}

export function* getSubCategoryListActionWatcher() {
  yield takeLatest(GET_SUB_CATEGORY_LIST, fetchSubCategoryList)
}


function getFitnessDefaultsApi() {
  return axios.request({ method: "get", url: FITNESS_DEFAULTS_API, });
}

function* fetchFitnessDefaults(action) {
  try {
    let res = yield call(getFitnessDefaultsApi);
    action.resolve(res?.data?.data ?? [])
    yield put(setFitnessDefaultsAction(res?.data?.data ?? []));
  } catch (e) {
    handleErrorResponse(e.res)
  }
}

export function* getFitnessDefaultsActionWatcher() {
  yield takeLatest(GET_FITNESS_DEFAULTS, fetchFitnessDefaults)
}


function getRestaurantDefaultsApi() {
  return axios.request({ method: "get", url: RESTAURANT_DEFAULTS_API, });
}

function* fetchRestaurantDefaults(action) {
  try {
    let res = yield call(getRestaurantDefaultsApi);
    action.resolve(res?.data?.data ?? [])
    yield put(setRestaurantDefaultsAction(res?.data?.data ?? []));
  } catch (e) {
    handleErrorResponse(e.res)
  }
}

export function* getRestaurantDefaultsActionWatcher() {
  yield takeLatest(GET_RESTAURANT_DEFAULTS, fetchRestaurantDefaults)
}