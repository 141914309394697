import {
    GET_RETAIL_LISTING,
} from "../constants";

export function getRetailListingAction(session, resolve, reject) {
    return {
        type: GET_RETAIL_LISTING,
        payload: session,
        resolve,
        reject
    };
}