import axios from "axios";
import { API } from "../../../config/Config";
import { apiErrors } from "../../../config/HandleAPIErrors";
import { GET_USER_PROFILE, GET_BUSINESS_PROFILE, UPDATE_CART_COUNT, UPDATE_WISHLIST_COUNT } from "../../constants";
import {
  USER_DETAILS_API,
  BUSINESS_PROFILE_API,
  NOTIFICATIONS_API,
  STRIPE_CONNECT_ACCOUNT_API,
  UPDATE_PROFILE_PIC_API,
  SIZE_GUIDE_API,
  UPDATE_BUSINESS_PROFILE_API,
  PAYPAL_CONNECT_ACCOUNT_API,
  STRIPE_CONNECT_STATUS_API,
  PAYPAL_CONNECT_STATUS_API,
  UPDATE_USER_PROFILE_API,
  WISHLIST_API,
  UPDATE_COMPANY_LOGO_API,
  REVIEWS_API,
  BILLING_HISTORY_API
} from "../../../api";
import { langs } from "../../../config/localization";
import { toastr } from "react-redux-toastr";

require("dotenv").config();

/**
 * User get User profile
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getUserProfile(data, callback) {
  let url = USER_DETAILS_API + data.user_id;
  const request = axios.get(`${url}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback !== undefined && callback(res);
        dispatch({
          type: GET_USER_PROFILE,
          payload: res.data,
        });
      })
      .catch(function (error) {
        callback !== undefined && callback(error);
        // apiErrors(error)
      });
  };
}

/**
 * @method getTraderProfile
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getTraderProfile(data, callback) {
  const request = axios.get(`${BUSINESS_PROFILE_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {

        callback !== undefined && callback(res);
        dispatch({
          type: GET_BUSINESS_PROFILE,
          payload: res,
        });
      })
      .catch(function (error) {
        callback !== undefined && callback(error);
        // apiErrors(error)
      });
  };
}


export function getReviewsAction(data, callback) {
  const request = axios.get(`${REVIEWS_API}`, { params: data });
  return (dispatch) => {
    request.then((res) => {
      callback(res.data)
    })
      .catch(function (error) {
        callback !== undefined && callback(error);
        // apiErrors(error)
      });
  };
}
/**
 * @method getBusinessProfileAction
 * @description get user profile data
 */
export function getBusinessProfileAction(data, callback) {

  let request
  if (data?.token)
    request = axios.get(`${BUSINESS_PROFILE_API}`, { headers: { "Authorization": `Bearer ${data.token}` } })
  else
    request = axios.get(`${BUSINESS_PROFILE_API}`)
  return (dispatch) => {
    request
      .then((res) => {
        dispatch({
          type: GET_BUSINESS_PROFILE,
          payload: res,
        });
        callback(res.data);
      })
      .catch(function (error) {

      });
  };
}

/**
 * User save Business profile
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function updateBusinessProfileAction(data, callback) {
  const request = axios.post(UPDATE_BUSINESS_PROFILE_API, data,
    {
      headers: {
        "Content-Type": "multipart/form-data; charset=utf-8;",
      },
    });
  return (dispatch) => {
    request
      .then((res) => {

        callback !== undefined && callback(res.data);
        // dispatch({
        //     type: GET_BUSINESS_PROFILE,
        //     payload: res
        // })
      })
      .catch(function (error) {
        callback !== undefined && callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User save User profile
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function saveUserProfileAction(data, callback) {
  const request = axios.post(UPDATE_USER_PROFILE_API, data);
  return (dispatch) => {
    request
      .then((res) => {

        callback !== undefined && callback(res);
        // dispatch({
        //     type: GET_BUSINESS_PROFILE,
        //     payload: res
        // })
      })
      .catch(function (error) {
        callback !== undefined && callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User save User profile
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function checkPaypalAccepted(data, callback) {
  const request = axios.post(PAYPAL_CONNECT_STATUS_API, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User save User profile
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getPaypalUrlAction(data, callback) {
  const request = axios.post(PAYPAL_CONNECT_ACCOUNT_API, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        callback !== undefined && callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User save User profile
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getStripeUrlAction(data, callback) {
  const request = axios.post(STRIPE_CONNECT_ACCOUNT_API, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        callback !== undefined && callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User save User profile
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function checkStripeConnectAction(data, callback) {
  const request = axios.get(STRIPE_CONNECT_STATUS_API);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        if (error?.response?.data?.message) {
          toastr.error(langs.error, error.response.data.message);
        } else {
          apiErrors(error);
        }
        callback(error);
      });
  };
}

/**
 * User save User profile
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function checkPaypalConnectAction(data, callback) {
  const request = axios.post(`${PAYPAL_CONNECT_STATUS_API}`, data.data);
  return (dispatch) => {
    request
      .then((res) => {

        callback(res.data);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * Get Paypal Login Url
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getPaypalLoginUrl(callback) {
  const request = axios.get(`${API.getPaypalClassifiedsLoginUrl}`);
  return (dispatch) => {
    request
      .then((res) => {

        callback(res);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}
/**
 * Get Paypal Login Url
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function sendPaypalCode(reqData, callback) {
  const request = axios.get(`${API.sendPaypalCode}?code=${reqData.code}&state=${reqData.state}`);
  return (dispatch) => {
    request
      .then((res) => {

        callback(res);
      })
      .catch(function (error) {
        apiErrors(error);
        // callback(error);
      });
  };
}

/**
 * User change user name
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function changeUserName(data, callback) {
  const request = axios.post(`${API.changeUserName}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
        getUserProfile({ user_id: data.user_id });
      })
      .catch(function (error) {
        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * User change user changeMobNo
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function changeMobNo(data, callback) {
  const request = axios.post(`${API.changeMobNo}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        getUserProfile({ user_id: data.user_id });
        callback(res);
      })
      .catch(function (error) {
        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * User change user changeMobNo
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function changeEmail(data, callback) {
  const request = axios.post(`${API.changeEmail}`, data, {
    "Content-Type": "multipart/form-data",
  });
  return (dispatch) => {

    request
      .then((res) => {
        getUserProfile({ user_id: data.user_id });
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * User change user changeMobNo
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function updateProfilePicAction(data, callback) {
  const request = axios.post(UPDATE_PROFILE_PIC_API, data);
  return (dispatch) => {

    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        apiErrors(error);
        callback(error);
      });
  };
}
/**
 * User change user company logo
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function updateCompanyLogoAction(data, callback) {
  const request = axios.post(`${UPDATE_COMPANY_LOGO_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * User change user changeMobNo
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function changeAddress(data, callback) {
  const request = axios.post(`${API.changeAddress}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        getUserProfile({ user_id: data.user_id });
        callback(res.data);
      })
      .catch(function (error) {
        apiErrors(error);
        callback(error);
      });
  };
}

export function getNotificationAction(params, callback) {
  const request = axios.get(`${NOTIFICATIONS_API}`, { params: params });
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function getActivePlanAction(callback) {
  const request = axios.get(`${API.activePlan}/${16}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function getBillingHistoryAction(data, callback) {
  const request = axios.get(`${BILLING_HISTORY_API}`, { params: data });
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function deleteNotificationAction(notificationId, callback) {
  const request = axios.delete(`${NOTIFICATIONS_API}/${notificationId}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function updateNotificationStatusAction(notification_id, callback) {
  const request = axios.post(`${NOTIFICATIONS_API}/${notification_id}?_method=PUT`,);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function getWishlistAction(data, callback) {
  const request = axios.get(`${WISHLIST_API}`, { params: data });
  return (dispatch) => {
    request
      .then((res) => {
        // dispatch({
        //   type: UPDATE_CART_COUNT,
        //   payload: res.data.data.cart_count
        // })
        dispatch({
          type: UPDATE_WISHLIST_COUNT,
          payload: res.data.data.length
        })
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export const removeFoodScannerItemFromFavorite = (requestData, callback) => {
  const request = axios.post(`${API.addToFavoriteFoodProduct}`, requestData);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        apiErrors(error);
      });
  };
};

/**
 * User save its size guide
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function uploadSizeGuideAction(data, callback) {
  const request = axios.post(SIZE_GUIDE_API, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback !== undefined && callback(res);
      })
      .catch(function (error) {
        callback !== undefined && callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User delete its size guide
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function deleteSizeGuideAction(id, callback) {
  const request = axios.delete(SIZE_GUIDE_API + '/' + id);
  return (dispatch) => {
    request
      .then((res) => {
        callback !== undefined && callback(res);
      })
      .catch(function (error) {
        callback !== undefined && callback(error);
        apiErrors(error);
      });
  };
}

export function deleteReviewAction({ review_id, ...params }, callback) {
  const request = axios.delete(`${REVIEWS_API}/${review_id}`, { params: params });
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        apiErrors(error);
      });
  };
};
