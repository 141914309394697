import axios from "axios";
import { API } from "../../../config/Config";
import { apiErrors } from "../../../config/HandleAPIErrors";
import {
  SET_ADDRESS,
  SET_DIETARY_TYPES,
  SET_SPORTS_COUNTRY,
  SET_POPULAR_VENUE,
  SET_FITNESS_TYPES,
  SET_POPULAR_RESTAURANT,
  SET_FOOD_TYPES,
  SET_RESTAURANT_REVIEWS,
  SET_RESTAURANT_DETAIL,
} from "./../../constants";
import { setPriority } from "../../../components/classified-templates/CommanMethod";
import {
  CATEGORY_EVENTS_API,
  CHOICE_OF_PREPARATION_API,
  GET_EVENT_VENUES_API,
  LIKE_REVIEWS_API,
  MENU_BULK_UPDATES_API,
  MENU_CATEGORY_API,
  PREVIOUS_ENQUIRIES_API,
  PROMO_CODE_API,
  REPLY_REVIEWS_API,
  REPORT_BUSINESS_API,
  REPORT_REVIEWS_API,
  RESTAURANT_CART_API,
  RESTAURANT_CHECKOUT_API,
  RESTAURANT_REVIEWS_API,
  REVIEWS_API,
  STRIPE_CARDS_API,
  USER_ADDRESS_API
} from "../../../api";



/**
 * @method newInClassified
 * @description get all new classified record
 */
export function newInBookings(data, callback) {
  const request = axios.post(`${API.booking}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        let filteredList = setPriority(res.data.data);
        res.data.data = filteredList;
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method getBookingDetails
 * @description get booking details
 */
export function getBookingDetails(data, callback) {
  const request = axios.post(`${API.getBookingDetail}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method getFitnessTypes
 * @description get Event types
 */
export function getFitnessTypes(callback) {
  const request = axios.get(`${API.getFitnessTypes}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback !== undefined && callback(res);
        dispatch({
          type: SET_FITNESS_TYPES,
          payload: res.data,
        });
      })
      .catch(function (error) {

        apiErrors(error);
        callback !== undefined && callback(error);
      });
  };
}


export function getFitnessTypesPost(data, callback) {
  const request = axios.post(`${API.getFitnessTypes}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}
/**
 * @method getPopularFitnessTypes
 * @description get popular fitness type
 */
export function getPopularFitnessTypes(callback) {
  const request = axios.get(`${API.getPopularFitnessTypes}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method getCategoryEventsAction
 * @description get Event types
 */
export function getCategoryEventsAction(category_id, callback) {
  const request = axios.get(`${CATEGORY_EVENTS_API}/${category_id}`);
  return (dispatch) => {
    request.then((res) => {
      callback(res.data);
      dispatch({
        type: SET_FOOD_TYPES,
        payload: res.data,
      });
    })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method getFoodTypes
 * @description get Event types
 */
export function getFoodTypes(callback) {
  const request = axios.get(`${API.getFoodTypes}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
        dispatch({
          type: SET_FOOD_TYPES,
          payload: res.data,
        });
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method getDiataries
 * @description get diataries
 */
export function getDiataries(callback) {
  const request = axios.get(`${API.getDietaries}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
        dispatch({
          type: SET_DIETARY_TYPES,
          payload: res.data,
        });
      })
      .catch(function (error) {
        apiErrors(error);
        callback(error);
      });
  };
}




/**
 * @method standardEats
 * @description get standard eats
 */
export function standardEats(callback) {
  const request = axios.get(`${API.standardEats}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method searchByRestaurent
 * @description Search on Restaurents
 */
export function searchByRestaurent(reqData, callback) {
  const request = axios.post(`${API.searchByRestaurent}`, reqData);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
        // dispatch({
        //     type: SET_FOOD_TYPES,
        //     payload: res.data
        // })
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method getRestaurantDetail
 * @description get restaurant details
 */
export function getRestaurantDetail(id, filter, callback) {
  const request = axios.get(
    `${API.getRestaurentDetail}/${id}?filter=${filter}`
  );
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
        dispatch({
          type: SET_RESTAURANT_DETAIL,
          payload: res.data && res.data.data,
        });
      })
      .catch(function (error) {

        // apiErrors(error)
        callback(error);
      });
  };
}

/**
 * @method deleteMenuCategoryAction
 * @description get restaurant details
 */
export function deleteMenuCategoryAction(category_id, callback) {
  const request = axios.delete(`${MENU_CATEGORY_API}/${category_id}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        // apiErrors(error)
        callback(error);
      });
  };
}

/**
 * @method menuBulkUpdatesAction
 * @description get restaurant details
 */
export function menuBulkUpdatesAction(data, callback) {
  const request = axios.post(`${MENU_BULK_UPDATES_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        // apiErrors(error)
        callback(error);
      });
  };
}

/**
 * @method deleteRestaurantMenu
 * @description get restaurant details
 */
export function deleteRestaurantMenu(id, callback) {
  const request = axios.delete(`${API.deleteRestaurantMenu}/${id}`);

  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        // apiErrors(error)
        callback(error);
      });
  };
}

/**
 * @method deleteChoiceOfPreparationAction
 * @description get restaurant details
 */
export function deleteChoiceOfPreparationAction(choice_id, callback) {
  const request = axios.delete(`${CHOICE_OF_PREPARATION_API}/${choice_id}`);

  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        // apiErrors(error)
        callback(error);
      });
  };
}


/**
 * @method getSportsList
 * @description get sports details
 */
export function getSportsList(data, callback) {
  const request = axios.post(`${API.getSportsWiseTournament}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}
/**
 * @method getSportsCountryList
 * @description get sports country list
 */
export function getSportsCountryList(callback) {
  const request = axios.post(`${API.getSportsCountry}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method getLogo
 * @description get sports country list
 */
export function getLogo(data, callback) {
  const request = axios.post(`${API.getLogos}`, data)
  return (dispatch) => {
    request.then((res) => {
      callback(res);
    }).catch(function (error) {

      apiErrors(error)
      callback(error);
    });
  }
}

/**
 * @method getpopularteamLogos
 * @description get sports country list
 */
export function getpopularteamLogos(data, callback) {
  const request = axios.post(`${API.getpopularteamLogos}`, data)
  return (dispatch) => {
    request.then((res) => {
      callback(res);
    }).catch(function (error) {

      apiErrors(error)
      callback(error);
    });
  }
}
/**
 * @method getSportsCityList
 * @description get sports city list
 */
export function getSportsCityList(data, callback) {
  const request = axios.post(`${API.getSportsCity}`, data);
  return (dispatch) => {
    request.then((res) => {
      callback(res);
    })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method getSportsCityList
 * @description get sports city list
 */
export function getPopularTeamsList(callback) {
  const request = axios.post(`${API.getPopularTeamList}`)
  return (dispatch) => {
    request.then((res) => {
      callback(res);
    }).catch(function (error) {

      apiErrors(error)
      callback(error);
    });
  }
}

/**
 * @method getSportsCityList
 * @description get sports city list
 */
export function getTournamentTicketList(data, callback) {
  const request = axios.post(`${API.getTournamentTicketList}`, data)
  return (dispatch) => {
    request.then((res) => {
      callback(res);
    }).catch(function (error) {

      apiErrors(error)
      callback(error);
    });
  }
}

/**
 * @method placeSportsOrder
 * @description get sports city list
 */
export function placePaypalSportsOrder(data, callback) {
  const request = axios.post(`${API.placePaypalSportsOrder}`, data)
  return (dispatch) => {
    request.then((res) => {
      callback(res);
    }).catch(function (error) {

      apiErrors(error)
      callback(error);
    });
  }
}
/**
 * @method getSavedCardsAction
 * @description get sports city list
 */
export function getSavedCardsAction(callback) {
  const request = axios.get(`${STRIPE_CARDS_API}`)
  return (dispatch) => {
    request.then((res) => {
      callback(res);
    }).catch(function (error) {

      apiErrors(error)
      callback(error);
    });
  }
}

/**
 * @method getSportsDetail
 * @description get sports details
 */
export function getSportsDetail(data, callback) {
  const request = axios.post(`${API.getTounamentTickets}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method getBookingPopularCategories
 * @description get booking popular categories
 */
export function getBookingPopularCategories(callback) {
  const request = axios.get(`${API.getBookingPopularCat}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method getPortFolioData
 * @description get port folio data
 */
export function getPortFolioData(id, callback) {
  const request = axios.get(`${API.portFolioData}/${id}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        // apiErrors(error)
        callback(error);
      });
  };
}

/**
 * @method standardEats
 * @description get standard eats
 */
export function getBookingSearchAutocomplete(data, callback) {
  const request = axios.post(`${API.generalBookingAutocompleteSearch}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        // apiErrors(error)
        callback(error);
      });
  };
}

// /**
//  * @method standardEats
//  * @description get standard eats
//  */
// export function getBookingSearchAutocomplete(data, callback) {
//     const request = axios.post(`${API.getBookingAutocompleteSearch}`, data)
//     return (dispatch) => {
//         request.then((res) => {
//             callback(res);
//         }).catch(function (error) {
//             
//             // apiErrors(error)
//             callback(error);
//         });
//     }
// }

/**
 * @method getSportsSearchAutocomplete
 * @description get sports data
 */
export function getSportsSearchAutocomplete(callback) {
  const request = axios.post(`${API.getAllSports}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        // apiErrors(error)
        callback(error);
      });
  };
}

/**
 * @method getSportsDetailAPI
 * @description get sports detail page data
 */
export function getSportsDetailAPI(data, callback) {
  const request = axios.post(`${API.getTounamentTickets}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        // apiErrors(error)
        callback(error);
      });
  };
}

/**
 * @method mostPopularEvents
 * @description get all most popular events
 */
export function mostPopularEvents(callback) {
  const request = axios.get(`${API.mostpopularEvents}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method mostPopularInHandyMan
 * @description most popular in handyman
 */
export function mostPopularInHandyMan(data, callback) {
  const request = axios.post(`${API.mostPolularInHandyMan}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method getRestaurantAutocompleteList
 * @description restaurant autocomplete
 */
export function getRestaurantAutocompleteList(name, callback) {
  const request = axios.get(`${API.restaurantAutocomplete}?name=${name}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method getRestaurantReviewsAction
 * @description restaurant reviews
 */
export function getRestaurantReviewsAction() {
  const request = axios.get(`${RESTAURANT_REVIEWS_API}`);
  return (dispatch) => {
    request
      .then((res) => {
        dispatch({
          type: SET_RESTAURANT_REVIEWS,
          payload: res?.data?.data,
        });
      })
      .catch(function (error) {

        apiErrors(error);
      });
  };
}

/**
 * @method getPopularRestaurant
 * @description restaurant popular list
 */
export function getPopularRestaurant() {
  const request = axios.get(`${API.popularRestaurants}`);
  return (dispatch) => {
    request
      .then((res) => {
        dispatch({
          type: SET_POPULAR_RESTAURANT,
          payload: res.data,
        });
      })
      .catch(function (error) {

        apiErrors(error);
      });
  };
}

/**
 * @method getPopularVenues
 * @description restaurant popular list
 */
export function getPopularVenues() {
  const request = axios.get(`${API.popularVenues}`);
  return (dispatch) => {
    request
      .then((res) => {
        dispatch({
          type: SET_POPULAR_VENUE,
          payload: res.data,
        });
      })
      .catch(function (error) {

        apiErrors(error);
      });
  };
}

/**
 * @method addRestaurantInFav
 * @description get all most popular events
 */
export function addRestaurantInFav(reqData, callback) {
  const request = axios.post(`${API.addRestaurantInFav}`, reqData);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method removeRestaurantInFav
 * @description get all most popular events
 */
export function removeRestaurantInFav(reqData, callback) {
  const request = axios.post(`${API.removeRestaurantInFav}`, reqData);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method getDailyDeals
 * @description get daily deals records
 */
export function getDailyDeals(reqData, callback) {
  const request = axios.post(`${API.getDailyDealsAPI}`, reqData);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        // apiErrors(error)
        callback(error);
      });
  };
}

/**
 * @method getBookingPromoAPI
 * @description get daily deals records
 */
export function getBookingPromoAPI(reqData, callback) {
  const request = axios.post(`${API.getBookingPromoData}`, reqData);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        // apiErrors(error)
        callback(error);
      });
  };
}

/**
 * @method getRestaurantSpecialOffer
 * @description get restaurants special offers
 */
export function getRestaurantSpecialOffer(reqData, callback) {
  const request = axios.post(`${API.getSpecialOffer}`, reqData);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        // apiErrors(error)
        callback(error);
      });
  };
}

/**
 * @method setCountry
 * @description set country
 */
export function setCountry(reqData) {
  return (dispatch) => {
    dispatch({
      type: SET_SPORTS_COUNTRY,
      payload: reqData,
    });
  };
}

/**
 * @method getBestPackagesAPI
 * @description get best beauty packages api
 */
export function getBestPackagesAPI(reqData, callback) {
  const request = axios.post(`${API.getBestPackages}`, reqData);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        // apiErrors(error)
        callback(error);
      });
  };
}

/**
 * @method markAsAttended
 * @description mark inspection as attended
 */
export function markAsAttended(reqData, callback) {
  const request = axios.post(`${API.markInspectionAsAttended}`, reqData);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method sendEmailToBookInspection
 * @description send email to book an inspection user
 */
export function sendEmailToBookInspection(reqData, callback) {
  const request = axios.post(`${API.sendEmailToInspection}`, reqData);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method popularSpaWellness
 * @description popular spa wellness
 */
export function popularSpaWellness(callback) {
  const request = axios.get(`${API.popularSpa}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method restaurantCartAction
 * @description get sports details
 */
export function restaurantCartAction(data, callback) {
  const request = axios.post(`${RESTAURANT_CART_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error.res);
      });
  };
}

export function getRestaurantCartAction(callback) {
  const request = axios.get(`${RESTAURANT_CART_API}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function updateCartQuantityAction({ item_id, ...data }, callback) {
  const request = axios.post(`${RESTAURANT_CART_API}/${item_id}?_method=PUT`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        apiErrors(error);
        callback(error);
      });
  };
}

export function getUserAddressAction(callback) {
  const request = axios.get(`${USER_ADDRESS_API}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}
export function retailCheckoutPay(data, callback) {
  const request = axios.post(`${API.retailCheckoutPay}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}


export function saveUserAddressAction(data, callback) {
  const request = axios.post(`${USER_ADDRESS_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method restaurantCheckoutAction
 * @param {*} data 
 * @param {*} callback 
 * @returns 
 */
export function restaurantCheckoutAction(data, callback) {
  const request = axios.post(`${RESTAURANT_CHECKOUT_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

// restaurent order success new api
export function placeRestaurantoOrderSuccess(data, callback) {
  const request = axios.post(`${API.placeRestaurantoOrderSuccess}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}
// restaurent order success new api

export function getPreviousEnquiryListAction(callback) {
  const request = axios.get(`${PREVIOUS_ENQUIRIES_API}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        apiErrors(error);
        callback(error);
      });
  };
}

// retail vote api


export function retailVoteAPI(data, callback) {
  const request = axios.post(`${API.retailVoteAPI}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

// tradet vote api 
export function likeReviewAction(data, callback) {
  const request = axios.post(`${LIKE_REVIEWS_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function getEventVenuesAction(callback) {
  const request = axios.get(`${GET_EVENT_VENUES_API}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function applyPromocodeAction(data, callback) {
  const request = axios.post(`${PROMO_CODE_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * User get dashboard Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getCustomerDashBoardDetails(data, callback) {
  const request = axios.post(`${API.customerDashboardDetails}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User get dashboard classified orders
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getCustomerDashBoardDetailsClassifiedOffers(data, callback) {
  const request = axios.post(
    `${API.customerDashboardDetailsClassifiedOffers}`,
    data
  );
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User get dashboard classified orders
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function cancelSentOffers(data, callback) {
  const request = axios.post(`${API.cancelSentOffers}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User get dashboard classified orders
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function updateSentOffers(data, callback) {
  const request = axios.post(`${API.updateSentOffers}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User get dashboard classified orders
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function cancelBookedInspection(data, callback) {
  const request = axios.post(`${API.cancelBookedInspection}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User get dashboard Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function newLandingPageBookingList(data, callback) {
  const request = axios.post(`${API.newTraderList}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * @method getBookingLandingPageData
 * @description fetch all category api
 */
export function getBookingLandingPageData(data1, data2, data3, callback) {
  return (dispatch) => {
    const request1 = axios.post(`${API.newTraderList}`, data1);
    const request2 = axios.post(`${API.newTraderList}`, data2);
    const request3 = axios.post(`${API.newTraderList}`, data3);
    let masterData = { mostRecent: [], top_rated: [], recently_view: [] };
    Promise.all([request1, request2, request3])
      .then((res) => {
        const mostRecent =
          res[0].data && res[0].data.data ? res[0].data.data : [];
        const top_rated =
          res[1].data && res[1].data.data ? res[1].data.data : [];
        const recently_view =
          res[2].data && res[2].data.data ? res[2].data.data : [];
        masterData = {
          mostRecent,
          top_rated,
          recently_view,
        };
        callback(masterData);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

/**
 * @method restaurantAutocompleteNew
 * @description restaurant auto complete new api
 */
export function restaurantAutocompleteNew(name, callback) {
  const request = axios.get(`${API.restaurantSuggestion}?name=${name}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        callback(error);
      });
  };
}

/**
 * @method restaurantAutocompleteNew
 * @description restaurant auto complete new api
 */
export function bookingAutocompleteNew(data, callback) {
  const request = axios.post(`${API.bookingAutocomplete}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        callback(error);
      });
  };
}

/**
 * @method restaurantAutocompleteNew
 * @description restaurant auto complete new api
 */
export function bookinglandingPageSearch(data, callback) {
  const request = axios.post(`${API.bookingSearch}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        callback(error);
      });
  };
}

/**
 * @method restaurantAutocompleteNew
 * @description restaurant auto complete new api
 */
export function voteBookingReview(data, callback) {
  const request = axios.post(`${API.voteTraderReview}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        callback(error);
      });
  };
}

/**
 * @method replyToReviewAction
 * @description reply to booking review
 */
export function replyToReviewAction(data, callback) {
  const request = axios.post(`${REPLY_REVIEWS_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * @method reportReviewAction
 * @description report booking review
 */
export function reportReviewAction(data, callback) {
  const request = axios.post(`${REPORT_REVIEWS_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        callback(error);
        apiErrors(error);
      });
  };
}

/**
* @method updateReplyRatingVendor
* @description report booking review
*/
export function updateReplyRatingVendor(data, callback) {
  const request = axios.post(`${API.updateReplyRatingVendor}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * @method commentDataVendorReview
 * @description get booking details
 */
export function commentDataVendorReview(data, callback) {
  const request = axios.post(`${API.commentDataVendorReview}`, data);

  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method editBookingReviewAction
 * @description update booking restaurants reviews
 */
export function editBookingReviewAction(data, callback) {
  const request = axios.post(`${REVIEWS_API}/${data.review_id}?_method=PUT`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * @method addBookingReviewAction
 * @description add booking restaurant reviews
 */
export function addBookingReviewAction(data, callback) {
  const request = axios.post(`${REVIEWS_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * @method setRestaurantAddress
 * @description set restaurant address
 */
export function setRestaurantAddress(address) {
  return (dispatch) => {
    dispatch({
      type: SET_ADDRESS,
      payload: address,
    });
  };
}

/**
 * @method removeCartItemAction
 * @description remove restaurant item from cart
 */
export function removeCartItemAction(item_id, callback) {
  const request = axios.delete(`${RESTAURANT_CART_API}/${item_id}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * @method reportBusinessAction
 * @description report business profile api
 */
export function reportBusinessAction(data, callback) {
  const request = axios.post(`${REPORT_BUSINESS_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * @method globalSearch
 * @description global search
 */
export function globalSearch(data, callback) {
  const { name, user_id, location, distance } = data;
  const request = axios.get(
    `${API.globalSearchAPI}?query=${name}&user_id=${user_id}&location=${location}&distance=${distance}`
  );
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

export function getTraderMonthBooking(data, callback) {
  const request = axios.post(`${API.getTraderMonthBooking}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}
