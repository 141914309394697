import { put, takeLatest, call } from 'redux-saga/effects';
import {
    GET_ADVERTISEMENT_LIST,
    GET_PAGE_LISTING,
} from "../constants";
import {
    PAGE_ADVERTISEMENT_API,
    PAGE_LISTING_API,
} from "../../api";
import axios from "../../api/axios"
import {
    getAdvertisementListError,
    getAdvertisementListSuccess,
    getPageListingError,
    getPageListingSuccess,
    handleErrorResponse,
} from '../actions';

function getAdvertisementListApi(params) {
    let url = PAGE_ADVERTISEMENT_API + params.page_id;
    if (params.category_id)
        url = url + '/' + params.category_id;
    return axios.request({ method: "get", url: url });
}

function* fetchAdvertisementList(action) {
    let { payload, resolve, reject } = action;
    try {
        let res = yield call(getAdvertisementListApi, payload);
        if (payload?.page_id === 'home-page') yield put(getAdvertisementListSuccess(res.data));
        if (resolve) resolve(res.data)
    } catch (e) {
        handleErrorResponse(e.res)
        yield put(getAdvertisementListError(e))
        if (reject) reject()
    }
}

export function* getAdvertisementListActionWatcher() {
    yield takeLatest(GET_ADVERTISEMENT_LIST, fetchAdvertisementList)
}

/**
 * 
 * @param {page_id} params 
 * @returns page product and services list
 */
function getPageListingApi(params) {
    const { page_id, ...paramsRest } = params;
    let url = PAGE_LISTING_API + page_id;
    return axios.request({ method: "get", url: url, params: paramsRest });
}

function* fetchPageListing(action) {
    let { payload, resolve, reject } = action;
    try {
        let res = yield call(getPageListingApi, payload);
        if (payload?.page_id === 'home-page') yield put(getPageListingSuccess(res.data));
        if (resolve) resolve(res.data)
    } catch (e) {
        handleErrorResponse(e.res)
        yield put(getPageListingError(e))
        if (reject) reject()
    }
}

export function* getPageListingActionWatcher() {
    yield takeLatest(GET_PAGE_LISTING, fetchPageListing)
}