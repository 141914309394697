import {
    GET_ADVERTISEMENT_LIST,
    GET_ADVERTISEMENT_LIST_SUCCESS,
    GET_ADVERTISEMENT_LIST_ERROR,
    GET_PAGE_LISTING,
    GET_PAGE_LISTING_SUCCESS,
    GET_PAGE_LISTING_ERROR,
} from "../constants";

export function getAdvertisementListAction(session, resolve, reject) {
    return {
        type: GET_ADVERTISEMENT_LIST,
        payload: session,
        resolve,
        reject
    };
}

export function getAdvertisementListSuccess(data) {
    return {
        type: GET_ADVERTISEMENT_LIST_SUCCESS,
        payload: data
    };
}

export function getAdvertisementListError(error) {
    return {
        type: GET_ADVERTISEMENT_LIST_ERROR,
        payload: error
    };
}

export function getPageListingAction(session, resolve, reject) {
    return {
        type: GET_PAGE_LISTING,
        payload: session,
        resolve,
        reject
    };
}

export function getPageListingSuccess(data) {
    return {
        type: GET_PAGE_LISTING_SUCCESS,
        payload: data
    };
}

export function getPageListingError(error) {
    return {
        type: GET_PAGE_LISTING_ERROR,
        payload: error
    };
}