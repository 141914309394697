import config from "../config.json";

export const API_URL = `${config.API_URL}/`;
export const BASE_URL = `${config.BASE_URL}/`;
export const TOURISM_URL = `${config.TOURISM_URL}/`;
export const ASSETS_URL = `${API_URL}assets/`;
export const SPORTS_IMAGES_URL = `${BASE_URL}images/sports/`;

// Auth API's
export const BUSINESS_REGISTRATION_API = `${API_URL}register-business`;
export const USER_REGISTRATION_API = `${API_URL}register`;
export const SOCIAL_LOGIN_API = `${API_URL}social-login`;
export const LOGIN_API = `${API_URL}login`;
export const USER_PROFILE_API = `${API_URL}user-profile`;
export const BUSINESS_PROFILE_API = `${API_URL}business-profile`;

export const USER_DETAILS_API = `${API_URL}user-details/`;
export const USER_MENU_API = `${API_URL}user-menu`;
export const NOTIFICATIONS_API = `${API_URL}notifications`;
export const MESSAGES_API = `${API_URL}messages`;
export const BLOCK_MESSAGES_API = `${API_URL}block-messages`;
export const UPDATE_PROFILE_PIC_API = `${API_URL}update-profile-pic`;
export const UPDATE_COMPANY_LOGO_API = `${API_URL}update-company-logo`;
export const UPDATE_PASSWORD_API = `${API_URL}update-password`;
export const FORGOT_PASSWORD_API = `${API_URL}forgot-password`;
export const RESET_PASSWORD_API = `${API_URL}reset-password`;
export const UPDATE_BUSINESS_PROFILE_API = `${API_URL}update-business-profile`;
export const UPDATE_USER_PROFILE_API = `${API_URL}update-user-profile`;
export const DASHBOARD_API = `${API_URL}dashboard`;
export const PROFILE_DOCUMENTS_API = `${API_URL}profile-documents`;
export const USER_ADDRESS_API = `${API_URL}user-address`;
export const REVIEWS_API = `${API_URL}review`;
export const LIKE_REVIEWS_API = `${API_URL}like-review`;
export const REPLY_REVIEWS_API = `${API_URL}reply-review`;
export const REPORT_REVIEWS_API = `${API_URL}report-review`;
export const RESTAURANT_REVIEWS_API = `${API_URL}restaurant-reviews`;
export const CALENDAR_API = `${API_URL}calendar`;
export const BILLING_HISTORY_API = `${API_URL}billing-history`;
export const BILLING_INVOICE_API = `${API_URL}billing-invoice`;
export const FEDEX_STATUS_API = `${API_URL}fedex-status`;

// General API's 
export const CATEGORY_LIST_API = `${API_URL}categories`;
export const SUB_CATEGORY_LIST_API = `${API_URL}sub-categories`;
export const SLUG_CATEGORY_API = `${API_URL}slug-categories`;
export const PAGE_ADVERTISEMENT_API = `${API_URL}page/advertisement/`;
export const PAGE_LISTING_API = `${API_URL}page/listing/`;
export const ADS_BY_BUSINESS_API = `${API_URL}ads-by-business/`;
export const ITEM_DETAIL_API = `${API_URL}details/`;
export const SEND_OTP_API = `${API_URL}send-otp`;
export const COUNTRY_STATE_CITY_API = `${API_URL}country-state-cities`;
export const AUTOCOMPLETE_API = `${API_URL}search-by-name/`;

//Payments
export const STRIPE_CONNECT_ACCOUNT_API = `${API_URL}stripe/connect-account`;
export const STRIPE_CONNECT_STATUS_API = `${API_URL}stripe/connect-status`;
export const STRIPE_CARDS_API = `${API_URL}stripe/cards`;
export const PAYPAL_CONNECT_ACCOUNT_API = `${API_URL}paypal/connect-account`;
export const PAYPAL_CONNECT_STATUS_API = `${API_URL}paypal/connect-status`;


export const ORDER_API = `${API_URL}orders`;
export const SELLER_ORDER_API = `${API_URL}seller-orders`;

// Retail Module
export const SIZE_GUIDE_API = `${API_URL}retail/size-guide`;
export const RETAIL_INVENTORY_ATTRIBUTES_API = `${API_URL}retail/inventory-attributes`;
export const INVENTORY_IMAGES_API = `${API_URL}retail/inventory-images`;
export const RETAIL_ADS_API = `${API_URL}retail/post-ad`;
export const RETAIL_BRANDS_API = `${API_URL}retail/brands`;
export const AD_PERMISSION_API = `${API_URL}ad-permissions`;
export const SUBSCRIPTION_API = `${API_URL}subscription`;

// Food Scanner Module
export const FOOD_SCANNER_API = `${API_URL}food-scanner`;

// BOOKING Module
export const PACKAGES_API = `${API_URL}packages`;
export const PROMOTIONS_API = `${API_URL}promotions`;
export const DAILY_DEALS_API = `${API_URL}daily-deals`;
export const SPECIAL_OFFERS_API = `${API_URL}special-offers`;
export const MEMBERSHIP_PACKAGES_API = `${API_URL}membership-packages`;
export const AVAILABLE_SLOTS_API = `${API_URL}available-slots`;
export const BUSINESS_AVAILABLE_SLOTS_API = `${API_URL}business-available-slots`;
export const PREVIOUS_QUOTES_API = `${API_URL}previous-quotes`;
export const PREVIOUS_BOOKINGS_API = `${API_URL}previous-bookings`;
export const PREVIOUS_ENQUIRIES_API = `${API_URL}previous-enquiries`;
export const ENQUIRE_API = `${API_URL}enquire`
export const REQUEST_QUOTE_API = `${API_URL}request-quote`;
export const CATEGORY_EVENTS_API = `${API_URL}category-events`;
export const FITNESS_CLASSESS_API = `${API_URL}fitness-classes`;
export const PROMO_CODE_API = `${API_URL}promo-code`;
export const CLASS_SCHEDULE_API = `${API_URL}class-schedule`;
export const BUSINESS_CLASSES_API = `${API_URL}business-classes`;
export const BUSINESS_MEMBERSHIP_API = `${API_URL}business-memberships`;
export const REPORT_BUSINESS_API = `${API_URL}report-business`;
export const BUSINESS_SERVICES_API = `${API_URL}business-services`;
export const SERVICE_CATEGORIES_API = `${API_URL}service-categories`;
export const BOOK_SERVICES_API = `${API_URL}bookings/services`;
export const BOOK_CLASSES_API = `${API_URL}bookings/classes`;
export const BOOK_MEMBERSHIP_API = `${API_URL}bookings/membership`;
export const QUOTE_API = `${API_URL}bookings/quotes`;
export const BOOKINGS_API = `${API_URL}bookings/booking`;
export const BOOKING_HISTORY_API = `${API_URL}bookings/history`;
export const FITNESS_DEFAULTS_API = `${API_URL}bookings/fitness-defaults`;
export const RESTAURANT_DEFAULTS_API = `${API_URL}bookings/restaurant-defaults`;
export const BOOKING_CHECKOUT_API = `${API_URL}checkout/bookings`;
export const CLASS_CHECKOUT_API = `${API_URL}checkout/class`;
export const MEMBERSHIP_CHECKOUT_API = `${API_URL}checkout/membership`;
export const SERVICE_CHECKOUT_API = `${API_URL}checkout/service`;
export const RESTAURANT_CHECKOUT_API = `${API_URL}checkout/restaurant`;
export const MENU_CATEGORY_API = `${API_URL}menu-category`;
export const MENU_ITEMS_API = `${API_URL}menu-items`;
export const CHOICE_OF_PREPARATION_API = `${API_URL}choice-of-preparation`;
export const MENU_BULK_UPDATES_API = `${API_URL}menu-bulk-updates`;
export const RESTAURANT_CART_API = `${API_URL}restaurant/cart`;
export const RESTAURANT_ORDERS_API = `${API_URL}restaurant/orders`;



// Classified
export const CLASSIFIED_ADS_API = `${API_URL}classified/post-ad`;
export const CLASSIFIED_OFFERS_API = `${API_URL}classified/offers`;
export const CLASSIFIED_INVENTORY_ATTRIBUTES_API = `${API_URL}classified/inventory-attributes`;
export const CLASSIFIED_ATTRIBUTES_CHILDREN_API = `${API_URL}classified/attributes-children`;
export const CLASSIFIED_GET_ATTRIBUTES_VALUES_API = `${API_URL}classified/get-attribute-values`;

export const JOB_APPLICATIONS_API = `${API_URL}classified/job-applications`;
export const CLASSIFIED_PAYMENT_API = `${API_URL}classified/payment`;
export const CLASSIFIED_PAYMENT_DETAILS_API = `${API_URL}classified/payment-details`;
export const CLASSIFIED_PACKAGE_PAYMENT_DETAILS_API = `${API_URL}classified/payment-details`;
export const JOB_QUESTIONS_API = `${API_URL}classified/job-questions`;
export const APPLY_JOB_API = `${API_URL}classified/apply-job`;
export const RESUME_API = `${API_URL}classified/resume`;
export const CLASSIFIED_WISHLIST_API = `${API_URL}classified/wishlist`;
export const INSPECTION_API = `${API_URL}classified/inspection`;

export const SETTING_PARAMETER_API = `${API_URL}setting-parameters`;
export const CART_API = `${API_URL}cart`;
export const WISHLIST_API = `${API_URL}wishlist`;
export const FAVOURITE_API = `${API_URL}favourite`;
export const REPORT_AD_API = `${API_URL}report/ad`;

// Events Module
export const GET_EVENT_VENUES_API = `${API_URL}event/venues`;

// Handyman Module
export const SERVICE_IMAGES_API = `${API_URL}service-images`;


// Sports Module
export const SPORTS_STATICS_API = `${API_URL}sports/sports-statics`;
export const SPORTS_BOOK_TICKET_API = `${API_URL}sports/book-ticket`;
export const SPORTS_PAYMENT_API = `${API_URL}sports/payment`;
export const SPORTS_EVENTS_SEARCH_API = `${API_URL}sports/sports-events-search`;


// Tourism Module
export const BOOKING_SUB_CATEGORIES_API = `${API_URL}booking-sub-categories`;
export const GET_MOST_BOOKED_HOTELS_API = `${API_URL}tourism/get-most-booked-hotels`;
export const GET_MOST_BOOKED_FLIGHTS_API = `${API_URL}tourism/get-most-booked-flights`;
export const GET_RECOMMENDED_TOURISM_API = `${API_URL}tourism/recommended-tourism/`;
export const TOP_RATED_HOTELS_API = `${API_URL}tourism/get-top-rated-hotel`;
export const HOTEL_AVERAGE_RATING_API = `${API_URL}tourism/get-hotel-average-rating`;
export const ADD_HOTEL_VIEW_API = `${API_URL}tourism/add-hotel-views`;
export const GET_LOCATIONS_API = `${API_URL}tourism/locations/`;
export const STORE_TOURISM_SEARCH_API = `${API_URL}tourism/store-searched-data`;

export const FAVORITE_UNFAVORITE_HOTEL_API = `${API_URL}tourism/hotel-mark-favorite`;
export const GET_HOTEL_VIEWS_API = `${API_URL}tourism/get-hotel-views`;
export const GET_HOTEL_RATING_VIEWS_API = `${API_URL}tourism/get-hotel-rating-views`;
export const POST_HOTEL_REVIEW_API = `${API_URL}tourism/store-hotel-ratings`;
export const IS_HOTEL_FAVORITE_API = `${API_URL}tourism/is-hotel-favorite`;
export const GET_CAR_POPULAR_DEST_API = `${API_URL}tourism/get-popular-car-hire-destination`;
export const TOP_RENTALCAR_API = `${API_URL}tourism/get-top-rated-car`;
export const CHECK_IS_CAR_FAV_API = `${API_URL}tourism/is-car-favorite`;
export const CAR_MARK_FAV_API = `${API_URL}tourism/car-mark-favorite`;
export const POST_CAR_REVIEW_API = `${API_URL}tourism/store-car-service-ratings`;
export const GET_CAR_VIEWS_API = `${API_URL}tourism/get-car-views`;
export const CARS_RECOMMENDED_FOR_YOU_API = `${API_URL}tourism/recommended-car`;
export const ADD_CARS_VIEW_API = `${API_URL}tourism/add-car-views`;
export const GET_CAR_REVIEWS_API = `${API_URL}tourism/get-average-rating`;

// Payments Tourism Module
export const HOTEL_BOOKING_API = `${API_URL}hotels/hotel-bookings`;
export const HOTEL_BOOKING_PAYPAL_API = `${API_URL}hotels/hotel-bookings-paypal`;
export const FLIGHT_BOOKING_API = `${API_URL}flights/flight-bookings`;
export const FLIGHT_BOOKING_PAYPAL_API = `${API_URL}flights/flight-bookings-paypal`;
export const CAR_BOOKING_API = `${API_URL}cars/car-bookings`;
export const CAR_BOOKING_PAYPAL_API = `${API_URL}cars/car-bookings-paypal`;

// Node API's
// Hotel Module
export const HOTEL_SEARCH_API = `${TOURISM_URL}hotel/search`;
export const HOTEL_DESCRIPTION_API = `${TOURISM_URL}hotel/hotel-descriptive-info`;
export const HOTEL_MONO_SEARCH_API = `${TOURISM_URL}hotel/mono-search`;
// Flight Module
export const FLIGHT_SEARCH_API = `${TOURISM_URL}search-flights`;
export const CHECK_FLIGHT_AVAILABILITY_API = `${TOURISM_URL}check-flight-availability`;
export const ADD_PASSENGER_DETAILS_API = `${TOURISM_URL}add-passengers`;
export const GET_FLIGHT_PNR_API = `${TOURISM_URL}get-flight-pnr`;
// Car Module
export const CARS_SEARCH_API = `${TOURISM_URL}car/search`;
export const CHECK_CAR_AVAILABILITY_API = `${TOURISM_URL}car/car-availability`;
export const ADD_DRIVER_DETAILS_API = `${TOURISM_URL}car/add-driver`;


