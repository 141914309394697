import { toast } from "react-toastify";

export function handleErrorResponse(error) {
  if (error && error.status && error.status == 423) {
    let data = error.data.data;

    for (let field in data) {
      if (data.hasOwnProperty(field)) {
        
        if (data[field][0]) {
          toast.error(data[field][0]);
          return;
        }
        return
      }

    }
  } else {
    if (error && error.data && error.data.message) {
      toast.error(error.data.message);
      return;
    }
  }
}

export function handleSuccessResponse(res) {
  if (res.success) {
    if (res.message) {
      toast.success(res.message);
    }
  }

  return;
}
export * from './general';
export * from './sports';
export * from './tourism/index';
export * from './pageData';
export * from './jobs';


export * from './Common';
export * from './auth/Auth';
export * from './user/ProfileAction';
export * from './classifieds';
export * from './classifieds/PostAd';
export * from './booking';
export * from './Messages';
export * from './Details';
export * from './vender/MyAds';
export * from './vender/DashBoard';
export * from './vender/Service'
export * from './vender/Profile'
export * from './vender/Fitness';
export * from './vender/GeneralVendor'
export * from './wellbeing';
export * from './user/MyBookingAction';
export * from './event';
export * from './Listing';
export * from './restaurant';
export * from './beauty';
export * from './vender/handyman';
export * from './retail';
export * from './retail/UserRetail';
export * from './vender/EventCaterer';
export * from './fitness/';
export * from './handyman/';
export * from './food-scanner/FoodScanner';
export * from './classifieds/vendor';
export * from './vender/MyAds';
export * from './retail/vendor';
export * from './Checkout';
export * from './booking/tourism/Flight'
export * from './booking/tourism/Car'
export * from './booking/tourism/Hotel'
