import axios from "../../api/axios";
import { apiErrors } from "../../config/HandleAPIErrors";
import { BLOCK_MESSAGES_API, MESSAGES_API } from "../../api";
import { API } from "../../config/Config";

/**
 * @method getMessagesAction
 * @description get all Messages
 */
export function getMessagesAction(requestData, callback) {
  const request = axios.get(`${MESSAGES_API}`, { params: requestData });
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        apiErrors(error);
        callback(error);
      });
  };
}

/**
 * @method getChatDetailAction
 * @description get all chat detail
 */
export function getChatDetailAction({ classified_id, ...requestData }, callback) {
  const request = axios.get(`${MESSAGES_API}/${classified_id}`, { params: requestData });
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        // apiErrors(error)
        callback(error);
      });
  };
}

export function updateMessageStatusAction(requestData, callback) {
  const request = axios.post(`${MESSAGES_API}/0?_method=PUT`, requestData);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        callback(error);
      });
  };
}

/**
 * @method deleteChatMessageAction
 * @description delete chat message
 */
export function deleteChatMessageAction(message_id, callback) {
  const request = axios.delete(`${MESSAGES_API}/${message_id}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        // apiErrors(error)
        callback(error);
      });
  };
}

/**
 * @method blockUnblockUserAction
 * @description block/Unblock Chat User
 */
export function blockUnblockUserAction(requestData, callback) {
  const request = axios.post(`${BLOCK_MESSAGES_API}`, requestData);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        // apiErrors(error)
        callback(error);
      });
  };
}

/**
 * @method sendMessageAction
 * @description get all chat detail
 */
export function sendMessageAction(requestData, callback) {
  const request = axios.post(`${MESSAGES_API}`, requestData);

  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        apiErrors(error);
        callback(error);
      });
  };
}

export async function getChatImages(requestData) {
  const request = await axios.post(`${API.getChatImages}`, requestData);

  return request.data.url;
}
