import axios from "axios";
import { FITNESS_CLASSESS_API, MEMBERSHIP_PACKAGES_API } from "../../../api";
import { API } from "../../../config/Config";
import { apiErrors } from "../../../config/HandleAPIErrors";

require("dotenv").config();

/**
 * User get Ad management Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function addFitnessClassAction(data, callback) {
  const request = axios.post(`${FITNESS_CLASSESS_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}
export function editMembership(data, callback) {

  const request = axios.post(`${API.createMembership}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}


/**
 * User get Ad management Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function createMemberShipPackageAction(data, callback) {

  const request = axios.post(`${MEMBERSHIP_PACKAGES_API}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User get Ad management Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function createClassCategory(data, callback) {
  const request = axios.post(`${API.classCategory}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User get Ad management Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getFitnessClassesAction(data, callback) {
  const request = axios.get(`${FITNESS_CLASSESS_API}/${data.id}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User get Ad management Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getMemberShipPackagesAction(data, callback) {
  const request = axios.get(`${MEMBERSHIP_PACKAGES_API}`, { params: data });
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User get Ad management Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getFitnessMemberShipBookings(data, callback) {
  const request = axios.post(`${API.getFitnessMembershipBookings}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User get Ad management Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function changeFitnessClassStatusAction({ class_id, ...data }, callback) {

  const request = axios.get(`${FITNESS_CLASSESS_API}/${class_id}/edit`, { params: data });
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        apiErrors(error);
      });
  };
}

export function changeMembershipStatusAction({ package_id, ...data }, callback) {

  const request = axios.get(`${MEMBERSHIP_PACKAGES_API}/${package_id}/edit`, { params: data });
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        apiErrors(error);
      });
  };
}

/**
 * User get Ad management Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
// export function deleteFitnessClass(data, callback) {
//   const request = axios.post(`${API.deleteFitnessClass}`, data);
//   return (dispatch) => {
//     request
//       .then((res) => {
//         callback(res);
//       })
//       .catch(function (error) {
//         callback(error);
//         apiErrors(error);
//       });
//   };
// }
// export function deleteFitnessClass(data) {
//   const request = axios.post(`${API.deleteFitnessClass}/${data}`);
//   
// }
export function deleteFitnessClass(data) {
  const request = axios.delete(`${API.deleteFitnessClass}/${data}`);
  if (request) {

  }
}

/**
 * User get Ad management Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function deleteFitnessMemberShip(data, callback) {
  const request = axios.delete(
    `${API.deleteFitnessMembership}/${data.id}`,
    data
  );
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User remove fitness class time
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function removeFitnessClassTime(data, callback) {
  const request = axios.post(`${API.deleteClassSchedule}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User get Ad management Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function changeStatusOfAllFitnessClass(data, callback) {
  const request = axios.post(`${API.deActiveAllFitnessClass}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User get Ad management Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function changeStatusOfAllFitnessMembership(data, callback) {
  const request = axios.post(`${API.deActiveAllFitnessMembership}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User get Ad management Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getFitnessScheduleBookings(data, callback) {
  const request = axios.post(`${API.getFitnessSchedulesMyBookings}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User get Ad management Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getFitnessScheduleBookings2(data, callback) {
  const request = axios.post(`${API.getFitnessSchedulesMyBookings2}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User add fitness class subscription
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function addFitnessClassSubscription(data, callback) {
  const request = axios.post(`${API.addFitnessClassSubscription}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

/**
 * User get Ad management Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getFitnessBookingsHistory(data, callback) {
  const request = axios.post(`${API.fitnessVendorHistoryListing}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}
export function listTraderCustomersOfBookedClasses(data, callback) {
  const request = axios.post(`${API.listTraderCustomersOfBookedClasses}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

export function getTraderMonthFitnessBooking(data, callback) {
  const request = axios.post(`${API.getTraderMonthFitnessBooking}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

export function vendorProfileFitnessDashboard(data, callback) {
  const request = axios.post(`${API.vendorprofileFitnessDashboard}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}
