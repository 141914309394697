import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from './reducers';
import rootSaga from './sagas';
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const middleware = compose(
  applyMiddleware(thunk),
  applyMiddleware(sagaMiddleware),
);
export const store = createStore(persistedReducer, middleware)
sagaMiddleware.run(rootSaga);
export const pStore = persistStore(store)

// import createSagaMiddleware from 'redux-saga';

// const sagaMiddleware = createSagaMiddleware();

// export default function configureStore() {
//     const store = createStore(
//         reducer,
//         applyMiddleware(sagaMiddleware),
//     );
//     sagaMiddleware.run(rootSaga);

//     return store;
// }