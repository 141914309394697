import {
    GET_ADVERTISEMENT_LIST,
    GET_ADVERTISEMENT_LIST_SUCCESS,
    GET_PAGE_LISTING,
    GET_PAGE_LISTING_SUCCESS,
} from "../constants";

const initialState = {
    advertisementList: [],
    pageListing: [],
}

export default function pageDataReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ADVERTISEMENT_LIST:
            return { ...state };
        case GET_ADVERTISEMENT_LIST_SUCCESS:
            return { ...state, advertisementList: action.payload.data }
        case GET_PAGE_LISTING:
            return { ...state };
        case GET_PAGE_LISTING_SUCCESS:
            return { ...state, pageListing: action.payload }
        default:
            return state;
    }
}
