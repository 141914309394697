import axios from 'axios';
import { FOOD_SCANNER_API } from '../../../api';
import { API } from '../../../config/Config';
import { apiErrors } from '../../../config/HandleAPIErrors';
import { SET_COMPARISION_PRODUCT_IDS } from '../../constants';


/**
 * @method getProductAutocompleteList
 * @description get product list in Auto Complete
 */
export const getProductAutocompleteList = (requestData, callback) => {
    const request = axios.post(`${API.foodScannerProductAutocompleteList}${requestData}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            apiErrors(error);
        });
    }
}

export const getProductList = (requestData, callback) => {
    const request = axios.post(`${API.foodScannerProductList}`, requestData)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {
            apiErrors(error);
            callback(error);
        });
    }
}

export const toggleFavoriteFoodScannerAction = (product_id, callback) => {
    const request = axios.get(`${FOOD_SCANNER_API}/${product_id}/edit`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            apiErrors(error);
        });
    }
}

export const getFoodScannerProductAction = (product_id, callback) => {
    const request = axios.get(`${FOOD_SCANNER_API}/${product_id}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            apiErrors(error);
        });
    }
}

export const getComparisionProductsAction = (products, callback) => {
    const request = axios.get(`${FOOD_SCANNER_API}`, { params: products })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            apiErrors(error);
        });
    }
}

/**
 * @method foodProductList
 * @description get product list in Auto Complete
 */
export const foodProductList = (callback) => {
    const request = axios.post(`${API.foodProductList}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            apiErrors(error);
        });
    }
}

/**
 * @method addProduct
 * @description get product list in Auto Complete
 */
export const addFoodProductAction = (requestData, callback) => {
    const request = axios.post(`${FOOD_SCANNER_API}`, requestData)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            apiErrors(error);
        });
    }
}

/**
 * @method addProduct
 * @description get product list in Auto Complete
 */
export const setComparisionProductIdAction = (payload) => {
    return {
        type: SET_COMPARISION_PRODUCT_IDS,
        payload: payload,
    };
}

/**
 * @method barcodeSearch
 * @description get product list in Auto Complete
 */
export const barcodeSearch = (searchKeyword, callback) => {
    const request = axios.post(`${API.barcodeSearch}${searchKeyword}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            apiErrors(error);
        });
    }
}

/**
 * @method searchInFoodScanner
 * @description get product list in Auto Complete
 */
export const searchInFoodScanner = (requestData, callback) => {
    const request = axios.post(`${API.foodScannerSearch}`, requestData)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            callback(error)
            // apiErrors(error);
        });
    }
}