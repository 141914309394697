import {
    GET_ITEM_DETAILS,
    GET_ITEM_DETAILS_SUCCESS,
} from "../constants";

const initialState = {
    itemDetails: [],
}

export default function itemDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ITEM_DETAILS:
            return { ...state };
        case GET_ITEM_DETAILS_SUCCESS:
            return { ...state, itemDetails: action.payload }
        default:
            return state;
    }
}
