/** All messages used in application */
export const MESSAGES = {
  SERVER_ERROR: "Something went wrong please try again later.",
  REGISTER_SUCCESS: "User has been registered successfully.",
  CATEGORY_SAVED: "Your category has been saved succesfully.",
  MENU_SAVED_SUCCESS: `User menu saved successfully`,
  CHANGE_PASSWORD_SUCCESS: `Your password has been updated successfully, please login with new password.`,
  MENU_SKIPED_SUCCESS: `Menu skipped successfully do not ask me again.`,
  FILTER_SAVE_SUCCESS: `Filter has been saved succesfully.`,
  FILTER_UPDATE_SUCCESS: `Filter has been update succesfully.`,
  FILTER_DELETE_SUCCESS: `Filter has been deleted succesfully.`,
  POST_ADD_SUCCESS: `Your post has been created successfully.`,
  ATLEAST_ONE_FILTER: `Please select atleast one filter`,
  REVIEW_ADD_SUCCESS: "Review has been added successfully.",
  MESSAGE_SENT_SUCCESS: "Message has been sent successfully.",
  SELECT_MENU: "Please select menu.",
  POST_LIMIT: "Your ad posting limit is over.",
  PERMISSION_ERROR: "You do not have a permission to post an ad.",
  PAYMENT_SUCCESS: "Your Payment has been done successfully.",
  ADD_WISHLIST_SUCCESS: "Item added to Wishlist.",
  REMOVE_WISHLIST_SUCCESS: "Item removed from Wishlist.",
  NUMBER_VARIFY_SUCCESS: "Your phone number has been varified successfully.",
  OFFER_ADD_SUCCESS: "Your offer has been saved successfully.",
  SELECT_MENU: "Please select menu",
  RESUME_UPDATE: "Your resume has been saved succesfully.",
  FILE_DELETE_SUCCESS: "Your file has been deleted successfully.",
  FILE_UPLOAD_SUCCESS: "Your file has been uploaded successfully.",
  FILE_DELETE_CONFIRM: "Are you sure, you want to delete this file?",
  VALID_FILE_TYPE: "You can only upload PDF , DOC, DOCX RTF & TXT file!",
  VALID_FILE_SIZE: "File must smaller than 2MB!",
  DUPLICATE_FILE_NAME: "File is already exist",
  BOOK_INSPECTION: "Inspection has been booked successfully.",
  BEAUTY_SERVICE_DELETE: "Trader Service Deleted Successfully",
  CONFIRM_DELETE: "Are sure you want to delete ?",
  BEAUTY_SERVICE_UPDATE: "Trader Service updated Successfully",
  BEAUTY_SERVICE_CREATE: "Trader Service has been created Successfully",
  PROFILE_UPDATE_SUCCESS: "Your profile has been updated successfully",
  PAYPAL_VERIFIED_SUCCESS: "Paypal has been verified successfully",
  PAYPAL_REDIRECTING_SUCCESS: "Please wait redirecting to Paypal onboarding",
  STRIPE_VERIFIED_SUCCESS: "Stripe has been verified successfully",
  STRIPE_REDIRECTING_SUCCESS: "Please wait redirecting to Stripe onboarding",
  MENU_UPDATE_SUCCESS: "Menu item details has been updated successfully.",
  MENU_DELETE_SUCCESS: "Menu item details has been deleted successfully.",
  MENU_CREATE_SUCCESS: "Your menu has been created successfully.",
  MENU_CATEGORY_ADD_SUCCESS: "Menu category has been added successfully.",
  MENU_CATEGORY_UPDATE_SUCCESS: "Menu category has been updated successfully.",
  PORTFOLIO_DELETE_SUCCESS: "Your Portfolio has been deleted successfully.",
  PORTFOLIO_UPDATE_SUCCESS: "Your Portfolio has been updated successfully.",
  PORTFOLIO_CREATE_SUCCESS: "Your Portfolio has been created successfully.",
  PORTFOLIO_REQUIRED: "Portfolio Image is required.",
  CLASS_CREATE_SUCCESS: "Fitness class has been created successfully.",
  CLASS_UPDATE_SUCCESS: "Fitness updated has been created successfully.",
  DEAL_CREATE_SUCCESS: "Deals has been created successfully.",
  OFFER_CREATE_SUCCESS: "Offer has been created successfully.",
  PACKAGE_CREATE_SUCCESS: "Package has been created successfully.",
  PROMO_CREATE_SUCCESS: "Promotion has been created successfully.",
  DISSCOUNT_VALIDATION: "Discount Limit is out of the Limit.",
  SERVICE_LENGTH_VALIDATION: "In a package Services should be greater than 2 or less than 4",
  MEMBERSHIP_CREATE_SUCCESS: "Membership has been created successfully.",
  MEMBERSHIP_UPDATE_SUCCESS: "Membership has been updated successfully.",
  CLASS_DELETE_SUCCESS: "Fitness class has been deleted successfully.",
  CLASS_SHEDULE_DELETE_SUCCESS: "Fitness class schedule has been deleted successfully.",
  CLASS_CHANGE_STATUS_SUCCESS: "Fitness class status has been changed successfully.",
  ALL_CLASS_CHANGE_STATUS_SUCCESS: "All Fitness class status has been changed successfully.",
  MEMBERSHIP_CHANGE_STATUS_SUCCESS: "Fitness membership status has been changed successfully.",
  ALL_MEMBERSHIP_CHANGE_STATUS_SUCCESS: "All Fitness membership status has been changed successfully.",
  INSPECTION_DELETE_SUCCESS: "Inspection has been deleted successfully.",
  APPLICATION_JOB_SUCCESS: "Applied successfullly.",
  MAKE_AN_OFFER: "Offer price can not be greater than the existing price value.",
  OFFER_ALREADY_SENT: "Offer already sent",
  ALREADY_APPLIED_ON_A_JOB: "You have already applied on this job.",
  NOT_ABLE_APPLY_OFFER: "There is no price associated for this ad.",
  MANDATE_FILDS: "Please fill mandate fields before adding the another.",
  DECILNED_ENQUIRY: "Enquiry has been declined sucessfully.",
  CONFIRM_HANDYMAN_BOOKING: "Booking has been confirmed sucessfully.",
  DECILNED_BOOKING: "Booking has been declined sucessfully.",
  CREATE_QUOTE: "Quote has been created sucessfully.",
  ACCEPT_QUOTE: "Quote has been accepted sucessfully.",
  RESCHEDULE_BOOKING: "Booking has been reschuled sucessfully.",
  AD_TO_CART: "Your cart details has been added successfully.",
  REQUEST_SENT: "Request has been sent sucessfully.",
  REPORT_ADD_SUCCESS: "Ad has been reported successfully.",
  REPORT_ADD_WARNING: "Ad has been already reported.",
  ADDRESS_ADD_SUCCESS: "Address has been added successfully.",
  ADDRESS_UPDATE_SUCCESS: "Address has been updated successfully.",
  REVIEW_UPDATE_SUCCESS: "Review has been updated successfully.",
  DATE_SELECTION_VALIDATION: "Past date are not allowed",
  ADD_SIZE_GUIDE: "Size Guide has been added succesfully",
  DELETE_SIZE_GUIDE: "Size Guide has been deleted succesfully",
  DELETE_PAYMENT_CARD: "Your card has been deleted succesfully",
  SAVE_PAYMENT_CARD: "Your card has been saved succesfully",
  UPDATE_PAYMENT_CARD: "Your card has been updated succesfully",
};
