import axios from 'axios';
import { CLASSIFIED_ADS_API, CLASSIFIED_OFFERS_API } from '../../../api';
import { API } from '../../../config/Config';
import { apiErrors } from '../../../config/HandleAPIErrors';
import { SET_GENERAL_VENDOR_MY_OFFER, } from '../../constants';

require('dotenv').config();

/**
 * User get Ad management Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getOfferListAction(params, callback) {
    const request = axios.get(`${CLASSIFIED_OFFERS_API}`, { params: params })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
            dispatch({
                type: SET_GENERAL_VENDOR_MY_OFFER,
                payload: res.data
            })
        })
            .catch(function (error) {
                callback(error)
                // apiErrors(error)
            });
    }
}


/**
 * delete Classified
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function deleteGeneralClassified(data, callback) {
    const request = axios.post(`${API.deleteGeneralClassified}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}


/**
 * get Offer Detail List
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getOfferDetailAction({ classified_id, ...params }, callback) {
    const request = axios.get(`${CLASSIFIED_OFFERS_API}/${classified_id}`, { params: params })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * Change Status
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function changeGeneralOfferStatus(data, callback) {
    const request = axios.post(`${API.acceptOffer}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}


/**
 * Change Status
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function changeGeneralClassifiedStatus(data, callback) {

    const request = axios.post(`${CLASSIFIED_ADS_API}/${data.classified_id}?_method=PUT`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}