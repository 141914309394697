// ------------------Third Party Credentials---------------- //
export const GOOGLE_CLIENT_ID = '932948088381-vrqpp4l6kjeu23qofg3fbqinat02oos8.apps.googleusercontent.com';
export const GOOGLE_MAP_KEY = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB6p3DBzMK19qsqR0zcHYurjh_Ra21jnHs&libraries=geometry,drawing,places';
export const FACEBOOK_APP_ID = '167001851407764';
export const GEOCODE_API_KEY = 'AIzaSyB6p3DBzMK19qsqR0zcHYurjh_Ra21jnHs';
export const STRIPE_PUBLIC_KEY = 'pk_test_m1DbcNYIBYHUVxxHgBRlBj1O007idAV60K';
export const PAYPAL_ENV = 'snadbox';
export const PAYPAL_CLIENT = {
  sandbox: "AREIlckrVOn91AOK1bYVlg1lsWgqAK49f6j-clybiFLQAe6F7-yovfTNlK7CyUAij5lxQZbteK0MvNdi",
  production: "EC3KB5dIv6Wv1ICrM7uuCBRTjXf7wQxKpIqRIT3B1rurf8lot-2_uY-GhecmZCiGPvVuV8GugAC-aljC",
};

// ------------------DEFAULT DEVICE SETTINGS---------------- //
export const DEFAULT_DEVICE_ID = 'dnPGfEqZjbg:APA91bGQLhY4a-2bI3RwY-0-BLSpQd4mk1SNqrHC46JDGw2qFCgwetXEGJF-8Yu97X6V3wqEzh-gcsgZ9ajmJXo2G4ksU7eK-JVpV6_hsCGihpV-c576hJgt1WjhVbHhj9A16y4oAV5G';
export const DEFAULT_MODEL = 'samsung, SM-G925W8';
export const DEFAULT_DEVICE_TYPE = 'Android';
export const SIZE = '4242880';

// ------------------DEFAULT ASSETS---------------- //
export const DEFAULT_IMAGE_TYPE = require('../assets/images/default-profile.jpeg');
export const DEFAULT_IMAGE_CARD = require('../assets/images/default_image.jpg');
export const DEFAULT_THUMB_IMAGE = require('../assets/images/default-thumb.png');
export const DEFAULT_ICON = require('../assets/images/default-icon.png');

// Local Config - Ple ase set to your local url parameter
export const BASE_URL = 'https://admin.formee.com';
export const CLIENT_NODE_URL_SPORTS = 'https://test.formee.com:4061';
export const CLIENT_NODE_URL_TOURISM = 'https://tourism.formee.com';
export const NODE_URL = 'https://test.formee.com:4063';

export const TEMPLATE = {
  GENERAL: 'general',
  JOB: 'jobs',
  REALESTATE: 'real-estate',
  CLASSIFIED: 'classified',
  HANDYMAN: 'Handyman',
  BEAUTY: 'Beauty',
  WELLBEING: 'Wellbeing',
  PSERVICES: 'Professionals',
  EVENT: 'Events',
  SPORTS: 'Sports',
  RESTAURANT: 'Restaurant',
  TURISM: 'Tourism',
  ALL: 'all',
  FITNESS: 'Fitness',
};

export const SELLER_TYPE = {
  PRIVATE: 'private',
  RETAIL: 'retail',
  PROFESSIONAL: 'professional-services',
  HANDYMAN: 'handyman',
  WELLBEING: 'wellbeing',
  EVENTS: 'events',
  RESTAURANT: 'restaurant',
  BEAUTY: 'beauty',
  FITNESS: 'fitness',
  SPA: 'spa',
  REAL_ESTATE: 'real-estate',
  JOB: 'jobs',
  CAR_DEALER: 'car-dealer',
}

export const FILTER_TABS = {
  MOST_RECENT: 'most-recent',
  TOP_RATED: 'top-rated',
};

export const REVIEW_TYPE = {
  PRODUCT: 'product',
  BOOKING: 'booking',
  RETAIL: 'retail',
};

export const SORT_REVIEW_BY = {
  MOST_RECENT: 'most-recent',
  TOP_RATED: 'top-rated',
  OLDEST_FIRST: 'desc',
  NEWEST_FIRST: 'asc',
};

export const JOB_APPICANT_STATUS = {
  APPLICANT: 'applicant',
  REJECT: 'reject',
  SPAM: 'spam',
  SHORTLIST: 'shortlist',
  INTERVIEW: 'interview',
};

export const GENERAL_APPICANT_STATUS = {
  PENDING: 'Pending',
  ACCEPT: 'Accepted',
  REJECT: 'Rejected',
};

export const QUESTION_TYPES = {
  TEXT: 'text',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
};

export const PAGE_SIZE = {
  PAGE_SIZE_10: 10,
  PAGE_SIZE_12: 12,
  PAGE_SIZE_15: 15,
  PAGE_SIZE_20: 20,
};

export const BOOKING_SLUG = {
  MAKE_UP: 'make-up',
};

export const BUSINESS_DETAIL_TABS = {
  INFORMATION: 'Information',
  SERVICES: 'Service',
  CLASSES: 'Classes',
  PORTFOLIO: 'Portfolio',
  REVIEW: 'Reviews',
};

export const BOOKING_TABS = {
  CLASSES: 'classes',
  MEMBERSHIPS: 'memberships',
  ENQUIRIES: 'enquiries',
  BOOKINGS: 'bookings',
  HISTORY: 'history',
  CONFIRMED: 'confirmed',
  PENDING: 'pending',
};

export const ORDERS_TABS = {
  PAST_ORDERS: 'past-orders',
  CONFIRMED_ORDERS: 'confirmed-orders',
  CURRENT_ORDERS: 'current-orders',
  ON_THE_WAY: "on-the-way",
  NEW_ORDERS: 'new-orders',
  IN_PROGRESS: 'in-progress',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  CONFIRMED: 'confirmed',
  PENDING: 'pending',
  DELIVERED: 'delivered',
  IN_THE_KITCHEN: 'in-the-kitchen',
  READY_FOR_PUCK_UP: 'food-is-ready-for-pickup',
};

export const FITNESS_TABS = {
  CLASSES: 'classes',
  MEMBERSHIP: 'membership',
};

export const SORT_OPTIONS = {
  TODAY: 'Today',
  THIS_WEEK: 'This Week',
  THIS_MONTH: 'This Month',
  THIS_YEAR: 'This Year',
};

export const DASHBOARD_TYPES = {
  JOB: 'Jobs', //Employer Dashboard
  GENERAL: 'General', //General Dashboard
  REAL_ESTATE: 'Real Estate', //Real Estate Dashboard
};

export const API = {
  // V2 API's Start
  // Sports API START
  sportsStatic: `${BASE_URL}/api/v2/sports/sports-statics`,
  getSportsEvent: `${CLIENT_NODE_URL_SPORTS}/api/v2/sports/all-events`,
  getSportsWiseTournament: `${CLIENT_NODE_URL_SPORTS}/api/v2/sports/get-sportswise-tournaments`,
  getSportsCountry: `${CLIENT_NODE_URL_SPORTS}/api/v2/sports/all-countries`,
  getSportsCity: `${CLIENT_NODE_URL_SPORTS}/api/v2/sports/all-cities`,
  sportsBookingTicketForm: `${CLIENT_NODE_URL_SPORTS}/api/v2/sports/booking-event-ticket`,
  getTournamentTicketList: `${CLIENT_NODE_URL_SPORTS}/api/v2/sports/get-tournament-tickets`,
  getTounamentTickets: `${CLIENT_NODE_URL_SPORTS}/api/v2/sports/get-tournament-tickets`,
  sportsEventSearch: `${CLIENT_NODE_URL_SPORTS}/api/v2/sports/sportswise-events`,
  getAllSports: `${CLIENT_NODE_URL_SPORTS}/api/v2/sports/get-all-sports`,
  // Sports API END

  // tourism starts
  bookingSubCategory: `${BASE_URL}/api/v2/booking-sub-categories`,
  getMostBookedHotels: `${BASE_URL}/api/v2/tourism/get-most-booked-hotels`,
  getMostBookedFilght: `${BASE_URL}/api/v2/tourism/get-most-booked-flights`,
  getFlightAutocompleteAPI: `${CLIENT_NODE_URL_TOURISM}/api/v2/locations`,
  ///hotels
  hotelSearch: `${CLIENT_NODE_URL_TOURISM}/api/v2/hotel/search`,
  getTopRatedhotel: `${BASE_URL}/api/v2/tourism/get-top-rated-hotel`,
  favoriteUnfavoriteHotel: `${BASE_URL}/api/v2/tourism/hotel-mark-favorite`,
  hotelMonoSearch: `${CLIENT_NODE_URL_TOURISM}/api/v2/hotel/mono-search`,
  getHotelviews: `${BASE_URL}/api/v2/tourism/get-hotel-views`,
  getHotelavgrating: `${BASE_URL}/api/v2/tourism/get-hotel-average-rating`,
  getHotelRatingViews: `${BASE_URL}/api/v2/tourism/get-hotel-rating-views`,
  postHotelReview: `${BASE_URL}/api/v2/tourism/store-hotel-ratings`,
  addHotelViews: `${BASE_URL}/api/v2/tourism/add-hotel-views`,
  isHotelFavorite: `${BASE_URL}/api/v2/tourism/is-hotel-favorite`,
  getHotelDetails: `${CLIENT_NODE_URL_TOURISM}/api/v2/hotel/hotel-descriptive-info`,


  // V2 API's End


  login: `${BASE_URL}/api/login`,
  logout: `${BASE_URL}/api/logout`,
  temp: `http://10.10.10.179/formeenew/api/login`,
  forgotPassword: `${BASE_URL}/api/forgotpassword`,
  resetPassword: `${BASE_URL}/api/reset-password`,
  userRegistration: `${BASE_URL}/api/registration`,
  vendorRegistration: `${BASE_URL}/api/vendor-registration`,
  classifiedCategory: `${BASE_URL}/api/categoriestabledata`,
  bookingCategory: `${BASE_URL}/api/booking-categories`,
  retailCategory: `${BASE_URL}/api/allretailcategories`,
  addCategory: `${BASE_URL}/api/menu`,
  postReviewService: `${BASE_URL}/api/add_review`,
  userMenu: `${BASE_URL}/api/menu`,
  menuSkip: `${BASE_URL}/api/skip-menu`,
  changePassword: `${BASE_URL}/api/changepassword`,
  getUserProfile: `${BASE_URL}/api/userdetail`,
  changeUserName: `${BASE_URL}/api/changeusername`,
  changeMobNo: `${BASE_URL}/api/changemobileno`,
  changeEmail: `${BASE_URL}/api/changeemail`,
  changeProfile: `${BASE_URL}/api/changeprofile`,
  updateUserProfile: `${BASE_URL}/api/update-user-profile`,
  changeAddress: `${BASE_URL}/api/changecontactaddress`,
  getClassifiedListing: `${BASE_URL}/api/classified-list`,
  getClassifiedDetail: `${BASE_URL}/api/classified-detail`,
  addToFavorite: `${BASE_URL}/api/add-to-favourites-copy`,
  removeToFavorite: `${BASE_URL}/api/remove-from-favourites`,
  getBannerPage: `${BASE_URL}/api/pages`,
  getPageAdvertisementAction: `${BASE_URL}/api/page-advertisment`,
  getRetailList: `${BASE_URL}/api/classified-list-retail`,
  getMostViewd: `${BASE_URL}/api/most-viewed`,
  getClassifiedAttibute: `${BASE_URL}/api/showallattributes`,
  childAttribute: `${BASE_URL}/api/showallattributeschildren`,
  getClassifiedFilterAttibute: `${BASE_URL}/api/get_all_classifiedbyfilter`,
  getSavesearchlist: `${BASE_URL}/api/savesearchlist`,
  savesearchlist: `${BASE_URL}/api/save_searchapi`,
  deleteSavedSearch: `${BASE_URL}/api/delete_saved_searchapi`,
  editSavedSearch: `${BASE_URL}/api/searchlistupdatestatus`,
  postAd: `${BASE_URL}/api/addclassified`,
  bussinessUserPostAd: `${BASE_URL}/api/businessuseraddclassified`,
  getPostAnAdDetailAPI: `${BASE_URL}/api/editclassified`,
  updatePostAd: `${BASE_URL}/api/updateclassified`,
  childCategory: `${BASE_URL}/api/get-child-categories`,
  newInClassified: `${BASE_URL}/api/new-classified-ads-list`,
  papularSearch: `${BASE_URL}/api/popular-searches`,
  classifiedGeneralSearch: `${BASE_URL}/api/get_all_classifiedssearchbyname`,
  mostPapular: `${BASE_URL}/api/featured-categories`,
  addReview: `${BASE_URL}/api/add-classified-reviews`,
  removeReview: `${BASE_URL}/api/soft-delete-booking-rating`,
  booking: `${BASE_URL}/api/traders-list`,
  deleteClass: `${BASE_URL}/api/delete-classes-copy`,
  addWishList: `${BASE_URL}/api/addwishlist`,
  removeWishList: `${BASE_URL}/api/removewishlist`,
  activePlan: `${BASE_URL}/api/active-plan`,
  getOccupationType: `${BASE_URL}/api/get-classified-retail-occupation-types`,
  classifiedGeneralSearchOption: `${BASE_URL}/api/get_all_classifiedswithname`,
  callForPopularSearch: `${BASE_URL}/api/search`,
  getPopularSearchCities: `${BASE_URL}/api/popular-search-cities`,
  getSearchesByCity: `${BASE_URL}/api/search-by-city`,
  getAllChats: `${BASE_URL}/api/get-messages-list`,
  getChatDetails: `${BASE_URL}/api/get-messages`,
  updateMessages: `${BASE_URL}/api/update-message-status`,
  deleteChatMessage: `${BASE_URL}/api/delete-chat-message`,
  blockUnblockChatUser: `${BASE_URL}/api/block-unblock-user`,
  getTrakingOrders: `${BASE_URL}/api/get-orders-for-vendor/all-orders`,
  sendMessage: `${BASE_URL}/api/send_msg_api`,
  adPermission: `${BASE_URL}/api/ad-permissions`,
  createResume: `${BASE_URL}/api/create-resume`,
  savePlanDetails: `${BASE_URL}/api/save-plan-details`,
  getResume: `${BASE_URL}/api/resume-details`,
  makeAnOffer: `${BASE_URL}/api/offermessageapi_message`,
  applyJobAPI: `${BASE_URL}/api/job-apply-post`,
  deleteUploadedFiles: `${BASE_URL}/api/remove-resume-document`,
  getBookingDetail: `${BASE_URL}/api/trader-detail`,
  getSportsEnquirylist: `${BASE_URL}/api/sports356/list-sports-bookings`,
  getFitnessTypes: `${BASE_URL}/api/delete-trader-service-image`,
  removeProfile: `${BASE_URL}/api/wellbeing-fitness-types`,
  getEventTypesSuggestions: `${BASE_URL}/api/get-event-search-filters`,
  getFoodTypes: `${BASE_URL}/api/all-cusines`,
  getDietaries: `${BASE_URL}/api/all-dietaries`,
  standardEats: `${BASE_URL}/api/all-standard_etas`,
  searchByRestaurent: `${BASE_URL}/api/browse-restaurants`,
  getRestaurentDetail: `${BASE_URL}/api/restaurants`,
  deleteRestaurantCategory: `${BASE_URL}/api/menucategories`,
  deleteRestaurantMenu: `${BASE_URL}/api/menuitems`,
  deleteExtraChoiceOfPreparation: `${BASE_URL}/api/menuitems-choice-of-preparation`,
  activateAllItems: `${BASE_URL}/api/activate-deactivate-all-menu-items`,
  deleteAllItems: `${BASE_URL}/api/delete-all-menu-items`,
  deleteAllCategory: `${BASE_URL}/api/delete-all-menu-category`,
  updateMenu: `${BASE_URL}/api/update-menuitems`,
  contactAdSendMessage: `${BASE_URL}/api/send_msg_api_message`,
  bookAnInspection: `${BASE_URL}/api/book-an-inspection`,
  getJobQuestions: `${BASE_URL}/api/job-apply`,
  getAdManagementDetails: `${BASE_URL}/api/addmanagmenttab`,
  changeClassifiedGeneralStatus: `${BASE_URL}/api/frontclassifiedchangestatus`,
  changeApplicationStatus: `${BASE_URL}/api/change_applicant_status`,
  deleteClassifiedAPI: `${BASE_URL}/api/frontclassifieddelete`,
  getJobApplications: `${BASE_URL}/api/jobapplications`,
  getBookingPopularCat: `${BASE_URL}/api/get-popular-category`,
  getEmployerDashboard: `${BASE_URL}/api/classified-employer-vendor-dashboard`,
  getTraderProfile: `${BASE_URL}/api/get-trader-profile`,
  getTraderProfileUser: `${BASE_URL}/api/retail-classified-user-detail`,
  saveBusinessProfile: `${BASE_URL}/api/save-trader-profile`,
  getPaypalUrl: `${BASE_URL}/api/get-paypal-mp-reg-url`,
  getPaypalClassifiedsLoginUrl: `${BASE_URL}/api/get_paypal_login_url`,
  getStripeUrl: `${BASE_URL}/api/create_stripe_connected_account`,
  verifyStripe: `${BASE_URL}/api/get_stripe_connect_link_response`,
  verifyPaypal: `${BASE_URL}/api/mp-onboard-success-api`,
  sendPaypalCode: `${BASE_URL}/api/get_paypal_code`,
  getBeautyService: `${BASE_URL}/api/beauty/list-trader-services`,
  getSpaService: `${BASE_URL}/api/list-trader-services`,
  getServiceCategory: `${BASE_URL}/api/get-services-categories`,
  deleteServiceCategory: `${BASE_URL}/api/delete-fitness-category-services`,
  updateSpaService: `${BASE_URL}/api/update-trader-service`,
  removeServiceCategory: `${BASE_URL}/api/soft-delete-service-category`,
  deleteAllServiceCategory: `${BASE_URL}/api/delete-all-service-categories`,
  activateAllServices: `${BASE_URL}/api/activate-deactivate-all-trader-services`,
  deleteAllServices: `${BASE_URL}/api/delete-all-wellbeing-trader-services`,
  updateServiceCategory: `${BASE_URL}/api/update-service-category`,
  createSpaService: `${BASE_URL}/api/trader-services`,
  deleteBeautyService: `${BASE_URL}/api/beauty/trader-services`,
  portFolioData: `${BASE_URL}/api/portfolio-tab-data`,
  deleteRestaurantItem: `${BASE_URL}/api/menuitems`,
  updateMenuItem: `${BASE_URL}/api/update-menuitems`,
  getMenuItemById: `${BASE_URL}/api/menuitems`,
  saveRestaurantProfileDetail: `${BASE_URL}/api/restaurants`,
  updateRestaurantProfileDetail: `${BASE_URL}/api/update-businee-profile`,
  getAllMenuCategory: `${BASE_URL}/api/all-menu-categories`,
  addMenuCategory: `${BASE_URL}/api/menucategories`,
  updateMenuCategory: `${BASE_URL}/api/update-menu-category`,
  activateMenuCategory: `${BASE_URL}/api/active-deactive-menu-category`,
  createFitnessClass: `${BASE_URL}/api/create-classes`,
  createMembership: `${BASE_URL}/api/fitness-class-packages`,
  classCategory: `${BASE_URL}/api/store-services-categories`,
  getFitnessClasses: `${BASE_URL}/api/list-trader-classes`,
  // getFitnessClasses: `${BASE_URL}/api/get-services-categories`,
  getFitnessmembership: `${BASE_URL}/api/list-trader-packages`,
  getFitnessMembershipBookings: `${BASE_URL}/api/list-vendor-members`,
  getFitnessSchedulesMyBookings: `${BASE_URL}/api/view-trader-schedule`,
  getFitnessSchedulesMyBookings2: `${BASE_URL}/api/view-trader-schedule2`,
  addFitnessClassSubscription: `${BASE_URL}/api/fitness-class-subscription`,

  // getBookingAutocompleteSearch: `${BASE_URL}/api/get-booking-autocomplete-search`,
  generalBookingAutocompleteSearch: `${BASE_URL}/api/general-booking-autocomplete-search`,
  mostpopularEvents: `${BASE_URL}/api/event/get-popular-event`,
  addRestaurantInFav: `${BASE_URL}/api/add-to-favourites-copy`,
  removeRestaurantInFav: `${BASE_URL}/api/remove-from-favourites`,
  viewPortfolio: `${BASE_URL}/api/view-portfolio`,
  deletePortfolioImages: `${BASE_URL}/api/delete-all-images`,
  viewBrocher: `${BASE_URL}/api/view-brochure`,
  viewCertificate: `${BASE_URL}/api/view-certification`,
  viewGallery: `${BASE_URL}/api/view-gallery`,
  uploadDocuments: `${BASE_URL}/api/upload-profile-documents`,
  deleteDocuments: `${BASE_URL}/api/delete-profile-document`,
  createPortfolio: `${BASE_URL}/api/create-portfolio`,
  updatePortfolio: `${BASE_URL}/api/update-portfolio`,
  uploadPortfolio: `${BASE_URL}/api/portfolio-upload`,
  deletePortfolio: `${BASE_URL}/api/delete-portfolio`,
  activeInactiveRestaurant: `${BASE_URL}/api/active-deactive-menu-item`,
  mostPolularInHandyMan: `${BASE_URL}/api/get-popular-sub-categories`,
  restaurantAutocomplete: `${BASE_URL}/api/get-searched-menus`,
  // changeFitnessClassStatus: `${BASE_URL}/api/activate-deactivate-trader-service`,
  changeFitnessClassStatus: `${BASE_URL}/api/active-deactive-fitness-class`,
  changeFitnessMemberShipStatus: `${BASE_URL}/api/active-deactive-class-package`,
  restaurantCustomerReviews: `${BASE_URL}/api/restaurant-customer-reviews`,
  popularRestaurants: `${BASE_URL}/api/get-popular-restaurant`,
  popularVenues: `${BASE_URL}/api/get-popular-venue`,
  deleteFitnessClass: `${BASE_URL}/api/delete-trader-service`,
  deleteFitnessMembership: `${BASE_URL}/api/fitness-class-packages`,
  deleteClassSchedule: `${BASE_URL}/api/active-deactive-class-schedule`,
  deActiveAllFitnessClass: `${BASE_URL}/api/active-deactive-fitness-class-all`,
  deActiveAllFitnessMembership: `${BASE_URL}/api/active-deactive-class-package-all`,
  activeDeactiveService: `${BASE_URL}/api/beauty/active-deactive-service-status`,
  getDealFromAdmin: `${BASE_URL}/api/get-daily-deals`,
  createDeals: `${BASE_URL}/api/add-vendor-daily-deals`,
  activateDeactivateDeal: `${BASE_URL}/api/active-deactive-vendor-daily-deals`,
  myDeals: `${BASE_URL}/api/get-vendor-daily-deals`,

  getPopularFitnessTypes: `${BASE_URL}/api/get-popular-class-booking`,
  getDailyDealsAPI: `${BASE_URL}/api/get-users-daily-deals`,
  checkPaypalAccepted: `${BASE_URL}/api/check_onboard_status`,
  getBookingPromoData: `${BASE_URL}/api/get-users-promotions`,
  addOffer: `${BASE_URL}/api/add-vendor-special-offers`,
  myOffers: `${BASE_URL}/api/get-vendor-special-offers`,
  eligibleOffer: `${BASE_URL}/api/get-admin-special-offers`,
  getSpecialOffer: `${BASE_URL}/api/get-users-special-offers`,
  activateDeactivateOffer: `${BASE_URL}/api/active-deactive-vendor-special-offers`,
  activateDeactivatePromo: `${BASE_URL}/api/active-deactive-vendor-promotions`,
  activateDeactivatePacakage: `${BASE_URL}/api/active-deactive-vendor-beauty-package`,
  getBestPackages: `${BASE_URL}/api/get-all-vendor-best-beauty-package`,

  vendorAdList: `${BASE_URL}/api/inspection-list`,
  getInspectionList: `${BASE_URL}/api/inspection-list-by-date`,
  deleteAllInspection: `${BASE_URL}/api/delete-all-inspection`,
  deleteInspection: `${BASE_URL}/api/delete-realestate-inspection`,
  markInspectionAsAttended: `${BASE_URL}/api/mark-inspection-attended`,
  sendEmailToInspection: `${BASE_URL}/api/send-email-to-booking-request`,
  getResumeFile: `${BASE_URL}/api/jobapplications/view`,
  userInspectionList: `${BASE_URL}/api/inspection-list-user`,
  userUpdateInspection: `${BASE_URL}/api/edit-booked-inspection`,





  getPopularTeamList: `${BASE_URL}/api/get-popular-team`,
  getLogos: `${BASE_URL}/api/get-team-logos`,
  getCards: `${BASE_URL}/api/get-all-user-cards`,
  placeSportsOrder: `${BASE_URL}/api/sports356/place-order`,
  placePaypalSportsOrder: `${BASE_URL}/api/sports356/place-order-paypal`,
  getpopularteamLogos: `${BASE_URL}/api/get-top-team-logos`,
  getGeneralVendorMyOffers: `${BASE_URL}/api/getclassifiedofferreceived`,
  deleteGeneralClassified: `${BASE_URL}/api/deleteClassified`,
  getGeneralOfferDetail: `${BASE_URL}/api/getclassifiedofferreceived-list`,
  acceptOffer: `${BASE_URL}/api/offer-accept-reject`,
  changeClassifiedStatus: `${BASE_URL}/api/ad-change-status`,
  popularSpa: `${BASE_URL}/api/get-popular-spa-wellness`,
  getVendorClassified: `${BASE_URL}/api/get-category-by-user-id`,
  getTraderMonthWellbeingBooking: `${BASE_URL}/api/get-trader-month-wellbeing-booking`,
  getTraderMonthBeautyBooking: `${BASE_URL}/api/get-trader-month-beauty-booking`,
  getSpaDietitianSpaBooking: `${BASE_URL}/api/spa/dietitian-spa-booking`,
  getServiceBooking: `${BASE_URL}/api/get-service-booking`,
  updateSpaDietitianSpaBooking: `${BASE_URL}/api/spa/update-dietitian-spa-booking`,
  getTraderDetails: `${BASE_URL}/api/trader-detail`,
  getBookingIdByEnquiryId: `${BASE_URL}/api/get-bookingId-by-enquiryId`,

  getServiceBookingCheckout: `${BASE_URL}/api/service-booking-checkout-api`,
  listCustomerServiceBookings: `${BASE_URL}/api/spa/list-customer-service-bookings`,
  listCustomerBookingsHistory: `${BASE_URL}/api/spa/list-customer-booking-history`,
  getCustomerMyBookingsCalender: `${BASE_URL}/api/spa/get-my-booking-calender`,
  listVendorServiceSpaBookings: `${BASE_URL}/api/list-vendor-service-bookings`,
  listVendorServiceSpaBookingsHistory: `${BASE_URL}/api/list-vendor-booking-history`,
  getVendorWellBeingMonthBookingsCalender: `${BASE_URL}/api/get-trader-month-wellbeing-booking`,
  vendorServiceBookingResponse: `${BASE_URL}/api/vendor-service-booking-response`,
  customerServiceBookingResponse: `${BASE_URL}/api/customer-service-booking-response`,
  deleteEventHistoryBooking: `${BASE_URL}/api/booking-history-soft-delete`,
  deleteSpaHistoryBooking: `${BASE_URL}/api/spa/delete-booking-history`,
  deleteBeautyHistoryBooking: `${BASE_URL}/api/beauty/delete-booking-history`,
  getSpaServiceRepay: `${BASE_URL}/api/spa/repay`,
  spaDeletebookingHistory: `${BASE_URL}/api/spa/delete-booking-history`,
  beautyDeletebookingHistory: `${BASE_URL}/api/beauty/delete-booking-history`,
  vendorChangeSlotStatus: `${BASE_URL}/api/change-trader-slot`,
  customerBookingDispute: `${BASE_URL}/api/customer-booking-dispute`,
  sendEventEnquiry: `${BASE_URL}/api/enquires`,
  getEventEnquiry: `${BASE_URL}/api/enquires`,
  saveToRestaurantCart: `${BASE_URL}/api/save-to-restaurant-cart`,
  viewRestaurantCart: `${BASE_URL}/api/view-restaurant-cart`,
  updateRestaurantCart: `${BASE_URL}/api/update-cart-item`,
  getUserAddress: `${BASE_URL}/api/get-user-address`,
  retailCheckoutPay: `${BASE_URL}/api/retail-checkout`,
  saveUserAddress: `${BASE_URL}/api/save-user-address`,
  placeRestaurantOrder: `${BASE_URL}/api/place-restaurant-order`,
  getUserRestaurantCurrentOrders: `${BASE_URL}/api/user-orders/current_orders`,
  getUserRestaurantPastOrders: `${BASE_URL}/api/user-orders/past_orders`,
  getOrderDetailsById: `${BASE_URL}/api/orders`,
  getOrderDelete: `${BASE_URL}/api/orders`,
  getCancelRestaurantOrder: `${BASE_URL}/api/cancel-restaurant-order`,
  getRestaurantVendorOrderByMonth: `${BASE_URL}/api/get-restaurant-orders-by-month`,
  getRestaurantVendorEarning: `${BASE_URL}/api/get-vendor-earnings`,
  getRestaurantOrdersForVendor: `${BASE_URL}/api/get-orders-for-vendor`,
  acceptRestaurantOrder: `${BASE_URL}/api/accept-order`,
  updateRestaurantOrderStaus: `${BASE_URL}/api/update-order-status`,
  getUserPreviousQuote: `${BASE_URL}/api/get-quotes-list`,
  getEventVenuesList: `${BASE_URL}/api/get-event-venues-list`,
  beautyServiceBooking: `${BASE_URL}/api/beauty/service-booking`,
  updateBeautyServiceBooking: `${BASE_URL}/api/beauty/manage-booking`,
  updateBeautyServiceBookingFitness: `${BASE_URL}/api/modify-trader-class-booking`,
  getBeautyServiceBooking: `${BASE_URL}/api/beauty/get-service-booking`,
  beautyServiceBookingCheckout: `${BASE_URL}/api/beauty/service-booking-checkout-api`,
  reOrderRestaurantItems: `${BASE_URL}/api/view-restaurant-cart`,
  applyPromoCode: `${BASE_URL}/api/cart-apply-promo`,
  listVendorServiceBeautyBookings: `${BASE_URL}/api/beauty/list-vendor-service-bookings`,
  getFitnessServiceBookingCheckout: `${BASE_URL}/api/subscription-checkout-api`,
  checkoutTraderClassBooking: `${BASE_URL}/api/checkout-trader-class-booking`,
  listUserBeautyServiceBookings: `${BASE_URL}/api/beauty/list-customer-service-bookings`,
  listVendorServiceBeautyBookingHistory: `${BASE_URL}/api/beauty/list-vendor-booking-history`,
  beautyServiceBookingsReview: `${BASE_URL}/api/beauty/service-review`,
  wellbeingServiceBookingsReview: `${BASE_URL}/api/wellbeing-service-review`,
  getSchedule: `${BASE_URL}/api/list-class-schedule`,
  buyTraderClass: `${BASE_URL}/api/buy-trader-class`,
  getFitnessCustomerHistoryList: `${BASE_URL}/api/list-customer-history`,
  getFitnessDeleteBooking: `${BASE_URL}/api/delete-fitness-booking-history`,
  getHandyManVenderEnquiries: `${BASE_URL}/api/get-trader-quotes`,
  getHandyManVenderEnquiryDetail: `${BASE_URL}/api/get-quote-detail`,
  changeEnquiryStatus: `${BASE_URL}/api/update-trader-quote-status`,
  getHandyManVenderJobs: `${BASE_URL}/api/get-trader-jobs`,
  getHandyManVenderBookingDetail: `${BASE_URL}/api/get-job-detail`,
  changeHandymanBookingStatus: `${BASE_URL}/api/trader-update-job-status`,
  cancelTraderQuoteStatus: `${BASE_URL}/api/cancel-trader-quote-status`,
  changeWellbeingBookingStatus: `${BASE_URL}/api/vendor-service-booking-response`,
  changeBeautyBookingStatus: `${BASE_URL}/api/beauty/vendor-service-booking-response`,
  getHandyManVenderHistoryList: `${BASE_URL}/api/get-trader-history`,
  getHandyManVenderHistoryDetail: `${BASE_URL}/api/get-history-detail`,
  reScheduleHadymanBooking: `${BASE_URL}/api/reschedule-job`,
  createQuote: `${BASE_URL}/api/create-quote`,
  // fitnessVendorHistoryListing: `${BASE_URL}/api/fitness-vendor-profile-history-listing`,
  fitnessVendorHistoryListing: `${BASE_URL}/api/list-trader-schedule-history`,
  listUserBeautyServiceBookingsHistory: `${BASE_URL}/api/beauty/list-customer-booking-history`,
  cancelBeautyServiceBooking: `${BASE_URL}/api/beauty/customer-service-booking-response`,
  cancelFitnessServiceBooking: `${BASE_URL}/api/cancel-trader-class-booking`,
  customerBeautyBookingDispute: `${BASE_URL}/api/customer-service-booking-dispute`,
  beautyServiceBookingRepay: `${BASE_URL}/api/beauty/repay`,
  listTraderCustomersOfBookedClasses: `${BASE_URL}/api/list-trader-customers-of-booked-classes`,
  eventcatereVenderEquiryList: `${BASE_URL}/api/vendor-enquire-list`,
  eventcatererVenderBookingList: `${BASE_URL}/api/vendor-booking-list`,
  eventcatererVenderHistoryList: `${BASE_URL}/api/vendor-history-list`,
  eventcatereVenderEquiryDetail: `${BASE_URL}/api/enquires`,
  eventcatereVenderBookingsDetail: `${BASE_URL}/api/bookings`,
  declinEventEnquiry: `${BASE_URL}/api/enquire-response`,
  deleteTraderJobUrl: `${BASE_URL}/api/delete-trader-job`,
  deleteTraderJobUrlss: `${BASE_URL}/api/delete-quote-request`,
  deleteTraderEnquiryUrl: `${BASE_URL}/api/delete-quote`,
  DeleteWellBeingUrl: `${BASE_URL}/api/delete-service-booking`,
  deleteEventBookingUrl: `${BASE_URL}/api/delete-event-booking`,
  deleteEventEnquiry: `${BASE_URL}/api/delete-enquire-list`,
  foundUserAds: `${BASE_URL}/api/classifieduserinformation`,
  sendEventBookingResponce: `${BASE_URL}/api/customer-booking-response`,
  createHandymanVenderDispute: `${BASE_URL}/api/trader-raise-dispute`,
  replyHandymanVenderDispute: `${BASE_URL}/api/create-trader-dispute-message`,
  submitHandymanVenderReviews: `${BASE_URL}/api/rate-job`,
  createCatererVenderDispute: `${BASE_URL}/api/trader-booking-dispute`,
  replyCatererVenderDispute: `${BASE_URL}/api/save-trader-dispute-msg-booking`,
  submitCatererVenderReviews: `${BASE_URL}/api/event-review`,
  listCustomerHandymanQuote: `${BASE_URL}/api/user-quotes-list`,
  listCustomerHandymanBookings: `${BASE_URL}/api/get-user-jobs`,
  listCustomerHandymanHistory: `${BASE_URL}/api/get-user-job-history`,
  deleteHistoryBooking: `${BASE_URL}/api/delete-job`,
  deleteCustomerHandymanHistoryBooking: `${BASE_URL}/api/get-user-jobs-soft-delete`,
  changeEventStatus: `${BASE_URL}/api/vendor-booking-response`,
  listCustomerHandymanEnquiryDetail: `${BASE_URL}/api/get-quote-detail`,
  listCustomerHandymanBookingsDetail: `${BASE_URL}/api/get-user-job-detail`,
  listCustomerHandymanHistoryDetail: `${BASE_URL}/api/get-history-detail`,
  changeStatusJobRequest: `${BASE_URL}/api/customer-update-job-status`,
  changeStatusQuoteRequest: `${BASE_URL}/api/quote-status-change`,
  raiseHandymanCustomerDispute: `${BASE_URL}/api/customer-raise-dispute`,
  raiseHandymanCustomerDisputeReply: `${BASE_URL}/api/create-customer-dispute-message`,
  acceptHandymanCustomerBooking: `${BASE_URL}/api/trader-checkout`,
  listCustomerBookingsCard: `${BASE_URL}/api/get-all-user-cards`,

  //handyman
  retailVoteAPI: `${BASE_URL}/api/add-retail-classified-reviews-votes`,
  traderVoteAPI: `${BASE_URL}/api/add-trader-rating-vote`,
  previousQuote: `${BASE_URL}/api/quote-req-list-for-temp`,
  requestQuoteCreate: `${BASE_URL}/api/request-quote-create`,
  getTraderMonthBookingAPI: `${BASE_URL}/api/get-trader-month-booking`,
  getTraderBookingSlots: `${BASE_URL}/api/get-trader-booking-slots`,
  getTraderQuote: `${BASE_URL}/api/trader-jobs-list-for-temp`,
  createTraderBooking: `${BASE_URL}/api/booking-create`,
  eventcatereCustomerEquiryList: `${BASE_URL}/api/customer-enquire-list`,
  eventcatererCustomerBookingList: `${BASE_URL}/api/customer-booking-list`,
  eventcatererCustomerHistoryList: `${BASE_URL}/api/customer-history-list`,
  eventcatereCustomerEquiryDetail: `${BASE_URL}/api/enquires`,
  eventjobCheckoutSuccess: `${BASE_URL}/api/job-checkout-success`,
  eventcatereCustomerBookingsDetail: `${BASE_URL}/api/bookings`,
  postBookingsDetail: `${BASE_URL}/api/bookings`,
  declinCustomerEventEnquiry: `${BASE_URL}/api/quote-response`,
  eventEnquiryCheckout: `${BASE_URL}/api/event-booking-checkout-api`,
  eventCalendarBookings: `${BASE_URL}/api/customer-event-calender-booking`,
  eventVenderCalendarBookings: `${BASE_URL}/api/get-vendor-month-event-bookings`,
  getTraderMonthBooking: `${BASE_URL}/api/get-trader-month-booking`,
  declineEnquiryByCustomer: `${BASE_URL}/api/customer-cancel-enquire`,
  getTraderjobIdByQuoteId: `${BASE_URL}/api/get-traderjobId-by-quoteId`,
  customerCancelQuoteRequest: `${BASE_URL}/api/customer-cancel-quote-request`,
  rescheduleEventBookingapi: `${BASE_URL}/api/reschedule-event-booking`,

  //m4
  retailAutocomplete: `${BASE_URL}/api/get_all_retailclassifiedswithname`,
  getRetailDetail: `${BASE_URL}/api/retail-classified-detail`,
  getRetailSearchByname: `${BASE_URL}/api/get_all_retailclassifiedssearchbyname`,
  addToCart: `${BASE_URL}/api/add_to_cart`,
  addretailReview: `${BASE_URL}/api/add_review`,
  getRetailAttribute: `${BASE_URL}/api/showallretailattributes`,
  userRetailOrder: `${BASE_URL}/api/orders`,
  updateRetailOrderStatus: `${BASE_URL}/api/update_order_status`,
  updateOrderStatus: `${BASE_URL}/api/update-order-status`,
  applyRetailFilter: `${BASE_URL}/api/get_all_retailclassifiedbyfilter`,
  cartDetail: `${BASE_URL}/api/cart`,
  placeOrder: `${BASE_URL}/api/place-orders-api`,
  saveForLater: `${BASE_URL}/api/addwishlistretail`,
  retailPostAd: `${BASE_URL}/api/retailaddclassified`,
  getMostPopularInRetail: `${BASE_URL}/api/most_popular_classifieds`,
  getSaveSearchList: `${BASE_URL}/api/retail_saved_searchlist`,
  deleteSavedSerch: `${BASE_URL}/api/delete_retail_savedsearch`,
  getRetailDashboardDetail: `${BASE_URL}/api/retailer-vendor-dashboard`,
  createRetailSearch: `${BASE_URL}/api/create_retail_savedsearch`,
  userPaymentRetail: `${BASE_URL}/api/user_payment_update`,
  myRetailDeals: `${BASE_URL}/api/get-vendor-daily-deals-retails`,
  getRetailDealFromAdmin: `${BASE_URL}/api/get-daily-deals-retails`,
  createRetailDeals: `${BASE_URL}/api/add-vendor-daily-deals-retails`,

  /* Food Scanner*/
  foodScannerProductAutocompleteList: `${BASE_URL}/api/foodproduct_list?product_name=`,
  foodScannerProductList: `${BASE_URL}/api/halalproduct_list`,
  addToFavoriteFoodProduct: `${BASE_URL}/api/product_add_remove_favorite`,
  foodProductList: `${BASE_URL}/api/foodproduct_list`,
  addProduct: `${BASE_URL}/api/food_product_user_save`,
  barcodeSearch: `${BASE_URL}/api/halalproduct_scan?product_code=`,
  foodScannerSearch: `${BASE_URL}/api/halalproduct_list`,

  /* Static Pages */
  static: `${BASE_URL}/api/get-static-pages-by-slug`,
  faqPage: `${BASE_URL}/api/get-all-faqs`,
  /**BK */
  vendorprofileFitnessDashboard: `${BASE_URL}/api/vendor-profile-fitness-dashboard`,
  listCustomerClasses: `${BASE_URL}/api/list-customer-classes`,
  getMyFitnessBookingCalender: `${BASE_URL}/api/fitness/get-my-fitness-booking-calender`,
  listCustomerHistoryClasses: `${BASE_URL}/api/list-customer-history`,
  getTraderMonthFitnessBooking: `${BASE_URL}/api/get-trader-month-fitness-booking`,
  rateTraderClass: `${BASE_URL}/api/rate-trader-class`,
  fitnessCustomerListAllSubscriptions: `${BASE_URL}/api/list-customer-all-subscriptions`,
  spaTraderServiceBookingDispute: `${BASE_URL}/api/trader-service-booking-dispute`,
  beautyTraderServiceBookingDispute: `${BASE_URL}/api/beauty/trader-service-booking-dispute`,
  showCustomerClass: `${BASE_URL}/api/show-customer-class`,
  getTransactionDetail: `${BASE_URL}/api/get-vendor-payment-received-formee`,
  getInvoiceDetail: `${BASE_URL}/api/get-vendor-payment-received-formee`,
  getGSTPercent: `${BASE_URL}/api/get-setting-parameters`,
  getRetailPostAdDetail: `${BASE_URL}/api/editretailclassified`,
  updatePostAdDetail: `${BASE_URL}/api/updateretailclassified`,
  getBrands: `${BASE_URL}/api/get-brand-by-category`,
  generateInvoice: `${BASE_URL}/api/generate-formee-invoice`,
  sendFormmeInvoice: `${BASE_URL}/api/send-formee-invoice`,
  getTraderMonthEventBooking: `${BASE_URL}/api/get-trader-month-event-booking`,
  getSavedClassified: `${BASE_URL}/api/allwishclassifiedlist`,
  updateRetailSavedSearch: `${BASE_URL}/api/update_retail_savedsearch`,
  customerDashboardDetails: `${BASE_URL}/api/customer-profile-dashboard`,
  userNotifications: `${BASE_URL}/api/user-notifications`,
  customerDashboardDetailsClassifiedOffers: `${BASE_URL}/api/user-classified-list-offer`,
  cancelSentOffers: `${BASE_URL}/api/sent-offer-delete`,
  updateSentOffers: `${BASE_URL}/api/update-offermessageapi-message`,
  cancelBookedInspection: `${BASE_URL}/api/cancel-booked-inspection`,

  // delete user notification
  deleteUserNotifications: `${BASE_URL}/api/delete-user-notifications`,

  // // delete user notification

  // Billing
  billingHistory: `${BASE_URL}/api/billing-history`,
  packageInvoiceDetail: `${BASE_URL}/api/packageInvoiceDetail`,
  // End Billing

  // update visibility status notification
  updateUserNotificationStatus: `${BASE_URL}/api/update-notification-status`,

  // // update visibility status user notification

  useWishList: `${BASE_URL}/api/get-wishlist-by-customer`,
  customerRaiseDispute: `${BASE_URL}/api/orders/submit_dispute`,
  customerRetailDispute: `${BASE_URL}/api/orders/seller_dispute_response`,
  uploadRetailImages: `${BASE_URL}/api/inv_attribute_group_img_upload`,
  retailDailyDeals: `${BASE_URL}/api/get-users-daily-deals-retails`,

  newClassifiedList: `${BASE_URL}/api/new-classified-list`,
  newTraderList: `${BASE_URL}/api/traders-list-new`,
  restaurantSuggestion: `${BASE_URL}/api/get-searched-menus`,
  fetchStripeSeceret: `${BASE_URL}/api/stripe-setup-intent`,
  newRetailList: `${BASE_URL}/api/new-classified-list-retail`,
  bookingAutocomplete: `${BASE_URL}/api/general-booking-autocomplete-search-new`,
  fetchStripeToken: `${BASE_URL}/api/get-stripe-card-token`,
  saveStripeCard: `${BASE_URL}/api/add-card-user`,
  reportAd: `${BASE_URL}/api/reportclassfiedadd`,
  likeOnReview: `${BASE_URL}/api/add-classified-reviews-votes`,
  bookingSearch: `${BASE_URL}/api/general-booking-autocomplete-search-new-search`,

  leaveFeedback: `${BASE_URL}/api/leave_retail_feedback`,
  voteRetailReview: `${BASE_URL}/api/add-retail-classified-reviews-votes`,
  reportRetailVendorReview: `${BASE_URL}/api/report-review`,
  reply_to_vendor_review: `${BASE_URL}/api/reply-review`,
  reportRetailAds: `${BASE_URL}/api/reportretailadd`,
  removeRetailCartItem: `${BASE_URL}/api/removeitem`,
  reportFeedback: `${BASE_URL}/api/report-feedback`,
  voteFeedback: `${BASE_URL}/api/add-retail-add-feedback-vote`,
  removeFromSaveForLater: `${BASE_URL}/api/removewishlistretail`,
  changeCartItemQuantity: `${BASE_URL}/api/change-cart-item-quantity`,
  deleteUserAddress: `${BASE_URL}/api/delete_user_address`,

  reportUserReview: `${BASE_URL}/api/report-classified-review`,

  voteTraderReview: `${BASE_URL}/api/add-trader-rating-vote`,
  replybookingReview: `${BASE_URL}/api/booking-reply-rating`,
  updateReplyRatingVendor: `${BASE_URL}/api/update-booking-reply-rating`,
  reportBookingReview: `${BASE_URL}/api/report-booking-rating`,
  commentDataVendorReview: `${BASE_URL}/api/get-booking-reply-rating`,
  adRetailToWishlist: `${BASE_URL}/api/addwishlistretail`,
  removeRetailToWishlist: `${BASE_URL}/api/removewishlistretail`,
  updateClassifiedReview: `${BASE_URL}/api/edit-classified-review`,
  updateRetailReview: `${BASE_URL}/api/edit-retail-review`,
  restaurantReviewList: `${BASE_URL}api/restaurant-reviews`,
  updateBookingReview: `${BASE_URL}/api/edit-booking-rating`,
  addBookingReviews: `${BASE_URL}/api/add-booking-rating`,
  getMySubscriptions: `${BASE_URL}/api/get-customer-subscriptions`,
  addSizeGuide: `${BASE_URL}/api/save-trader-sizeguide`,
  deleteSizeGuide: `${BASE_URL}/api/delete-trader-sizeguide`,
  removeRestaurantCartItem: `${BASE_URL}/api/remove-restaurant-cart-item`,
  reportTraderProfile: `${BASE_URL}/api/report-trader-user`,
  fexedPostageList: `${BASE_URL}/api/get-fedex-response`,
  getPriceValues: `${BASE_URL}/api/get-attribute-values`,
  // getRetailVendorOrderList:`${BASE_URL}/api/seller-orders`,
  getRetailVendorOrderList: `${BASE_URL}/api/seller-orders`,
  globalSearchAPI: `${BASE_URL}/api/globalsearch`,
  updateDefaultCard: `${BASE_URL}/api/update-default-card`,
  deleteUserCard: `${BASE_URL}/api/delete-user-card`,
  jobApplicationList: `${BASE_URL}/api/jobapplication-list`,
  userJobApplicationList: `${BASE_URL}/api/jobapplication-list-foruser`,
  jobApplicationDelete: `${BASE_URL}/api/jobapplication-delete`,
  getJobApplicantDetails: `${BASE_URL}/api/jobapplications/view`,

  //
  getOrderDetails: `${BASE_URL}/api/retail-order-detail`,
  deleteOfferListItem: `${BASE_URL}/api/offer-delete`,
  markAsSold: `${BASE_URL}/api/ad-change-status`,
  jobSubSubcategory: `${BASE_URL}/api/job-sub-sub-category`,

  //retail feedback api's
  sellerFeedbackList: `${BASE_URL}/api/retail-vendor-feedback-list`,
  replyFeedback: `${BASE_URL}/api/reply-feedback`,
  retailAdmanagement: `${BASE_URL}/api/retailaddmanagmenttab`,
  deleteRetailAds: `${BASE_URL}/api/frontretailclassifieddelete`,
  changeRetailStatus: `${BASE_URL}/api/frontretailclassifiedchangestatus`,
  deletePostAnAdUploadedImages: `${BASE_URL}/api/retailadd-delete-attachment`,
  deleteclassifiedofferreceived: `${BASE_URL}/api/deleteclassifiedofferreceived`,

  getCardDetails: `${BASE_URL}/api/get-user-card`,
  updateCardDetails: `${BASE_URL}/api/update-user-card`,
  classified_delete_image: `${BASE_URL}/api/front_delete_attachment`,
  sendOtp: `${BASE_URL}/api/send-otp`,
  verifyOtp: `${BASE_URL}/api/verify-otp`,
  foundRetailUserAds: `${BASE_URL}/api/retailclassifieduserinformation`,

  //flight api
  // getFlightAutocompleteAPI: `http://10.10.10.130:8888/api/locations`,
  // getFlightSearch: `http://10.10.10.130:8888/api/flights`,

  getFlightSearch: `${CLIENT_NODE_URL_TOURISM}/api/flights`,
  adPassengerDetails: `${CLIENT_NODE_URL_TOURISM}/api/add_multi_element`,
  getCarSearchData: `${CLIENT_NODE_URL_TOURISM}/api/car/car-availability`,
  flightAirSell: `${CLIENT_NODE_URL_TOURISM}/api/air_sell`,
  getPnrNumber: `${CLIENT_NODE_URL_TOURISM}/api/pnr_farePricePNRWithBookingClass`,
  getAvailabilityRates: `${CLIENT_NODE_URL_TOURISM}/api/car/car-availability-rates`,
  postDriverDetails: `${CLIENT_NODE_URL_TOURISM}/api/car/pnr-add-multi-element`,

  storeCarServiceRating: `${BASE_URL}/api/v1/tourism/store-car-service-ratings`,
  getMostRecommendedTour: `${BASE_URL}/api/v1/tourism/recommended-for-you`,
  tourismFlightBooking: `${BASE_URL}/api/flights/place-order`,
  tourismHotelBooking: `${BASE_URL}/api/hotels/hotel-bookings`,
  tourismHotelPaypalBooking: `${BASE_URL}/api/hotels/hotel-bookings-paypal`,
  tourismFlightBookingPaypal: `${BASE_URL}/api/flights/place-order-paypal`,
  getCarPopularDest: `${BASE_URL}/api/v1/tourism/get-popular-car-hire-destination`,
  topRentalcar: `${BASE_URL}/api/v1/tourism/get-top-rated-car`,
  storeSearchData: `${BASE_URL}/api/v1/tourism/store-searched-data`,
  checkIsCarFav: `${BASE_URL}/api/v1/tourism/is-car-favorite`,
  carMarkFav: `${BASE_URL}/api/v1/tourism/car-mark-favorite`,
  postCarReview: `${BASE_URL}/api/v1/tourism/store-car-service-ratings`,
  getCarViews: `${BASE_URL}/api/v1/tourism/get-car-views`,
  carsrecommendedForYou: `${BASE_URL}/api/v1/tourism/recommended-car`,
  addCarsView: `${BASE_URL}/api/v1/tourism/add-car-views`,
  getCarReviews: `${BASE_URL}/api/v1/tourism/get-average-rating`,
  tourismCarBookingAPI: `${BASE_URL}/api/cars/place-order`,
  tourismCarPaypalAPI: `${BASE_URL}/api/cars/place-order-paypal`,


  getServiceOptions: `${BASE_URL}/api/get-all-state-cities`,
  // restaurent booking success
  placeRestaurantoOrderSuccess: `${BASE_URL}/api/place-restaurant-order-success`,
  paymentDetailPackage: `${BASE_URL}/api/paymentDetailPackage`,
  paymentDetail: `${BASE_URL}/api/paymentDetail`,
  getPayment: `${BASE_URL}/api/save-payment`,
  paymentPaypal: `${BASE_URL}/api/paypal_payment`,

};
