import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Form, Input, Button, Typography, Row, Col } from 'antd';
import './newsletter.less';
import { ReactComponent as Instagram } from '../../assets/images/instagram.svg';
import { ReactComponent as Facebook } from '../../assets/images/facebook.svg';
import { ReactComponent as LinkedIn } from '../../assets/images/LinkedIn.svg';

const { Title, Text } = Typography;
const Newsletter = () => {
  return (
    <Fragment>
      <Row className='newsletter-box'>
        <Col xs={24} md={5} lg={4}>
          <Title level={4} className='text-normal'>Newsletter</Title>
          <Text>Stay up to date with our latest news releases.</Text></Col>
        <Col xs={0} lg={1}></Col>
        <Col xs={24} md={10} lg={9} className="">
          <Form layout='inline'>
            <Form.Item>
              <Input placeholder='Enter your email' />
            </Form.Item>
            <Form.Item>
              <Button type='primary'>Subscribe</Button>
            </Form.Item>
          </Form>
        </Col>
        <Col className="get-social align-right" xs={24} md={8} lg={10} >
          <div className='d-flex mobile-hide'>
            <Text > Get Social </Text>
            <div className='social-link'>
              <Link target="_blank" to={{ pathname: 'https://www.instagram.com/formee.app/?hl=en' }}>
                <Instagram className='icon' />
              </Link>
              <Link target="_blank" to={{ pathname: 'https://www.facebook.com/formeeapplication' }}>
                <Facebook className='icon' />
              </Link>
              <Link target="_blank" to={{ pathname: 'https://www.linkedin.com/company/formee-pty-ltd' }}>
                <LinkedIn className='icon' />
              </Link>
            </div>
          </div>

          <div className='app-link pc-hide'>
            <Text className='text-getapp mb-10'>Get the App</Text>
            <Link target="_blank" to={{ pathname: 'https://apps.apple.com/pk/app/formee/id1245080932' }} >
              <img className="mb-10" src={require('../../assets/images/app-store-dark.png')} alt='App Store' />
            </Link><br />
            <Link target="_blank" to={{ pathname: 'https://play.google.com/store/apps/details?id=com.pws.formee&hl=en_US' }} >
              <img className="mb-10" src={require('../../assets/images/google-play-dark.png')} alt='Google Play' />
            </Link>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

export default Newsletter;