import React from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import {
  closeForgotModel,
  controlMenuDropdown,
  getBusinessProfileAction,
  getCategoryListAction,
  getDashBoardDetails,
  getNotificationAction,
  getUserMenuAction,
  getUserProfileAction,
  loadingAction,
  logoutAction,
  openLoginModel,
  sidebarOpenClose,
  updateSearchOpenClose,
  updateNotificationStatusAction,
} from "../../../store/actions/index";
import moment from "moment";
import { Layout, Menu, Dropdown, Typography, Button, Drawer, Badge } from "antd";
import Icon from "../../../components/customIcons/customIcons";
// import "./header.less";
import "./headerNew.less";
import Login from "../../auth/login/index";
import YourMenu from "./YourMenu";
import AllMenuList from "./AllMenu";
import { converInUpperCase } from "../../common";
import { langs } from "../../../config/localization";
import { LockOutlined } from "@ant-design/icons";
import { ReactComponent as Logo } from "../../../assets/images/header-logo.svg";
import { ReactComponent as Search } from "../../../assets/images/header-search.svg";
import { ReactComponent as Hamburger } from "../../../assets/images/hamburger-icon.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/images/icons/calendar-icon2.svg";
import MobileSidebar from "./MobileSidebar";
import { dashboardPaths, getMainCategory, replaceImageURL } from "../../../common/Navigation";

const { Header } = Layout;
const { Text } = Typography;

let path = ["/", "/classifieds", "/bookings", "/retail", "/food-scanner"];

class AppHeader extends React.Component {
  constructor(props) {
    super(props);

    this.menuRef = React.createRef();

    let showSecure = false;
    let { pathname } = this.props.history.location;
    if (pathname === "/add-profile-payment-methods" || pathname === "/booking-checkout") showSecure = true;
    else showSecure = false;
    this.state = {
      insideDashboard: false,
      isHomePage: false,
      visible: false,
      yourMenu: false,
      isRedirect: false,
      isMenuOpen: false,
      isNotificationOpen: true,
      istest: 0,
      showSecure: showSecure,
      getNotificationData: [],
      mainHeaderClass: "main-header",
      isMenuClose: false,
    };
  }

  /**
   * @method componentWillMount
   * @description called before render the component
   */

  componentWillMount() {
    const { isLoggedIn, seller_type } = this.props;
    if (isLoggedIn && seller_type === langs.sellerType.private) {
      this.props.getUserProfileAction('');
    }
    isLoggedIn && this.props.getBusinessProfileAction('', () => { });
    this.props.getCategoryListAction("", (res) => { });
    isLoggedIn && seller_type === langs.sellerType.private && this.props.getUserMenuAction('', (res) => { });
    isLoggedIn && document.body.classList.add("seller_type_" + seller_type);
    isLoggedIn && document.body.classList.add(getMainCategory(seller_type));
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    document.addEventListener("click", this.handleClickOutside, true);

    this.props.sidebarOpenClose(false);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);

    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    const domNode = this.menuRef.current;

    let existsin = "";
    if (document.getElementsByClassName("all-menu")[0] && document.getElementsByClassName("all-menu")[0] !== undefined) existsin = document.getElementsByClassName("all-menu")[0].contains(event.target);

    let menuItem = document.getElementsByClassName("clicked-item")[0];

    if (event.target !== domNode && !existsin && event.target !== menuItem) {
      this.setState({
        isMenuClose: false,
      });
    }
  };

  componentDidUpdate(nextProps) {
    let pathName = this.props.location.pathname.split("/");
    pathName = '/' + pathName[1]
    if (dashboardPaths().includes(pathName) && !this.state.insideDashboard) {
      this.setState({ insideDashboard: true })
    } else {
      if (this.state.insideDashboard && !dashboardPaths().includes(pathName)) {
        this.setState({ insideDashboard: false })
      }
    }
    this.props.history.listen((location, action) => {
      this.setState({
        isMenuOpen: false,
        visible: false,
      });
      this.props.sidebarOpenClose(false);

      let pathName = this.props.history.location.pathname;

      if (pathName === ("/" || "")) {
        this.setState({ isHomePage: true });
      } else {
        this.setState({ isHomePage: false });
      }
    });
  }



  handleScroll = (event) => {
    let updates = {};
    let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    if (scrollTop > this.props.bannerHeight - 0) {
      updates["mainHeaderClass"] = "main-header sticky-header";
      document.body.classList.add("header-sticky");
    } else {
      updates["mainHeaderClass"] = "main-header";
      document.body.classList.remove("header-sticky");
    }
    updates["isMenuClose"] = false;
    this.setState(updates);
  };

  /**
   * @methodcomponentWillReceiveProps
   * @description receive props
   */
  componentWillReceiveProps(nextprops, prevProps) {
    let { pathname } = this.props.history.location;
    if (pathname === "/add-profile-payment-methods" || pathname === "/booking-checkout") this.setState({ showSecure: true });
    else this.setState({ showSecure: false });
  }

  /**
   * @method showModal
   * @description show model
   */
  showModal = () => {
    const { isLoggedIn } = this.props;
    !isLoggedIn && this.props.openLoginModel();
  };

  /**
   * @method showLimitedNotification
   * @description to genrate the popup for notification*/

  showLimitedNotification = () => {
    const { loggedInUser } = this.props;
    let isLimited = true;
    if (loggedInUser) {
      this.props.loadingAction(true);
      let params ={
        page: 1,
        per_page: 20,

      }
      this.props.getNotificationAction(params, (response) => {
        this.props.loadingAction(false);
        if (response.success) {
          if (response.data.data.length > 0) {
            this.setState({ isNotificationOpen: false });
          }
          let temArray = [];
          let notificationData = response.data.data;
          notificationData.map((value, i) => {
            let objectArray = {
              key: i,
              id: value.id,
              sender_id: value.sender_id,
              receiver_id: value.receiver_id,
              subject: value.subject,
              devicename: value.devicename,
              massage: value.massage,
              deviceid: value.deviceid,
              classified_id: value.classified_id,
              offer_price: value.offer_price,
              created_at: value.created_at,
              updated_at: value.updated_at,
              notifiable_id: value.notifiable_id,
              notifiable_type: value.notifiable_type,
              destination_url: value.destination_url,
              user_id: value.user_id,
              limit: 5,
              name: value.name,
              is_sellable: value.is_sellable,
              is_visible: value.is_visible,
              user_image: value.user_image,
            };
            temArray.push(objectArray);
          });
          let resData = this.sortData(temArray, this.state.sortType);

          this.setState({ notificationListData: resData });
          this.setState({ getNotificationData: resData });
        }
      });
    }
  };

  updateNotificationStatus = (notification_id) => {
    const { isLoggedIn } = this.props;
    if (isLoggedIn) {
      this.props.loadingAction(true);
      this.props.updateNotificationStatusAction(notification_id, (response) => {
        this.props.loadingAction(false);

        if (response.success) {
          this.showLimitedNotification();
        }
      });
    }
  };

  sortData = (data, sortOption) => {
    if (sortOption === "Newest") {
      //Newest
      data.sort(function (a, b) {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      });
      return data;
    } else {
      //Oldest
      data.sort(function (a, b) {
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
      });
      return data;
    }
  };

  /**
   * @method showYourMenu
   * @description show showYourMenu
   */
  showYourMenu = () => {
    this.setState({
      yourMenu: true,
    });
  };

  /**
   * @method navigateUser
   * @description nevigate user
   */
  navigateUser = () => {
    this.props.history.push('/profile')
  };

  /**
   * @method handleCancel
   * @description close model
   */
  handleCancel = (e) => {
    this.setState({
      yourMenu: false,
    });
  };

  logout = async () => {
    this.props.logoutAction();
    toastr.success(langs.success, langs.messages.logout_success);
    window.location.assign("/");
  };


  /**
   * @method handleMenuChange
   * @description handle menu change
   */
  handleMenuChange = (event) => {
    this.setState({ isMenuOpen: event });
    this.props.controlMenuDropdown(event);
  };

  handleClickOfNotification = (notificationId) => {
    this.updateNotificationStatus(notificationId);
  };

  handleSearchOpenClose = (event) => {
    this.props.updateSearchOpenClose(event);
  };

  onClickCloseMenu = () => {
    this.setState({ isMenuClose: false });
  };

  /**
   * @method render
   * @description render component
   */
  render() {
    const cmenu = (
      <Menu className='all-menu'>
        <AllMenuList onClickCloseMenu={this.onClickCloseMenu} />
      </Menu>
    );
    const { yourMenu, isRedirect, showSecure } = this.state;
    let filterNotification = this.state.getNotificationData.find((val) => {
      return val.is_visible !== 1;
    });
    const { isLoggedIn, userProfile, isOpenLoginModel, seller_type, isPrivateUser } = this.props;

    //Condition for menu

    let loggedInAsPrivateuser = isLoggedIn && isPrivateUser;
    let headerStyle = !isLoggedIn && path.includes(this.props.location.pathname) ? `header-wrap header-wrap-landing` : isLoggedIn && isPrivateUser && path.includes(this.props.location.pathname) ? "header-wrap header-wrap-landing" : `header-wrap`;

    if (isLoggedIn && this.state.istest === 0) {
      this.showLimitedNotification();
      this.setState({ istest: 1 });
    }
    if (isRedirect) { return (<Redirect push to={{ pathname: "/myProfile" }} />); }
    const showDrawer = () => {
      this.setState({
        visible: !this.state.visible,
        isMenuClose: false,
      });
    };


    const menu = (
      <Menu>
        <Menu.Item key='1'>
          <a href='javascript:void(0)' onClick={() => this.navigateUser()}>
            View Account
          </a>
        </Menu.Item>
        <Menu.Item key='2'>
          <a href='javascript:void(0)' onClick={() => this.logout()}>
            Logout
          </a>
        </Menu.Item>
      </Menu>
    );

    const menuNotification = () => (
      <Menu className='notifocation-outer'>
        {this.state.notificationListData.map((notificationData) => (
          <Menu.Item key='notification-data' onClick={(event) => (notificationData.is_visible === false ? this.updateNotificationStatus(notificationData.id) : null)} className={notificationData.is_visible === true ? "table-row-unbold " : "table-row-bold"}>
            <span className='notification-img'>
              <img src={notificationData.user_image} alt="" />
            </span>
            <div className='notification-data'>
              <h3>{notificationData.massage}</h3>
              <span>{moment(notificationData.updated_at).format("DD/MM/YYYY HH:mm A")}</span>
            </div>
          </Menu.Item>
        ))}

        <span className='all-notification'>
          <Link to='/notifications' title='Cart'>
            View All Notification
          </Link>
        </span>
      </Menu>
    );
    return (
      <>
        <Header
          className={`${(this.state.isHomePage === false) ? `${this.state.mainHeaderClass} innerPage` : `${this.state.mainHeaderClass}  homePage`}
          ${isLoggedIn && this.state.insideDashboard === true && seller_type === langs.sellerType.private ? "isLoggedIn privateUser" : ""}
          ${isLoggedIn && this.state.insideDashboard === true && seller_type !== langs.sellerType.private ? "isLoggedIn" : ""}
          `}>
          <div className={headerStyle}>
            <div className='header-left'>
              <div className='logo'>
                <Link to='/' title='Formee'>
                  <Logo />
                </Link>
              </div>
              <div className='header-search-wrap'>
                <div className='mobile-search'>
                  <Button
                    type='primary'
                    icon={<Search />}
                    onClick={() => {
                      this.handleSearchOpenClose(!this.props.searchOpenCloseUpdate);
                    }}
                  />
                </div>
                {!showSecure && (!isLoggedIn || isPrivateUser) && (
                  <Menu mode='inline' ref={this.menuRef}>
                    <Menu.Item key='1' title='Main Menu'>
                      <Dropdown
                        overlay={cmenu}
                        overlayClassName={`main-menu-dropdown header-Dropdown ${this.state.isMenuClose ? "active" : ""}`}
                        onOpenChange={this.handleMenuChange}
                        open={this.state.isMenuClose}
                        // trigger={['hover']}
                        className='clicked-item'
                      >
                        <Link
                          to='/'
                          className='ant-dropdown-link'
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ isMenuClose: !this.state.isMenuClose, visible: false });
                          }}
                        >
                          <img src={require("../../../assets/images/menu-icon.svg")} alt='Menu Icon' />
                        </Link>
                      </Dropdown>
                    </Menu.Item>
                    {isLoggedIn && (
                      <Menu.Item key='2' onClick={this.showYourMenu}>
                        <span>
                          <span className='border-link'>My Menu</span>
                        </span>
                      </Menu.Item>
                    )}
                  </Menu>
                )}
              </div>
            </div>
            <div className='header-right header-profile-right'>
              {!showSecure && (
                <Menu mode='inline'>
                  {!loggedInAsPrivateuser && isLoggedIn && seller_type != 'retail' ? (
                    <Menu.Item key='calendar'>
                      <Link to='/calendar' title='Calendar'>
                        <CalendarIcon />
                      </Link>
                    </Menu.Item>
                  ) : ""}
                  {loggedInAsPrivateuser && isLoggedIn ? (
                    <Menu.Item key='wishlist'>
                      <Link to='/wishlist' title='Wishlist'>
                        <Badge count={this.props.wishlist_count} overflowCount={this.props.wishlist_count > 9 ? 9 : this.props.wishlist_count}>
                          <Icon icon='wishlist' size='20' />
                        </Badge>
                      </Link>
                    </Menu.Item>
                  ) : !isLoggedIn ? (
                    <Menu.Item key='wishlist' onClick={() => this.props.openLoginModel()}>
                      <Icon icon='wishlist' size='20' />
                    </Menu.Item>
                  ) : ''}
                  {loggedInAsPrivateuser && isLoggedIn && (
                    <Menu.Item key='bookings'>
                      <Link to='/booking-list' title='Bookings'>
                        <Badge count={this.props.booking_count} overflowCount={this.props.booking_count > 9 ? 9 : this.props.booking_count}>
                          <Icon icon='bookings-ticket' size='22' />
                        </Badge>
                      </Link>
                    </Menu.Item>
                  )}
                  {loggedInAsPrivateuser && isLoggedIn ? (
                    <Menu.Item key='cart'>
                      <Link to='/cart' title='Cart'>
                        <Badge count={this.props.cart_count} overflowCount={this.props.cart_count > 9 ? 9 : this.props.cart_count}>
                          <Icon icon='cart' size='20' />
                        </Badge>
                      </Link>
                    </Menu.Item>
                  ) : !isLoggedIn ? (
                    <Menu.Item key='cart' onClick={() => this.props.openLoginModel()}>
                      <Icon icon='cart' size='20' />
                    </Menu.Item>
                  ) : ''}
                  {isLoggedIn && (
                    <Menu.Item key='notification'>
                      <Dropdown overlay={menuNotification} disabled={this.state.isNotificationOpen} trigger={["click"]}>
                        <Badge count={this.props.notification_count} overflowCount={this.props.notification_count > 9 ? 9 : this.props.notification_count}>
                          <svg width='18' height='22' viewBox='0 0 18 22' fill='none' xmlns='http://www.w3.org/2000/svg' className={filterNotification ? "notification-top" : ""}>
                            <path fillRule='evenodd' clipRule='evenodd' d='M10.9307 18.8672H6.61816C6.61816 19.0923 6.65274 19.3096 6.71694 19.5141C6.85333 19.9483 7.12343 20.3241 7.47987 20.5922C7.83964 20.8627 8.28739 21.0234 8.77441 21.0234C9.25705 21.0234 9.70398 20.8627 10.0643 20.5922C10.4213 20.3241 10.6934 19.9483 10.8309 19.5141C10.8957 19.3096 10.9307 19.0923 10.9307 18.8672ZM17.3994 17.7891V16.7109L15.2432 14.5547V9.16406C15.2432 5.85422 13.475 3.08344 10.3916 2.35031V1.61719C10.3916 0.722344 9.66926 0 8.77441 0C7.87957 0 7.15723 0.722344 7.15723 1.61719V2.35031C4.06301 3.08344 2.30566 5.84344 2.30566 9.16406V14.5547L0.149414 16.7109V17.7891H17.3994ZM3.23581 16.0641H14.313L13.5182 15.2692V9.16406C13.5182 6.45733 12.123 4.53507 9.99259 4.02853L8.77654 3.7394L7.55493 4.02884C5.41605 4.53561 4.03066 6.44502 4.03066 9.16406V15.2692L3.23581 16.0641Z' fill='#90A8BE' />
                          </svg>
                        </Badge>
                      </Dropdown>

                      {this.state.notificationListData &&
                        this.state.notificationListData.length > 0 &&
                        this.state.notificationListData.map((val) => {
                          if (val.is_visible === 0) {
                            return <span id='dot-noti'></span>;
                          }
                        })}
                    </Menu.Item>
                  )}
                  {isLoggedIn && isPrivateUser ? (
                    <Menu.Item key='post-ad'>
                      <Link to='/post-ad' title='Post an Ad'>
                        <svg className='ad-svg' width='23' height='22' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M0.75 0C0.335786 0 0 0.335786 0 0.75V17.75C0 18.1642 0.335786 18.5 0.75 18.5H13C13 18.5 12.6875 18.25 12.6875 17.75C12.6875 17.25 13 17 13 17H1.5V1.5H18V12C18 12 18.25 11.625 18.75 11.625C19.25 11.625 19.5 12 19.5 12V0.75C19.5 0.335786 19.1642 0 18.75 0H0.75ZM10.2144 12.751H8.79932L8.23682 11.2876H5.66162L5.12988 12.751H3.75L6.25928 6.30859H7.63477L10.2144 12.751ZM7.81934 10.2021L6.93164 7.81152L6.06152 10.2021H7.81934ZM15.1802 12.751H14.0332V12.0654C13.8428 12.332 13.6172 12.5312 13.3564 12.6631C13.0986 12.792 12.8379 12.8564 12.5742 12.8564C12.0381 12.8564 11.5781 12.6411 11.1943 12.2104C10.8135 11.7769 10.623 11.1733 10.623 10.3999C10.623 9.60889 10.8091 9.0083 11.1812 8.59814C11.5532 8.18506 12.0234 7.97852 12.5918 7.97852C13.1133 7.97852 13.5645 8.19531 13.9453 8.62891V6.30859H15.1802V12.751ZM11.8843 10.3164C11.8843 10.8145 11.9531 11.1748 12.0908 11.3975C12.29 11.7197 12.5684 11.8809 12.9258 11.8809C13.21 11.8809 13.4517 11.7607 13.6509 11.5205C13.8501 11.2773 13.9497 10.9155 13.9497 10.4351C13.9497 9.89893 13.853 9.51367 13.6597 9.2793C13.4663 9.04199 13.2188 8.92334 12.917 8.92334C12.624 8.92334 12.3779 9.04053 12.1787 9.2749C11.9824 9.50635 11.8843 9.85352 11.8843 10.3164ZM19.5 14.3281C19.5 13.9139 19.1642 13.5781 18.75 13.5781C18.3358 13.5781 18 13.9139 18 14.3281V17H15.75C15.3358 17 15 17.3358 15 17.75C15 18.1642 15.3358 18.5 15.75 18.5H18V20.7507C18 21.1649 18.3358 21.5007 18.75 21.5007C19.1642 21.5007 19.5 21.1649 19.5 20.7507V18.5H21.75C22.1642 18.5 22.5 18.1642 22.5 17.75C22.5 17.3358 22.1642 17 21.75 17H19.5V14.3281Z'
                            fill='#90A8BE'
                          />
                        </svg>
                      </Link>
                    </Menu.Item>
                  ) : !isLoggedIn ? (
                    <Menu.Item key='post-ad' onClick={() => this.props.openLoginModel()}>
                      <svg className='ad-svg' width='23' height='22' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M0.75 0C0.335786 0 0 0.335786 0 0.75V17.75C0 18.1642 0.335786 18.5 0.75 18.5H13C13 18.5 12.6875 18.25 12.6875 17.75C12.6875 17.25 13 17 13 17H1.5V1.5H18V12C18 12 18.25 11.625 18.75 11.625C19.25 11.625 19.5 12 19.5 12V0.75C19.5 0.335786 19.1642 0 18.75 0H0.75ZM10.2144 12.751H8.79932L8.23682 11.2876H5.66162L5.12988 12.751H3.75L6.25928 6.30859H7.63477L10.2144 12.751ZM7.81934 10.2021L6.93164 7.81152L6.06152 10.2021H7.81934ZM15.1802 12.751H14.0332V12.0654C13.8428 12.332 13.6172 12.5312 13.3564 12.6631C13.0986 12.792 12.8379 12.8564 12.5742 12.8564C12.0381 12.8564 11.5781 12.6411 11.1943 12.2104C10.8135 11.7769 10.623 11.1733 10.623 10.3999C10.623 9.60889 10.8091 9.0083 11.1812 8.59814C11.5532 8.18506 12.0234 7.97852 12.5918 7.97852C13.1133 7.97852 13.5645 8.19531 13.9453 8.62891V6.30859H15.1802V12.751ZM11.8843 10.3164C11.8843 10.8145 11.9531 11.1748 12.0908 11.3975C12.29 11.7197 12.5684 11.8809 12.9258 11.8809C13.21 11.8809 13.4517 11.7607 13.6509 11.5205C13.8501 11.2773 13.9497 10.9155 13.9497 10.4351C13.9497 9.89893 13.853 9.51367 13.6597 9.2793C13.4663 9.04199 13.2188 8.92334 12.917 8.92334C12.624 8.92334 12.3779 9.04053 12.1787 9.2749C11.9824 9.50635 11.8843 9.85352 11.8843 10.3164ZM19.5 14.3281C19.5 13.9139 19.1642 13.5781 18.75 13.5781C18.3358 13.5781 18 13.9139 18 14.3281V17H15.75C15.3358 17 15 17.3358 15 17.75C15 18.1642 15.3358 18.5 15.75 18.5H18V20.7507C18 21.1649 18.3358 21.5007 18.75 21.5007C19.1642 21.5007 19.5 21.1649 19.5 20.7507V18.5H21.75C22.1642 18.5 22.5 18.1642 22.5 17.75C22.5 17.3358 22.1642 17 21.75 17H19.5V14.3281Z'
                          fill='#90A8BE'
                        />
                      </svg>
                    </Menu.Item>
                  ) : ""}

                  {!isLoggedIn ? (
                    <Menu.Item
                      key='user-dropdown'
                      className='user-link'
                      onClick={() => {
                        this.props.openLoginModel();
                      }}
                    >
                      <Icon icon='user' />
                      {isLoggedIn === false && <Text>Log in</Text>}
                      { }
                    </Menu.Item>
                  ) : isPrivateUser ? (
                    <Menu.Item
                      key='user-dropdown'
                      className={!isPrivateUser ? `user-link selected ` : "user-link"}                      
                    >
                      <Dropdown overlayClassName='header-custom-dropdown' overlay={menu} placement='bottom' arrow>
                        <div className='userIcon'>
                          <div overlayClassName='header-custom-dropdown' overlay={menu} placement='bottomCenter' arrow
                          >
                            <span className='user-icon mr-5'>
                              <img src={replaceImageURL(userProfile?.image, 30, 30)} icon='user' size='20' />
                            </span>
                          </div>
                          <Text>{userProfile && `${converInUpperCase(userProfile.name)}`}</Text>
                        </div>
                      </Dropdown>
                    </Menu.Item>
                  ) : (
                    <Menu.Item key='user-dropdown' className={!isPrivateUser ? `user-link selected` : "user-link"}>
                      <Dropdown overlayClassName='user-dropdown header-custom-dropdown' overlay={menu} arrow placement='bottom'>
                        <div className=' userIcon'>
                          <span className='user-icon mr-5'>
                            <img src={replaceImageURL(userProfile?.image, 30, 30)} icon='user' size='20' />
                          </span>
                          <Text>{userProfile && `${converInUpperCase(userProfile.name)}`}</Text>
                        </div>
                      </Dropdown>
                    </Menu.Item>
                  )}
                </Menu>
              )}
              {showSecure && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LockOutlined />
                  <span style={{ marginLeft: "10px" }}>Safe and secure checkout</span>
                </div>
              )}
              <div className='mobile-menu menuMobile'>
                <Button type='primary' onClick={showDrawer}>
                  <Hamburger />
                </Button>
                <Drawer className='header-main-menu' placement='right' width={250} onClose={showDrawer} open={this.state.visible}>
                  <MobileSidebar showSecure={showSecure} />
                </Drawer>
              </div>
            </div>
          </div>
          {isOpenLoginModel && (
            <Login
              visible={isOpenLoginModel}
              onCancel={() => {
                this.props.closeForgotModel();
                this.props.openLoginModel();
              }}
            />
          )}
          {yourMenu && <YourMenu visible={yourMenu} onCancel={this.handleCancel} />}
        </Header>
        <div className={`overlay ${this.state.isMenuClose || this.props.sideMenuOpenClose ? "open" : ""}`}></div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const { auth: { isLoggedIn, loggedInUser, wishlist_count, cart_count, booking_count, notification_count },
    profile: { userProfile, businessProfile }, general: { categoryList },
    common: { isOpenLoginModel, bannerHeight, searchOpenCloseUpdate },
    newComm: { sideMenuOpenClose } } = store;
  let seller_type = loggedInUser?.seller_type ?? "";
  return {
    isLoggedIn, loggedInUser, wishlist_count, cart_count, booking_count, notification_count,
    seller_type,
    isPrivateUser: seller_type === langs.sellerType.private,
    isOpenLoginModel,
    userProfile: userProfile ? userProfile : businessProfile,
    bannerHeight,
    searchOpenCloseUpdate,
    sideMenuOpenClose,
  };
};

export default connect(mapStateToProps, {
  closeForgotModel,
  controlMenuDropdown,
  getBusinessProfileAction,
  getCategoryListAction,
  getDashBoardDetails,
  getNotificationAction,
  getUserMenuAction,
  getUserProfileAction,
  loadingAction,
  logoutAction,
  openLoginModel,
  sidebarOpenClose,
  updateSearchOpenClose,
  updateNotificationStatusAction,
})(withRouter(AppHeader));
