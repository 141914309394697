import { GET_USER_PROFILE, GET_BUSINESS_PROFILE } from '../constants';

const INITIAL_STATE = {
    userProfile: null,
    businessProfile: null
};

const getUserProfile = (state, action) => ({
    ...state,
    userProfile: action.payload
});

const getBusinessProfile = (state, action) => ({
    ...state,
    businessProfile: action.payload.data.data
});
function userTypeReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_USER_PROFILE: {
            return getUserProfile(state, action);
        }
        case GET_BUSINESS_PROFILE: {
            return getBusinessProfile(state, action);
        }
        default:
            return state;
    }
}

export default userTypeReducer;