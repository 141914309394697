import axios from 'axios';
import { BUSINESS_SERVICES_API, DAILY_DEALS_API, FITNESS_CLASSESS_API, MENU_ITEMS_API, PACKAGES_API, PROMOTIONS_API, SERVICE_CATEGORIES_API, SPECIAL_OFFERS_API } from '../../../api';
import { API } from '../../../config/Config';
import { apiErrors } from '../../../config/HandleAPIErrors';
import { SET_PACKAGES, SET_MY_PROMO, SET_MY_OFFERS, SET_SPECIAL_OFFERS, SET_PROMOTIONS, SET_MY_DEALS, SET_DAILY_DEALS } from '../../constants';

require('dotenv').config();

/**
 * @method getBeautyServices
 * @description get all beauty services
 */
export function getBeautyServices(id, callback) {
    const request = axios.get(`${API.getBeautyService}/${id}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method getServiceCategoryDetailAction
 * @description get all spa services
 */
export function getServiceCategoryDetailAction(id, callback) {
    const request = axios.get(`${SERVICE_CATEGORIES_API}/${id}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method changeServiceCategoryDetailAction
 * @description change all spa services
 */
export function changeServiceCategoryDetailAction({ service_id, ...params }, callback) {
    const request = axios.get(`${SERVICE_CATEGORIES_API}/${service_id}/edit`, { params: params })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method getServiceCategoriesAction
 * @description get all spa services
 */
export function getServiceCategoriesAction(params, callback) {
    const request = axios.get(`${SERVICE_CATEGORIES_API}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 *  delete service category
 */
export function deleteServiceCategoryAction(service_category_id, callback) {
    const request = axios.delete(`${SERVICE_CATEGORIES_API}/${service_category_id}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
            // getServiceCategoriesAction()
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

// Delete Classes
export function deleteFitnessClassAction(class_id, callback) {
    const request = axios.delete(`${FITNESS_CLASSESS_API}/${class_id}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method updateServiceCategory
 * @description update spa services
 */
export function updateServiceCategory(data, callback) {
    const request = axios.post(`${API.updateServiceCategory}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}
/**
 * @method removeServiceCategory
 * @description update spa services
 */
export function removeServiceCategory(data, callback) {
    const request = axios.delete(`${API.removeServiceCategory}/${data}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method changeBusinessServiceAction
 * @description get restaurant details
 */
export function changeBusinessServiceAction({ service_id, ...data }, callback) {
    const request = axios.get(`${BUSINESS_SERVICES_API}/${service_id}/edit`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            // apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method deleteBusinessServiceAction
 * @description get restaurant details
 */
export function deleteBusinessServiceAction(service_id, callback) {
    const request = axios.delete(`${BUSINESS_SERVICES_API}/${service_id}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            // apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method removeService
 * @description update spa services
 */
export function removeService(data, callback) {
    const request = axios.delete(`${API.createSpaService}/${data}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method updateServiceCategoryAction
 * @description update spa services
 */
export function updateServiceCategoryAction({ category_id, ...data }, callback) {
    const request = axios.post(`${SERVICE_CATEGORIES_API}/${category_id}?_method=PUT`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * createServiceCategoriesAction
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function createServiceCategoriesAction(data, callback) {
    const request = axios.post(`${SERVICE_CATEGORIES_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method createServicesAction
 * @description create spa services 
 */
export function createServicesAction(data, callback) {
    const request = axios.post(`${BUSINESS_SERVICES_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method getBeautyServices
 * @description get all beauty services
 */
export function deleteServices(id, callback) {
    const request = axios.delete(`${API.deleteBeautyService}/${id}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method editServices
 * @description edit services
 */
export function editServices(data, callback) {
    const request = axios.post(`${API.deleteBeautyService}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method getMenuItemsDetailById
 * @description get menu items detail by id
 */
export function getMenuItemsDetailById(id, callback) {
    const request = axios.get(`${API.getMenuItemById}/${id}/edit`)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method deleteMenuItemAction
 * @description delete restaurant menu item
 */
export function deleteMenuItemAction(item_id, callback) {
    const request = axios.delete(`${MENU_ITEMS_API}/${item_id}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method updateRestaurantMenu
 * @description edit restaurant menu item
 */
export function updateRestaurantMenu(data, callback) {
    const request = axios.post(`${API.updateMenuItem}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method updateRestaurantMenuCategory
 * @description edit restaurant menu category
 */
export function updateRestaurantMenuCategory(data, callback) {
    const request = axios.post(`${API.updateMenuCategory}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method activateRestaurantMenuCategory
 * @description edit restaurant menu category
 */
export function activateRestaurantMenuCategory(data, callback) {
    const request = axios.post(`${API.activateMenuCategory}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method activateAndDeactivateRestaurant
 * @description activate deactivate restaurant
 */
export function activateAndDeactivateRestaurant(data, callback) {
    const request = axios.post(`${API.activeInactiveRestaurant}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method activateAndDeactivateService
 * @description activate deactivate restaurant
 */
export function activateAndDeactivateService(data, callback) {
    const request = axios.post(`${API.activeDeactiveService}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method getDailyDealsAction
 * @description get a deal from Admin
 */
export function getDailyDealsAction() {
    const request = axios.get(`${DAILY_DEALS_API}`)
    return (dispatch) => {
        request.then((res) => {
            // callback(res)
            dispatch({
                type: SET_DAILY_DEALS,
                payload: res.data
            })
        })
            .catch(function (error) {
                // callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method createDailyDealsAction
 * @description get a deal from Admin
 */
export function createDailyDealsAction(data, callback) {
    const request = axios.post(`${DAILY_DEALS_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method changeDailyDealStatusAction
 * @description change ststua of deal
 */
export function changeDailyDealStatusAction({ deal_id, ...data }, callback) {
    const request = axios.get(`${DAILY_DEALS_API}/${deal_id}/edit`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}


/**
 * @method changeSpecialOfferStatusAction
 * @description change ststua of deal
 */
export function changeSpecialOfferStatusAction({ offer_id, ...data }, callback) {
    const request = axios.get(`${SPECIAL_OFFERS_API}/${offer_id}/edit`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method togglePromotionsActions
 * @description change status of promo
 */
export function togglePromotionsActions({ promotion_id, ...data }, callback) {
    const request = axios.get(`${PROMOTIONS_API}/${promotion_id}/edit`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}


/**
 * @method changePackageStatusAction
 * @description change status of promo
 */
export function changePackageStatusAction({ package_id, ...data }, callback) {
    const request = axios.get(`${PACKAGES_API}/${package_id}/edit`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method createPackageAction
 * @description craete a deal from business
 */
export function createPackageAction(data, callback) {
    const request = axios.post(`${PACKAGES_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method getMyDeals
 * @description get a deal from Admin
 */
export function getMyDeals(data) {
    const request = axios.post(`${API.myDeals}`, data)
    return (dispatch) => {
        request.then((res) => {
            // callback(res)
            dispatch({
                type: SET_MY_DEALS,
                payload: res.data
            })
        })
            .catch(function (error) {

                // callback(error)
                // apiErrors(error)
            });
    }
}

/**
 * @method getPackagesAction
 * @description get a deal from Admin
 */
export function getPackagesAction() {
    const request = axios.get(`${PACKAGES_API}`)
    return (dispatch) => {
        request.then((res) => {
            // callback(res)
            dispatch({
                type: SET_PACKAGES,
                payload: res.data
            })
        })
            .catch(function (error) {
                // callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method getPromotionsAction
 * @description get a deal from Admin
 */
export function getPromotionsAction(data) {
    const request = axios.get(`${PROMOTIONS_API}`)
    return (dispatch) => {
        request.then((res) => {
            dispatch({
                type: SET_PROMOTIONS,
                payload: res.data
            })
        })
            .catch(function (error) {
                // callback(error)
                // apiErrors(error)
            });
    }
}

/**
 * @method getMyOffers
 * @description get a deal from Admin
 */
export function getMyOffers(data) {
    const request = axios.post(`${API.myOffers}`, data)
    return (dispatch) => {
        request.then((res) => {

            // callback(res)
            dispatch({
                type: SET_MY_OFFERS,
                payload: res.data
            })
        })
            .catch(function (error) {

                // callback(error)
                // apiErrors(error)
            });
    }
}

/**
 * @method getSpecialOffersAction
 * @description get a promo from Admin
 */
export function getSpecialOffersAction(data) {
    const request = axios.get(`${SPECIAL_OFFERS_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            dispatch({
                type: SET_SPECIAL_OFFERS,
                payload: res.data
            })
        })
            .catch(function (error) {
                apiErrors(error)
            });
    }
}

/**
 * @method createPromotionAction
 * @description create a deal from business
 */
export function createPromotionAction(data, callback) {
    const request = axios.post(`${PROMOTIONS_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method createSpecialOfferAction
 * @description create a offer from business
 */
export function createSpecialOfferAction(data, callback) {
    const request = axios.post(`${SPECIAL_OFFERS_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

