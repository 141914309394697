import axios from "../../../api/axios";
import { apiErrors } from "../../../config/HandleAPIErrors";
import { FLIGHT_SEARCH_API, CHECK_FLIGHT_AVAILABILITY_API } from "../../../api";
import { SET_FLIGHT_RECORD } from '../../constants'



/**
 * @method flightSearchAction
 * @descriptionhandle get flight search records
 */
export function flightSearchAction(data, callback) {
    const request = axios.post(`${FLIGHT_SEARCH_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
            dispatch({
                type: SET_FLIGHT_RECORD,
                payload: res.data
            })
        }).catch(function (error) {
            apiErrors(error)
            callback(error);
        });
    }
}

/**
* @method flightSellAction
* @descriptionhandle check flight available or not
*/
export function flightSellAction(data, callback) {
    const request = axios.post(`${CHECK_FLIGHT_AVAILABILITY_API}`, data)
    return (dispatch) => {
        request.then((res) => {

            callback(res);
        }).catch(function (error) {

            callback(error);
            apiErrors(error)
        });
    }
}