import {
    GET_ITEM_DETAILS,
    GET_ITEM_DETAILS_SUCCESS,
    GET_ITEM_DETAILS_ERROR,
} from "../constants";

export function getItemDetailsAction(session, resolve, reject) {
    return {
        type: GET_ITEM_DETAILS,
        payload: session,
        resolve,
        reject
    };
}

export function getItemDetailsSuccess(data) {
    return {
        type: GET_ITEM_DETAILS_SUCCESS,
        payload: data
    };
}

export function getItemDetailsError(error) {
    return {
        type: GET_ITEM_DETAILS_ERROR,
        payload: error
    };
}