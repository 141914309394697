import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Typography } from 'antd';
import { langs } from '../../../config/localization';
import { getBookingCatLandingRoute, getCategoryListingRoutes } from '../../../common/getRoutes'
import { controlMenuDropdown, } from '../../../store/actions/index';
import '../header/header.less';
import { withRouter } from 'react-router'
import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon.svg';

const { Title, } = Typography;

class AllMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bookingList: [],
            retailList: [],
            menuList: [],
            classifiedList: [],
            isEmpty: false,
            redirectTo: null,
            retailOpen: false,
            bookingOpen: false,
            classifiedOpen: false,
            foodScannerOpen: false,
        };
    }

    menuClose = (e) => {
        this.setState({
            visible: false
        });
    };

    /**
    * @method renderSubcategory
    * @description render subcategory based on category type
    */
    renderSubcategory = (categoryType, key) => {
        if (categoryType && categoryType !== undefined) {
            return categoryType.map((data, i) => {
                return (
                    <li key={data.id} onClick={() => {
                        this.props.onClickCloseMenu();
                        if (key === langs.sellerType.retail) {
                            let path = getCategoryListingRoutes(key, data.name, 1, data.id)
                            this.props.history.push(path)
                        } else if (key === langs.modules.booking) {
                            let path = getCategoryListingRoutes(key, data.name, 1, data.id)
                            if ([53, 55].includes(data.id))
                                path = getBookingCatLandingRoute(data.name, data.id, data.name)
                            this.props.history.push(path)
                        } else {
                            let path = getCategoryListingRoutes(key, data.name, 1, data.id)
                            if ([122, 137].includes(data.id))
                                path = getCategoryListingRoutes(key, data.name, 2, data.id, { id: 40, name: 'Real Estate' })
                            // path = getClassifiedCatLandingRoute(data.name, data.id, data.name)
                            this.props.history.push(path)
                        }
                    }}>{data.name}
                    </li>
                )
            });
        }
    }

    menus = [
        'retailOpen',
        'bookingOpen',
        'classifiedOpen',
        'foodScannerOpen'
    ]

    updateDimensions = () => {
        if (window.innerWidth > 991) {
            this.setState({
                retailOpen: true,
                bookingOpen: true,
                classifiedOpen: true,
                foodScannerOpen: true
            })
        } else {
            this.setState({
                retailOpen: false,
                bookingOpen: false,
                classifiedOpen: false,
                foodScannerOpen: false
            })
        }
    };

    udpateMenu = (current) => {
        let updates = {}
        this.menus.forEach(val => {
            if (val !== current) {
                updates[val] = false
            }
        })



        if (updates) {
            this.setState(updates)
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }



    /**
    * @method render
    * @description render component
    */
    render() {
        const { retailList, classifiedList, bookingList } = this.props;

        const { redirectTo } = this.state;
        let isBokkingVisible = bookingList && bookingList.length !== 0
        let isClassifiedVisible = classifiedList && classifiedList.length !== 0
        let isRetailVisible = retailList && retailList.length !== 0

        if (window.innerWidth > 991) {
            return (
                <div className='menu-wrap'>

                    <button className="closeBtn" onClick={() => { this.props.onClickCloseMenu() }}><CloseIcon /></button>

                    <div className='category-menu'>
                        {isRetailVisible && <div className='category-menu-item retail-menu'>
                            <Link to='#' onClick={this.props.controlMenuDropdown(false)}><Title level={4}>{'Retail'}</Title></Link>
                            <ul>
                                {this.renderSubcategory(retailList, langs.sellerType.retail)}
                            </ul>
                        </div>}
                        {isBokkingVisible && <div className='category-menu-item bookings-menu'>
                            <Link to='#' onClick={this.props.controlMenuDropdown(false)}> <Title level={4}>{'Bookings'}</Title></Link>
                            <ul>
                                {this.renderSubcategory(bookingList, langs.modules.booking)}
                            </ul>
                        </div>}
                        {isClassifiedVisible && <div className='category-menu-item classifieds-menu'>
                            <Link to='#' onClick={this.props.controlMenuDropdown(false)}>
                                <Title level={4}>{'Classifieds'}</Title>
                            </Link>
                            <ul>
                                {this.renderSubcategory(classifiedList, langs.modules.classified)}
                            </ul>
                        </div>}
                        {/* {isEmpty &&  */}
                        <div className='category-menu-item food-scanner-menu'>
                            <Link to='#'><Title level={4}>{'Food Scanner'}</Title></Link>
                            <ul>
                                {/* {this.renderSubcategory(foodScanner)} */}
                                <li onClick={() => { this.props.onClickCloseMenu(); }}><Link to='/food-scanner'>Food Scanner</Link></li>
                            </ul>

                        </div>
                    </div>
                    {redirectTo && <Redirect push to={{
                        pathname: redirectTo
                    }}
                    />}
                </div>
            )
        } else {
            return (
                <div className='menu-wrap'>
                    <div className='category-menu'>
                        {isRetailVisible && <div className={`category-menu-item retail-menu ${this.state.retailOpen ? 'open' : ''}`}>
                            <div to='/retail' onClick={() => {
                                this.setState({
                                    retailOpen: !this.state.retailOpen
                                }, () => {
                                    this.udpateMenu('retailOpen')
                                })
                            }}>
                                <Title level={4}>{'Retail'}</Title>
                            </div>
                            {
                                this.state.retailOpen ? (
                                    <ul>
                                        {this.renderSubcategory(retailList, langs.sellerType.retail)}
                                    </ul>
                                ) : null
                            }

                        </div>}
                        {isBokkingVisible && <div className={`category-menu-item bookings-menu ${this.state.bookingOpen ? 'open' : ''}`}>
                            <div to='/bookings' onClick={() => {
                                this.setState({
                                    bookingOpen: !this.state.bookingOpen
                                }, () => {
                                    this.udpateMenu('bookingOpen')
                                })
                            }}> <Title level={4}>{'Bookings'}</Title></div>
                            {
                                this.state.bookingOpen ? (
                                    <ul>
                                        {this.renderSubcategory(bookingList, langs.modules.booking)}
                                    </ul>
                                ) : null
                            }

                        </div>}


                        {isClassifiedVisible && <div className={`category-menu-item classifieds-menu ${this.state.classifiedOpen ? 'open' : ''}`}>
                            <div to='/classifieds' onClick={() => {
                                this.setState({
                                    classifiedOpen: !this.state.classifiedOpen
                                }, () => {
                                    this.udpateMenu('classifiedOpen')
                                })
                            }}> <Title level={4}>{'Classifieds'}</Title></div>
                            {
                                this.state.classifiedOpen ? (
                                    <ul>
                                        {this.renderSubcategory(classifiedList, langs.modules.classified)}
                                    </ul>
                                ) : null
                            }

                        </div>}



                        <div className={`category-menu-item food-scanner-menu ${this.state.foodScannerOpen ? 'open' : ''}`}>
                            <div to='/food-scanner' onClick={() => {
                                this.setState({
                                    foodScannerOpen: !this.state.foodScannerOpen
                                }, () => {
                                    this.udpateMenu('foodScannerOpen')
                                })
                            }}> <Title level={4}>{'Food Scanner'}</Title>

                            </div>
                            {
                                this.state.foodScannerOpen ? (
                                    <ul>
                                        {/* {this.renderSubcategory(foodScanner)} */}
                                        <li onClick={() => { this.props.onClickCloseMenu(); }}>
                                            <Link to='/food-scanner' onClick={() => { this.props.onClickCloseMenu(); }}>Food Scanner</Link></li>
                                    </ul>
                                ) : null
                            }

                        </div>


                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (store) => {
    const { auth, general: { categoryList } } = store;
    let classifiedList = [], bookingList = [], retailList = [], foodScanner = '';

    classifiedList = categoryList.data.classified ? categoryList.data.classified : []
    bookingList = categoryList.data.booking ? categoryList.data.booking : []
    retailList = categoryList.data.retail ? categoryList.data.retail : []

    return {
        loggedInDetail: auth.loggedInUser,
        isLoggedIn: auth.isLoggedIn,
        classifiedList, bookingList, retailList, foodScanner
        //isEmpty
    };
};
export default connect(
    mapStateToProps,
    { controlMenuDropdown }
)(withRouter(AllMenu));

