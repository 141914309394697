import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutAction, openLoginModel } from "../../../store/actions/index";
import { Menu, Typography } from "antd";
import { langs } from "../../../config/localization";
import SubMenu from "antd/lib/menu/SubMenu";
import Icon from "../../../components/customIcons/customIcons";
import { converInUpperCase } from "../../common";
import { DASHBOARD_KEYS } from "../../../config/Constant";
import { ReactComponent as Users } from "../../../assets/images/users_icon.svg";
import { ReactComponent as Dashboard } from "../../../assets/images/dashboard_icon.svg";
import { ReactComponent as Notifications } from "../../../assets/images/notifications_icon.svg";
import { ReactComponent as Messages } from "../../../assets/images/messages_icon.svg";
import { ReactComponent as Favorite } from "../../../assets/images/favorite_icon.svg";
import { ReactComponent as Wishlist } from "../../../assets/images/wishlist_icon.svg";
import { ReactComponent as Add } from "../../../assets/images/add_icon.svg";
import { ReactComponent as Cart } from "../../../assets/images/cart_icon.svg";
import { ReactComponent as CalendarIcon } from '../../../assets/images/CalendarIcon.svg';
import { ReactComponent as Order } from "../../../assets/images/order_icon.svg";
import { ReactComponent as Bookings } from "../../../assets/images/bookings_icon.svg";
import { ReactComponent as Payment } from "../../../assets/images/payment_icon.svg";
import { ReactComponent as DealsIcon } from "../../../assets/images/dealsIcon.svg";
import { ReactComponent as ClassesIcon } from "../../../assets/images/classesIcon.svg";
import { ReactComponent as PromotionsIcon } from "../../../assets/images/promotionsIcon.svg";
import { ReactComponent as OrderTracker } from "../../../assets/images/OrderTracker.svg";
import { ReactComponent as PackagesIcon } from "../../../assets/images/packagesIcon.svg";
import { getMainCategory } from "../../../common/Navigation";

const { Text } = Typography;
//Condition for menu

export const MobileSidebar = (props) => {
    const { seller_type, mainCategory, userProfile, isLoggedIn } = props;
    let showDeals = [langs.sellerType.beauty, langs.sellerType.wellbeing, langs.sellerType.restaurant].includes(seller_type)
    let showPackages = [langs.sellerType.beauty, langs.sellerType.wellbeing, langs.sellerType.restaurant].includes(seller_type)

    return <>
        {!props.showSecure && isLoggedIn ? (
            <Menu
                style={{ width: 250 }} defaultSelectedKeys={["1"]} defaultOpenKeys={["sub1"]} mode='inline'>
                <Menu.Item key='profile' className='User-menu'>
                    <Link to='/profile'>{`${converInUpperCase(userProfile?.name ?? "")}`}</Link>
                    <Users />
                </Menu.Item>
                <Menu.Item key='dashboards' icon={<Dashboard />}>
                    <Link to='/dashboards'>Dashboard</Link>
                </Menu.Item>
                <Menu.Item key='notifications' icon={<Notifications />}>
                    <Link to='/notifications'>Notification</Link>
                </Menu.Item>
                <Menu.Item key='messages' icon={<Messages />}>
                    <Link to='/messages'>Messages</Link>
                </Menu.Item>
                <Menu.Item key='reviews' icon={<Favorite />}>
                    <Link to='/reviews'>Reviews</Link>
                </Menu.Item>
                {[langs.modules.booking, langs.modules.classified].includes(mainCategory) && (
                    <Menu.Item
                        key={seller_type === langs.sellerType.wellbeing
                            ? '/spa-my-bookings-calender'
                            : seller_type === langs.sellerType.beauty
                                ? '/beauty-my-bookings-calender'
                                : seller_type === langs.sellerType.fitness
                                    ? '/fitness-vendor-mycalendar'
                                    : '/calendar'}
                        icon={<CalendarIcon />}
                    >
                        <Link to={seller_type === langs.sellerType.wellbeing
                            ? '/spa-my-bookings-calender'
                            : seller_type === langs.sellerType.beauty
                                ? '/beauty-my-bookings-calender'
                                : seller_type === langs.sellerType.fitness
                                    ? '/fitness-vendor-mycalendar'
                                    : '/calendar'}>Calendar
                        </Link>
                    </Menu.Item>
                )}
                {seller_type === langs.sellerType.private && (
                    <Menu.Item key='wishlist' icon={<Wishlist />}>
                        <Link to='/wishlist'>Wishlist</Link>
                    </Menu.Item>
                )}
                {[langs.modules.retail, langs.modules.classified, langs.modules.private].includes(mainCategory) && (
                    <Menu.Item key='ad-management' icon={<Add />}>
                        <Link to='/ad-management'>Ad Management</Link>
                    </Menu.Item>
                )}
                {seller_type === langs.sellerType.private && (
                    <SubMenu key='carts' icon={<Cart />} title='Cart'>
                        <Menu.Item key='restaurant-cart'>
                            <Link to='/restaurant-cart'>Restaurant</Link>
                        </Menu.Item>
                        <Menu.Item key='cart'>
                            <Link to='/cart'>Retail</Link>
                        </Menu.Item>
                    </SubMenu>
                )}
                {seller_type === langs.sellerType.private && (
                    <Menu.Item key='/orders-retail' icon={<Order />}>
                        <Link to='/orders-retail'>My Orders</Link>
                    </Menu.Item>
                )}
                {seller_type === langs.sellerType.private && (
                    <Menu.Item key='/booking-list' icon={<Bookings />}>
                        <Link to='/booking-list'>Bookings</Link>
                    </Menu.Item>
                )}
                {[langs.sellerType.beauty, langs.sellerType.wellbeing].includes(seller_type) && (
                    <Menu.Item key={DASHBOARD_KEYS.SPA_SERVICES} title='Services' icon={<img src={require("../dashboard-sidebar/icons/list.png")} alt='' width='18' />}>
                        <Link to='/services'>Services</Link>
                    </Menu.Item>
                )}
                <SubMenu key='payment' icon={<Payment />} title='My Payment'>
                    <Menu.Item key='payment-methods'>
                        <Link to='/payment-methods'>Payment Method</Link>
                    </Menu.Item>
                    <Menu.Item key='billing-history'>
                        <Link to='/billing-history'>Billing history</Link>
                    </Menu.Item>

                    {[langs.sellerType.retail, langs.modules.classified].includes(mainCategory) && (
                        <Menu.Item key='subscription'>
                            <Link to='/subscription'>Subscriptions</Link>
                        </Menu.Item>
                    )}
                </SubMenu>
                {/* retail received orders */}
                {seller_type === langs.sellerType.retail && (
                    <Menu.Item key={DASHBOARD_KEYS.RETAIL_ORDERS} title='Orders' icon={<Order className="strokeIcon" />} >
                        <Link to='/received-orders'>My Orders</Link>
                    </Menu.Item>
                )}
                {[langs.modules.retail].includes(mainCategory) && (
                    <Menu.Item key={DASHBOARD_KEYS.RETAIL_TRANSACTION} title='Orders' icon={<img src={require("../dashboard-sidebar/icons/transaction-inactive.png")} alt='' width='20' />}>
                        <Link to='/transaction'>Transaction</Link>
                    </Menu.Item>
                )}
                {seller_type === langs.sellerType.restaurant && (
                    <Menu.Item key={DASHBOARD_KEYS.RESTAURANT_ORDERS} title='My Orders' icon={<Order className="strokeIcon" />} >
                        <Link to='/restaurant-orders'>My Orders</Link>
                    </Menu.Item>
                )}
                {seller_type === langs.sellerType.restaurant && (
                    <Menu.Item key={DASHBOARD_KEYS.RESTAURANT_ORDERS} title='My Orders' icon={<OrderTracker className="strokeFillIcon" />} >
                        <Link to='/order-tracking'>Order Tracker</Link>
                    </Menu.Item>
                )}
                {/* <Menu.Divider /> */}
                {seller_type === langs.sellerType.restaurant && (
                    <Menu.Item key={DASHBOARD_KEYS.RESTAURANT_MENUES} title='My Menues' icon={<ClassesIcon className="strokeIcon" />} >
                        <Link to='/restaurant-menu'>My Menu</Link>
                    </Menu.Item>
                )}
                {seller_type === langs.sellerType.fitness && (
                    <Menu.Item key={DASHBOARD_KEYS.MANAGE_CLASSES} title='Services'
                        icon={<ClassesIcon className="strokeIcon" />} >
                        <Link to='/classes-and-membership'>Classes</Link>
                    </Menu.Item>
                )}
                {seller_type === langs.sellerType.car_dealer && (
                    <Menu.Item key={DASHBOARD_KEYS.MY_OFFERS} title='Services' icon={<img src={require("../dashboard-sidebar/icons/offer.svg")} alt='' width='18' />}>
                        <Link to='/my-offer'>Offer</Link>
                    </Menu.Item>
                )}
                {(showDeals) && (
                    <Menu.Item key={DASHBOARD_KEYS.DAILY_DEALS} title='Services' icon={<DealsIcon className="strokeFillIcon" />} alt=''>
                        <Link to='/daily-deals'>Daily Deals</Link>
                    </Menu.Item>
                )}
                {(showDeals) && (
                    <Menu.Item title='Services' key={DASHBOARD_KEYS.PROMOTIONS} icon={<PromotionsIcon className="strokeFillIcon" />} alt=''>
                        <Link to='/promotions'>Promotions</Link>
                    </Menu.Item>
                )}
                {seller_type === langs.sellerType.restaurant && (
                    <Menu.Item title='Services' key={'/special-offers'} icon={<img src={require("../dashboard-sidebar/icons/deal.png")} alt="" />}>
                        <Link to='/special-offers'>Special Offers</Link>
                    </Menu.Item>
                )}
                {(showPackages) && (
                    <Menu.Item key={DASHBOARD_KEYS.BEST_PACKAGES} title='Services' icon={<PackagesIcon className="strokeIcon" />} alt=''>
                        <Link to='/packages'>Packages</Link>
                    </Menu.Item>
                )}
                <Menu.Item
                    key='logout'
                    className='menu-logout'
                    onClick={() => {
                        this.props.logoutAction();
                        window.location.assign("/");
                    }}>
                    Logout
                </Menu.Item>
            </Menu>
        ) : (
            <Menu style={{ width: 250 }} defaultSelectedKeys={["1"]} defaultOpenKeys={["sub1"]} mode='inline'>
                <Menu.Item
                    key='user-link'
                    className='user-link'
                    onClick={() => {
                        props.openLoginModel();
                    }}
                >
                    <Icon icon='user' />
                    {!isLoggedIn && <Text>Log in</Text>} { }
                </Menu.Item>
            </Menu>
        )
        }
    </>

}
const mapStateToProps = (store) => {
    const { auth: { isLoggedIn, loggedInUser }, profile: { businessProfile, userProfile } } = store;
    let seller_type = loggedInUser?.seller_type ?? ""
    let mainCategory = getMainCategory(seller_type)
    return {
        isLoggedIn, loggedInUser, seller_type, mainCategory,
        userProfile: businessProfile ? businessProfile : userProfile,
    };
};

export default connect(mapStateToProps, {
    logoutAction,
    openLoginModel,
})(withRouter(MobileSidebar));