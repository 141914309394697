import {
    GET_CATEGORY_LIST,
    GET_CATEGORY_LIST_SUCCESS,
    GET_CATEGORY_LIST_ERROR,
    GET_SUB_CATEGORY_LIST,
    GET_SUB_CATEGORY_LIST_SUCCESS,
    GET_SUB_CATEGORY_LIST_ERROR,
    SHOW_HIDE_LOADING,
    GET_FITNESS_DEFAULTS,
    SET_FITNESS_DEFAULTS,
    GET_RESTAURANT_DEFAULTS,
    SET_RESTAURANT_DEFAULTS,
} from "../constants";

export function getCategoryListAction(session, resolve, reject) {
    return {
        type: GET_CATEGORY_LIST,
        payload: session,
        resolve,
        reject
    };
}

export function getCategoryListSuccess(data) {
    return {
        type: GET_CATEGORY_LIST_SUCCESS,
        payload: data
    };
}

export function getCategoryListError(error) {
    return {
        type: GET_CATEGORY_LIST_ERROR,
        payload: error
    };
}

export function getSubCategoryListAction(session, resolve, reject) {
    return {
        type: GET_SUB_CATEGORY_LIST,
        payload: session,
        resolve,
        reject
    };
}

export function getSubCategoryListSuccess(data) {
    return {
        type: GET_SUB_CATEGORY_LIST_SUCCESS,
        payload: data
    };
}

export function getSubCategoryListError(error) {
    return {
        type: GET_SUB_CATEGORY_LIST_ERROR,
        payload: error
    };
}

export function loadingAction(session, resolve, reject) {
    return {
        type: SHOW_HIDE_LOADING,
        payload: session,
        resolve,
        reject
    };
}

export function getFitnessDefaultsAction(resolve) {
    return {
        type: GET_FITNESS_DEFAULTS,
        resolve,
    };
}

export function setFitnessDefaultsAction(data) {
    return {
        type: SET_FITNESS_DEFAULTS,
        payload: data
    };
}

export function getRestaurantDefaultsAction(resolve) {
    return {
        type: GET_RESTAURANT_DEFAULTS,
        resolve,
    };
}

export function setRestaurantDefaultsAction(data) {
    return {
        type: SET_RESTAURANT_DEFAULTS,
        payload: data
    };
}