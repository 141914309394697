import axios from 'axios';
import { BOOKINGS_API, COUNTRY_STATE_CITY_API, QUOTE_API } from '../../../api';
import { API } from '../../../config/Config';
import { apiErrors } from '../../../config/HandleAPIErrors';

require('dotenv').config();

/**
 * User get Enquiry List
 * @param data
 * @param callback
 * @returns {function(*)} 
 */
export function getQuotesListAction(data, callback) {
    const request = axios.get(`${QUOTE_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}


/**
 * User get Job List
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getBookingListAction(data, callback) {
    const request = axios.get(`${BOOKINGS_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}


/**
 * User get Enquiry Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getEnquiryDetailAction({ quote_id, ...data }, callback) {
    const request = axios.get(`${QUOTE_API}/${quote_id}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * User get JobBooking Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getJobBookingDetails(data, callback) {
    const request = axios.post(`${API.getHandyManVenderBookingDetail}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * User get History Details
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function getHistoryDetails(data, callback) {
    const request = axios.post(`${API.getHandyManVenderHistoryDetail}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}


/**
 * User decline Enquiry
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function declineEnquiry(data, callback) {
    const request = axios.post(`${API.changeEnquiryStatus}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}


/**
 * User change Handyman Booking Status
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function changeBookingStatusAction(data, callback) {
    const request = axios.post(`${BOOKINGS_API}/${data.booking_id}?_method=PUT`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * create Quote Action
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function createQuoteAction(data, callback) {
    const request = axios.post(`${QUOTE_API}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * User change Handyman Booking Status
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function rescheduleHanymanBooking(data, callback) {
    const request = axios.post(`${API.reScheduleHadymanBooking}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        }).catch(function (error) {
            callback(error)
            apiErrors(error)
        });
    }
}

/**
 * raise Handyman dispute
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function raiseHandymanDispute(data, callback) {
    const request = axios.post(`${API.createHandymanVenderDispute}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * raise Handyman dispute
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function replyHandymanDispute(data, callback) {
    const request = axios.post(`${API.replyHandymanVenderDispute}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}


/**
 * raise Handyman dispute
 * @param data
 * @param callback
 * @returns {function(*)}
 */
export function submitHandymanReview(data, callback) {
    const request = axios.post(`${API.submitHandymanVenderReviews}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}

/**
 * @method getCountryStateCityAction
 * @description add menu category
 */
export function getCountryStateCityAction(callback) {
    const request = axios.get(COUNTRY_STATE_CITY_API)
    return (dispatch) => {
        request.then((res) => {
            callback(res)
        })
            .catch(function (error) {
                callback(error)
                apiErrors(error)
            });
    }
}
