import { toastr } from 'react-redux-toastr';
import { langs } from '../config/localization';

/**
 * @method apiErrors
 * @description handle api error 
 */
export const apiErrors = (res, dispatch) => {
    const response = res?.response;

    // Adding un authorize condition after disscussion with kk
    if (response && response.status && response.data) {
        if ([422, 415, 412, 401].includes(response.status) && (response.data.error || response.data.message)) {
            if (response.data.error)
                toastr.error(langs.error, response.data.error);
            if (response.data.message)
                toastr.error(langs.error, response.data.message);
        } else if (response.data.errors && response.data.errors.length) {
            Object.values(response.data.errors).map(error => {
                toastr.error(langs.error, error[0]);
            })
        } else if ([415].includes(response.status) && (response.data.error || response.data.message)) {
            let message = response.data.error || response.data.message
            toastr.warning(langs.warning, message);
        } else {
            console.log("apiErrors", response)
        }
    }
}