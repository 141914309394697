// api constant

export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS";
export const GET_CATEGORY_LIST_ERROR = "GET_CATEGORY_LIST_ERROR";

export const GET_SUB_CATEGORY_LIST = "GET_SUB_CATEGORY_LIST";
export const GET_SUB_CATEGORY_LIST_SUCCESS = "GET_SUB_CATEGORY_LIST_SUCCESS";
export const GET_SUB_CATEGORY_LIST_ERROR = "GET_SUB_CATEGORY_LIST_ERROR";

export const GET_ADVERTISEMENT_LIST = "GET_ADVERTISEMENT_LIST";
export const GET_ADVERTISEMENT_LIST_SUCCESS = "GET_ADVERTISEMENT_LIST_SUCCESS";
export const GET_ADVERTISEMENT_LIST_ERROR = "GET_ADVERTISEMENT_LIST_ERROR";

export const GET_PAGE_LISTING = "GET_PAGE_LISTING";
export const GET_PAGE_LISTING_SUCCESS = "GET_PAGE_LISTING_SUCCESS";
export const GET_PAGE_LISTING_ERROR = "GET_PAGE_LISTING_ERROR";

export const GET_ITEM_DETAILS = "GET_ITEM_DETAILS";
export const GET_ITEM_DETAILS_SUCCESS = "GET_ITEM_DETAILS_SUCCESS";
export const GET_ITEM_DETAILS_ERROR = "GET_ITEM_DETAILS_ERROR";

export const GET_FITNESS_DEFAULTS = "GET_FITNESS_DEFAULTS";
export const SET_FITNESS_DEFAULTS = "SET_FITNESS_DEFAULTS";
export const GET_RESTAURANT_DEFAULTS = "GET_RESTAURANT_DEFAULTS";
export const SET_RESTAURANT_DEFAULTS = "SET_RESTAURANT_DEFAULTS";

export const SET_COMPARISION_PRODUCT_IDS = "SET_COMPARISION_PRODUCT_IDS";

export const SHOW_HIDE_LOADING = "SHOW_HIDE_LOADING";

export const UPDATE_BOOKING_COUNT = "UPDATE_BOOKING_COUNT";
export const UPDATE_CART_COUNT = "UPDATE_CART_COUNT";
export const UPDATE_NOTIFICATION_COUNT = "UPDATE_NOTIFICATION_COUNT";
export const UPDATE_WISHLIST_COUNT = "UPDATE_WISHLIST_COUNT";
export const GET_RETAIL_LISTING = "GET_RETAIL_LISTING";

export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const REGISTER_BUSSINESS_USER = 'REGISTER_BUSSINESS_USER';
export const RESET_APP = 'RESET_APP';
export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';
export const GET_LOGGED_IN_USER = 'GET_LOGGED_IN_USER';
export const LOGOUT = 'LOGOUT';
export const FETCH_ALL_CATEGORY = 'FETCH_ALL_CATEGORY';
export const SET_BOOKING_CATEGORY = 'SET_BOOKING_CATEGORY';
export const SET_BOOKING_SUBCATEGORY = 'SET_BOOKING_SUBCATEGORY';
export const SET_USER_MENU_LIST = 'SET_USER_MENU_LIST';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const SET_SAVED_CATEGORIES = 'set_saved_categories';
export const SET_CLASSIFIEDS_CATEGORY_LISTING = 'set_classifieds_category_listing';
export const SET_SELECTED_CLASSIFIED = 'set_selected_classified';
export const ADD_TO_FAVORITE = 'add_to_favorite';
export const LOGIN_MODEL = 'open_login_model';
export const CLOSE_LOGIN_MODEL = 'close_login_model';
export const OPEN_FORGOT_MODEL = 'open_forgot_model';
export const CLOSE_FORGOT_MODEL = 'close_forgot_model';
export const SEND_OTP = 'send_otp';
export const SET_BANNER_PAGE = 'set_banner_page';
export const SET_BANNER = 'set-banner';
export const SET_RETAIL_LIST = 'set_retail_list';
export const SET_MOST_VIEW = 'set_most_view';
export const SET_FAVORITE_ITEM_ID = 'set_favorite_item_id';
export const SET_FILTER_ATTRIBUTES = 'set_filter_attribute';
export const SET_RESUME_DETAILS = 'set_resume_details';
export const SET_FITNESS_TYPES = 'set_fitness_types';
export const SET_FOOD_TYPES = 'set_foof_types';
export const SET_DIETARY_TYPES = 'set_dietary_types';

export const STEP1 = 'step1';
export const STEP2 = 'step2';
export const STEP3 = 'step3';
export const STEP4 = 'step4';
export const SET_CHILD_CATEGORY = 'set_child _category';
export const SET_CLASSIFIED_CATEGORY = 'set_classified _category';
export const PAPULAR_SEARCH = 'set_papular_search';
export const MOST_PAPULAR_RECORD = 'most_papular_record';
export const GET_PLAN = 'get_plan';
export const SET_LOCATION = 'set_location';
export const FILE_LIST = 'FILE_LIST';
export const PREVIEW = 'PREVIEW';
export const GET_BUSINESS_PROFILE = 'GET_BUSINESS_PROFILE';
export const SET_RESTAURANT_DETAIL = 'set_restaurant_detail';
export const SET_BROCHURES = 'set_brochures';
export const SET_GALLERY = 'set_gallery';
export const SET_PORTFOLIO_FOLDER = 'set_portfolio_folder';
export const SET_CERTIFICATIONS = 'set_certifications';
export const SET_RESTAURANT_REVIEWS = 'SET_RESTAURANT_REVIEWS';
export const SET_POPULAR_RESTAURANT = 'set_popular_restaurants';
export const SET_POPULAR_VENUE = 'set_popular_venue';
export const SET_DAILY_DEALS = 'SET_DAILY_DEALS';
export const SET_MY_DEALS = 'set_my_deals';
export const SET_PROMOTIONS = 'SET_PROMOTIONS';
export const SET_SPECIAL_OFFERS = 'SET_SPECIAL_OFFERS';
export const SET_MY_OFFERS = 'set_my_offers';
export const SET_MY_PROMO = 'set_my_promo';
export const SET_ELIGIBLE_PACKAGES = 'set_eligible_packages';
export const SET_PACKAGES = 'SET_PACKAGES';
export const SET_SPORTS_COUNTRY = 'set_sports_country';
export const SET_GENERAL_VENDOR_MY_OFFER = 'set_general_venfor_my_offer';
export const SET_CURRENT_ADDRESS = 'set_current_location';
export const SET_GST_PERCENTAGE = 'set_gst_percentage';
export const SET_MENU_OVERLAY = 'set_menu_overlay';
export const SET_ADDRESS_TYPE = 'set_address_type';
export const SET_ADDRESS = 'set address';
export const SET_RETAIL_SUB_CATEGORIES = 'set-retail-sub-category';
export const SET_FLIGHT_RECORD = 'set_flight_records';
export const SET_MULTICITY_VALUE = 'set-multicity-values';
export const SET_FLIGHT_SERACH_PARAMS = 'set_flight_search_data';
export const SET_TOKEN = 'set-flight-token';
export const SET_CAR_SEARCH_DATA = 'set-car-search-data';
export const SET_CAR_REQ_DATA = 'set-car-request-data';
export const SET_ORIGIN = 'set_origin';
export const SET_SELECTED_CAR_DATA = 'set_selected_car_data';
export const SET_HOTEL_SEARCH_DATA = 'set-hotel-search-data';
export const SET_HOTEL_REQ_DATA = 'set-hotel-search-req-data';
export const GET_SELETED_HOTEL = 'get-selected-hotel-details';
export const GET_SERVICE_AREA_OPTION = 'get-service-area-option';
export const ADD_CHECKOUT_DATA = 'add-checkout-data';
export const REMOVE_CHECKOUT_DATA = 'remove-checkout-data';

export const BANNER_HEIGHT = 'BANNER_HEIGHT';
export const SEARCH_OPEN_CLOSE = 'SEARCH_OPEN_CLOSE';

export const SIDEBAR_OPEN_CLOSE = "SIDEBAR_OPEN_CLOSE";
