import axios from 'axios';
import { AD_PERMISSION_API, BILLING_INVOICE_API, CLASSIFIED_ADS_API, CLASSIFIED_ATTRIBUTES_CHILDREN_API, CLASSIFIED_GET_ATTRIBUTES_VALUES_API, CLASSIFIED_INVENTORY_ATTRIBUTES_API, SUBSCRIPTION_API } from '../../../api';
import { API } from '../../../config/Config';
import { apiErrors } from '../../../config/HandleAPIErrors';
import { STEP1, STEP2, STEP3, STEP4,  FILE_LIST, PREVIEW } from '../../constants';


/**
 * @method getClassifiedAttributesAction
 * @description get all input for post an add
 */
export function getClassifiedAttributesAction(data, callback) {
    const request = axios.get(`${CLASSIFIED_INVENTORY_ATTRIBUTES_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getClassifiedChildrenAction
 * @description get chlild input
 */
export function getClassifiedChildrenAction(data, callback) {
    const request = axios.get(`${CLASSIFIED_ATTRIBUTES_CHILDREN_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
* @method setAdPostData
* @description set post an ad data
*/
export function setAdPostData(data, step) {
    if (step === 1) {
        return (dispatch) => {
            dispatch({
                type: STEP1,
                payload: data
            })
        }
    } else if (step === 2) {
        return (dispatch) => {
            dispatch({
                type: STEP2,
                payload: data
            })
        }
    } else if (step === 3) {
        return (dispatch) => {
            dispatch({
                type: STEP3,
                payload: data
            })
        }
    } else if (step === 4) {
        return (dispatch) => {
            dispatch({
                type: STEP4,
                payload: data
            })
        }
    } else if (step === 'fillist') {
        return (dispatch) => {
            dispatch({
                type: FILE_LIST,
                payload: data
            })
        }
    } else if (step === 'preview') {
        return (dispatch) => {
            dispatch({
                type: PREVIEW,
                payload: data
            })
        }
    }
}

/**
 * @method postAnAdd
 * @description post ad 
 */
export function postAnAd(data, callback) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    const request = axios.post(`${API.postAd}`, data, headers)
    return (dispatch) => {
        
        request.then((res) => {
            
            callback(res);
        }).catch(function (error) {
            

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method AdPermissionAction
 * @description check if user have permission to post ad
 */
export function AdPermissionAction(data, callback) {
    const request = axios.get(AD_PERMISSION_API)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method checkSubscriptionForPostAd
 * @description get chlild input
 */
export function checkSubscriptionForPostAd(data, callback) {
    const request = axios.post(`${API.checkSubscription}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method classifiedAdPostAction
 * @description post ad 
 */
export function classifiedAdPostAction(data, callback) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    const request = axios.post(`${CLASSIFIED_ADS_API}`, data, headers)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getPostAdDetail
 * @description get post ad detail by id
 */
export function getClassifiedAdAction(data, callback) {
    const request = axios.get(`${CLASSIFIED_ADS_API}/${data.id}`,)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method updateClassifiesAdAction
 * @description get post ad detail by id
 */
export function updateClassifiesAdAction({ formData, id }, callback) {
    // const headers = { 'Content-Type': 'multipart/form-data' }
    const request = axios.post(`${CLASSIFIED_ADS_API}/${id}?_method=PUT`, formData)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method deleteInspectionAPI
 * @description delete inspection
 */
export function deleteInspectionAPI(data, callback) {
    const request = axios.post(`${API.deleteInspection}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {
            apiErrors(error)
            callback(error);
        });
    }
}

/**
 * @method getAttributeValuesAction
 * @description get attribute values
 */
export function getAttributeValuesAction(data, callback) {
    const request = axios.get(`${CLASSIFIED_GET_ATTRIBUTES_VALUES_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method deleteClassifiedImages
 * @description get attribute values
 */
export function deleteClassifiedImages(data, callback) {
    const request = axios.post(`${API.classified_delete_image}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method getPlanDetailAction
 * @description get Selected Plan Detail
 */
export function getPlanDetailAction(data, callback) {
    const request = axios.get(`${SUBSCRIPTION_API}/${data.id}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {
            callback(error);
        });
    }
}

/**
 * @method getInvoice
 * @description get Invoice Detail
 */
export function getInvoice(data, callback) {
    const request = axios.get(`${API.invoiceDetail}/${data}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            callback(error);
        });
    }
}

/**
 * @method getBillingInvoiceAction
 * @description get Package Invoice Detail
 */
export function getBillingInvoiceAction(invoice_id, callback) {
    const request = axios.get(`${BILLING_INVOICE_API}/${invoice_id}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {

            callback(error);
        });
    }
}