import {
    SET_LOGGED_IN_USER,
    UPDATE_BOOKING_COUNT,
    UPDATE_CART_COUNT,
    UPDATE_NOTIFICATION_COUNT,
    UPDATE_WISHLIST_COUNT
} from '../constants';
import { setLocalStorage } from '../../common/Methods'

const INITIAL_STATE = {
    loggedInUser: undefined,
    isLoggedIn: false,
    wishlist_count: 0,
    cart_count: 0,
    booking_count: 0,
    notification_count: 0,
    dashboardRoutes: ['/myProfile', '/editProfile', '/change-password', '/messages', '/dashboard', '/classes-and-membership']
};

const applySetUserType = (state, action) => ({
    ...state,
    isLoggedIn: true,
    loggedInUser: action.payload.data,
    booking_count: action.payload.data.booking_count,
    cart_count: action.payload.data.cart_count,
    notification_count: action.payload.data.notification_count,
    wishlist_count: action.payload.data.wishlist_count,
});


function authTypeReducer(state = INITIAL_STATE, action) {

    switch (action.type) {
        case SET_LOGGED_IN_USER: {
            setLocalStorage(action.payload)
            return applySetUserType(state, action);
        }
        case UPDATE_BOOKING_COUNT: {
            return { ...state, booking_count: action.payload };
        }
        case UPDATE_CART_COUNT: {
            return { ...state, cart_count: action.payload };
        }
        case UPDATE_NOTIFICATION_COUNT: {
            return { ...state, notification_count: action.payload };
        }
        case UPDATE_WISHLIST_COUNT: {
            return { ...state, wishlist_count: action.payload };
        }
        default:
            return state;
    }
}

export default authTypeReducer;
