import axios from "axios";
import { RESTAURANT_ORDERS_API } from "../../../api";
import { API } from "../../../config/Config";
import { apiErrors } from "../../../config/HandleAPIErrors";

export function getUserRestaurantCurrentOrders(callback) {
  const request = axios.get(`${API.getUserRestaurantCurrentOrders}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function getUserRestaurantPastOrders(callback) {
  const request = axios.get(`${API.getUserRestaurantPastOrders}`);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function getUserRestaurantTrakingOrders(data, callback) {
  let { page, page_size } = data;
  const request = axios.get(
    `${API.getTrakingOrders}/${null}?page=${page}&page_size=${page_size}`
  );
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);

      })
      .catch(function (error) {
        apiErrors(error);
      });
  };
}
export function getUserRestaurantTrakingOrdersStatus(status, callback) {
  const request = axios.get(`${API.getTrakingOrders}/${status}`);
  return (dispatch) => {
    request
      .then((res) => {

        callback(res);

      })
      .catch(function (error) {
        apiErrors(error);
      });
  };
}
export function updateRestaurantProfile(data, callback) {
  const request = axios.post(`${API.updateRestaurantProfileDetail}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {
        callback(error);
        apiErrors(error);
      });
  };
}

export function getRestaurantOrderDetailsAction(orderId, callback) {
  const request = axios.get(`${RESTAURANT_ORDERS_API}/${orderId}`);

  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}
export function addReviewService(data, callback) {
  const request = axios.post(`${API.postReviewService}`, data);

  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function updateRestaurantOrderAction({ order_id, ...data }, callback) {
  const request = axios.post(`${RESTAURANT_ORDERS_API}/${order_id}?_method=PUT`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {
        apiErrors(error);
        callback(error);
      });
  };
}

export function getRestaurantVendorOrderByMonth(data, callback) {
  const request = axios.post(`${API.getRestaurantVendorOrderByMonth}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function getRestaurantVendorEarning(data, callback) {
  const request = axios.post(`${API.getRestaurantVendorEarning}`, data);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function getRestaurantOrdersAction(params, callback) {
  const request = axios.get(`${RESTAURANT_ORDERS_API}`, { params: params });
  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function deleteRestaurantOrderAction(id, callback) {

  const request = axios.delete(`${RESTAURANT_ORDERS_API}/${id}`);

  return (dispatch) => {
    request
      .then((res) => {
        callback(res.data);
      })
      .catch(function (error) {

        // apiErrors(error)
        callback(error);
      });
  };
}

export function acceptRestaurantOrder(reqData, callback) {
  const request = axios.post(`${API.acceptRestaurantOrder}`, reqData);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function updateRestaurantOrderStatus(reqData, callback) {
  const request = axios.post(`${API.updateRestaurantOrderStaus}`, reqData);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}

export function reOrderRestaurantItems(reqData, callback) {
  const request = axios.post(`${API.reOrderRestaurantItems}`, reqData);
  return (dispatch) => {
    request
      .then((res) => {
        callback(res);
      })
      .catch(function (error) {

        apiErrors(error);
        callback(error);
      });
  };
}
