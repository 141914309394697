import axios from 'axios';
import { BOOK_CLASSES_API } from '../../../api';
import { API } from '../../../config/Config';
import { apiErrors } from '../../../config/HandleAPIErrors';

export function getBookedClassesAction(data, callback) {
    const request = axios.get(`${BOOK_CLASSES_API}`, { params: data })
    return (dispatch) => {
        request.then((res) => {
            callback(res.data); 
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

export function getMyFitnessBookingCalenderList(data, callback) {
    const request = axios.post(`${API.getMyFitnessBookingCalender}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res.data);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

export function fitnessListCustomerHistoryClasses(data, callback) {
    const request = axios.get(`${API.listCustomerHistoryClasses}?order=${data.order}&search=${data.search}&page=${data.page}&page_size=${data.page_size}`)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

export function rateTraderClass(data, callback) {
    const request = axios.post(`${API.rateTraderClass}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

export function fitnessCustomerListAllSubscriptions(data, callback) {
    const request = axios.post(`${API.fitnessCustomerListAllSubscriptions}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}

export function showCustomerClass(data, callback) {
    const request = axios.post(`${API.showCustomerClass}`, data)
    return (dispatch) => {
        request.then((res) => {
            callback(res);
        }).catch(function (error) {

            apiErrors(error)
            callback(error);
        });
    }
}